import styled from "styled-components";
import { DynamicSvg } from "../../ui-components/DynamicSvg";

interface DocumentItemProps {
    href: string;
    target: string;
    url: string;
    fileName: string;
    fileType: string;
}

interface FinalStateKeyProps {
    isLast: boolean;
    hasChildren?: boolean;
}

export const _UserChatMessageBox = styled.div`
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background: rgba(246, 246, 246, 0.44);
`;

export const _AIChatMessageBox = styled.div`
    margin-bottom: 20px;
`;

export const ChatLogScrollContainer = styled.div`
    height: 100%;
    flex: 1;
    overflow-y: auto;
    // overflow-x: clip;
    padding: 0 10px 20px 10px;
    margin: 0 -10px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
`;

export const CodeMessageContent = styled.div`
    margin-top: 15px;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const CodeBlock = styled.div`
    margin: 10px 0;
    border-radius: 6px;
    overflow: hidden;
    
    pre {
        margin: 0 !important;
        border-radius: 6px;
        
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            margin: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #666666;
            // border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b3b3b3;
        }
    }
`;

export const CodeBlockApplyButton = styled.button`
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background: #fff;
    color: #000;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    
    &:hover {
        background: #f5f5f5;
    }
`;

export const RunLogInChatTitle = styled.div`
    margin-top: 15px;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const RunLogInChatContainer = styled.div`
    
    margin-bottom: 10px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    overflow: hidden;
`;

export const RunLogInChatStepItem = styled.div`
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;

    &:hover {
        background-color: rgba(246, 246, 246, 0.44);
    }
    
    &:last-child {
        border-bottom: none;
    }
`;

export const StepIcon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StepContent = styled.div`
    flex: 1;
`;

export const StepHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const StepTitle = styled.div`
    font-weight: 500;
    font-size: 0.9em;
`;

export const StepMetadata = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 0.9em;
`;

export const StepDetails = styled.div<{ isExpanded: boolean }>`
    margin-top: 8px;
    display: ${props => props.isExpanded ? 'block' : 'none'};
    color: #666;
    font-size: 0.9em;
`;

export const StepStatusTag = styled.span<{ type: 'success' | 'error' | 'time' }>`
    background: ${
        props => props.type === 'success' ? 'rgba(75, 181, 67, 0.1)' : 
        props.type === 'error' ? 'rgba(255, 0, 0, 0.1)' : 
        '#F5F5F5'
    };
    color: ${
        props => props.type === 'success' ? '#4BB543' : 
        props.type === 'error' ? '#FF0000' : 
        '#7F7F7F'
    };
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.9em;
`;

export const StepFinalState = styled.div<{ isExpanded: boolean }>`
    margin-top: 8px;
    font-family: monospace;
    font-size: 0.85em;
    color: #666;
    white-space: pre-wrap;
    overflow: hidden;
    max-height: ${props => props.isExpanded ? 'none' : '100px'};
`;


export const ShowMoreButton = styled.button`
    background: none;
    border: none;
    color: #3C94D6;
    cursor: pointer;
    padding: 4px 0;
    font-size: 0.85em;
    
    &:hover {
        text-decoration: underline;
    }
`;

export const DocumentItem = styled.a<DocumentItemProps>`
    text-decoration: none;
    padding: 12px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
        background-color: rgba(246, 246, 246, 0.44);
    }
`;

export const LoadingIcon = styled.div`
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #666;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
    
    svg {
        width: 16px;
        height: 16px;
    }
    
    &:hover {
        color: #333;
    }
    
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

export const DocumentInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const DocumentName = styled.div`
    font-weight: 500;
    font-size: 0.9em;
    color: #333;
`;

export const DocumentType = styled.div`
    font-size: 0.8em;
    color: #666;
`;

export const PendingAttachmentItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background: #fff;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-bottom: 8px;
    font-size: 0.9em;
    color: #333;
    gap: 8px;
`;

export const FinalStateContainer = styled.div`
    margin: 12px 0;
    font-size: 0.9em;
    color: #333;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 8px;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
`;

export const FinalStateRow = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 4px;
    padding: 1px 0;
    width: 100%;
`;

export const FinalStateKey = styled.div<FinalStateKeyProps>`
    color: #666;
    position: relative;
    padding-left: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: ${props => props.hasChildren ? 'pointer' : 'default'};
    
    &::before {
        content: "${props => props.isLast ? '└─' : '├─'}";
        position: absolute;
        left: 0;
        color: #ccc;
    }
`;

export const ArrowIcon = styled.span<{ isOpen: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease;
    transform: rotate(${props => props.isOpen ? '90deg' : '0deg'});
    
    &::before {
        content: "▶";
        font-size: 10px;
        color: #666;
    }
`;

export const FinalStateValue = styled.div`
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
`;

export const FinalStateNestedContainer = styled.div`
    position: relative;
    padding-left: 12px;
    margin: 0;
    
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -2px;
        bottom: 8px;
        width: 1px;
        background: #ccc;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 12px 0;
`;

export const BaseButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    font-size: 0.9em;
    font-weight: 500;
    color: #666;
    background: #fff;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
        background: #f5f5f5;
        color: #333;
    }
`;

export const FinalStateHeader = styled(BaseButton)`
    flex: 1;
`;

export const ChartButton = styled(BaseButton)`
    svg {
        color: #666;
    }
    
    &:hover svg {
        color: #333;
    }
`;

