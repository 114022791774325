export default {
    "nodes": [
        {
            "id": "1",
            "type": "start",
            "position": { "x": 100, "y": 100 },
            "data": {
                "type": "start",
                "label": "Add Tray Ceiling",
                "tooltip": "Adds a tray ceiling to the selected elements",
                "code": "import clr\n\
clr.AddReference('RevitAPI')\n\
clr.AddReference('RevitAPIUI')\n\
\n\
from Autodesk.Revit.DB import *\n\
from Autodesk.Revit.UI import *\n\
from Autodesk.Revit.UI.Selection import ObjectType, ISelectionFilter\n\
import math\n\
\n\
# Get the current document and UIDocument\n\
\n\
# Custom filter for room selection\n\
class CustomRoomFilter:\n\
    def __init__(self):\n\
        pass\n\
    \n\
    def AllowElement(self, elem):\n\
        if elem.Category.Name == \"Rooms\":\n\
            return True\n\
        return False\n\
    \n\
    def AllowReference(self, reference, position):\n\
        return False\n\
\n\
# Make the class inherit from ISelectionFilter\n\
from System.Collections.Generic import *\n\
clr.AddReference('System.Core')\n\
CustomRoomFilter = type('CustomRoomFilter', \n\
                       (ISelectionFilter,),\n\
                       dict(CustomRoomFilter.__dict__))\n\
\n\
def create_tray_ceiling(doc, room, offset_distance=2.0, outer_height=10.0, inner_height=11.0):\n\
    \"\"\"\n\
    Creates a tray ceiling in a given room with two levels.\n\
    \n\
    Parameters:\n\
    doc: Revit document\n\
    room: Room element\n\
    offset_distance: Distance from walls for tray (in feet)\n\
    outer_height: Height of the outer ceiling (in feet)\n\
    inner_height: Height of the inner ceiling (in feet)\n\
    \"\"\"\n\
    # Verify room has boundary\n\
    boundary_segments = room.GetBoundarySegments(SpatialElementBoundaryOptions())\n\
    if not boundary_segments or len(boundary_segments) == 0:\n\
        raise ValueError(\"Room has no boundary segments\")\n\
\n\
    # Get room boundary\n\
    room_boundary = boundary_segments[0]\n\
    \n\
    # Create outer ceiling loop\n\
    outer_points = [seg.GetCurve().GetEndPoint(0) for seg in room_boundary]\n\
    outer_loop = CurveLoop.Create([Line.CreateBound(p1, p2) \n\
                                 for p1, p2 in zip(outer_points, outer_points[1:] + [outer_points[0]])])\n\
    \n\
    # Create inner boundary for tray\n\
    inner_loop = CurveLoop.CreateViaOffset(outer_loop, -offset_distance, XYZ.BasisZ)\n\
    \n\
    # Create loops list with hole\n\
    outer_ceiling_loops = [outer_loop, inner_loop]  # Second loop creates the hole\n\
    \n\
    # Get ceiling type\n\
    ceiling_type = FilteredElementCollector(doc)\\\n\
                    .OfClass(CeilingType)\\\n\
                    .FirstElement()\n\
    \n\
    base_level = room.Level\n\
    level_elevation = base_level.Elevation\n\
    \n\
    # Create outer ceiling (with hole)\n\
    base_ceiling = Ceiling.Create(doc, outer_ceiling_loops, ceiling_type.Id, base_level.Id)\n\
    param = base_ceiling.LookupParameter('Height Offset From Level')\n\
    if param:\n\
        param.Set(outer_height - level_elevation)\n\
    \n\
    # Create inner (raised) ceiling\n\
    inner_loops = [inner_loop]\n\
    tray_ceiling = Ceiling.Create(doc, inner_loops, ceiling_type.Id, base_level.Id)\n\
    param = tray_ceiling.LookupParameter('Height Offset From Level')\n\
    if param:\n\
        param.Set(inner_height - level_elevation)\n\
    \n\
    return {\n\
        \"base_ceiling\": base_ceiling,\n\
        \"tray_ceiling\": tray_ceiling\n\
    }\n\
\n\
# Main execution\n\
# Prompt user to select a room\n\
reference = uidoc.Selection.PickObject(ObjectType.Element, \n\
                                     CustomRoomFilter(), \n\
                                     \"Please select a room\")\n\
room = doc.GetElement(reference.ElementId)\n\
\n\
# Start transaction\n\
\n\
# Create tray ceiling with 10' outer and 11' inner heights\n\
result = create_tray_ceiling(doc, room, offset_distance=2.0, outer_height=10.0, inner_height=11.0)"
            }
        },
        {
            "id": "2",
            "type": "select_in_revit",
            "position": { "x": 100, "y": 200 },
            "data": {
                "type": "select_in_revit",
                "label": "Select Room",
                "tooltip": "Select a room to add a tray ceiling to",
                "selectionType": "Rooms"
            }
        },
        {
            "id": "3",
            "type": "code",
            "position": { "x": 100, "y": 300 },
            "data": {
                "type": "code",
                "label": "Add Tray Ceiling",
                "tooltip": "Adds a tray ceiling to the selected room",
                "code": "import clr\n\
clr.AddReference('RevitAPI')\n\
clr.AddReference('RevitAPIUI')\n\
\n\
from Autodesk.Revit.DB import *\n\
from Autodesk.Revit.UI import *\n\
from Autodesk.Revit.UI.Selection import ObjectType, ISelectionFilter\n\
import math\n\
\n\
# Get the current document and UIDocument\n\
\n\
# Custom filter for room selection\n\
class CustomRoomFilter:\n\
    def __init__(self):\n\
        pass\n\
    \n\
    def AllowElement(self, elem):\n\
        if elem.Category.Name == \"Rooms\":\n\
            return True\n\
        return False\n\
    \n\
    def AllowReference(self, reference, position):\n\
        return False\n\
\n\
# Make the class inherit from ISelectionFilter\n\
from System.Collections.Generic import *\n\
clr.AddReference('System.Core')\n\
CustomRoomFilter = type('CustomRoomFilter', \n\
                       (ISelectionFilter,),\n\
                       dict(CustomRoomFilter.__dict__))\n\
\n\
def create_tray_ceiling(doc, room, offset_distance=2.0, outer_height=10.0, inner_height=11.0):\n\
    \"\"\"\n\
    Creates a tray ceiling in a given room with two levels.\n\
    \n\
    Parameters:\n\
    doc: Revit document\n\
    room: Room element\n\
    offset_distance: Distance from walls for tray (in feet)\n\
    outer_height: Height of the outer ceiling (in feet)\n\
    inner_height: Height of the inner ceiling (in feet)\n\
    \"\"\"\n\
    # Verify room has boundary\n\
    boundary_segments = room.GetBoundarySegments(SpatialElementBoundaryOptions())\n\
    if not boundary_segments or len(boundary_segments) == 0:\n\
        raise ValueError(\"Room has no boundary segments\")\n\
\n\
    # Get room boundary\n\
    room_boundary = boundary_segments[0]\n\
    \n\
    # Create outer ceiling loop\n\
    outer_points = [seg.GetCurve().GetEndPoint(0) for seg in room_boundary]\n\
    outer_loop = CurveLoop.Create([Line.CreateBound(p1, p2) \n\
                                 for p1, p2 in zip(outer_points, outer_points[1:] + [outer_points[0]])])\n\
    \n\
    # Create inner boundary for tray\n\
    inner_loop = CurveLoop.CreateViaOffset(outer_loop, -offset_distance, XYZ.BasisZ)\n\
    \n\
    # Create loops list with hole\n\
    outer_ceiling_loops = [outer_loop, inner_loop]  # Second loop creates the hole\n\
    \n\
    # Get ceiling type\n\
    ceiling_type = FilteredElementCollector(doc)\\\n\
                    .OfClass(CeilingType)\\\n\
                    .FirstElement()\n\
    \n\
    base_level = room.Level\n\
    level_elevation = base_level.Elevation\n\
    \n\
    # Create outer ceiling (with hole)\n\
    base_ceiling = Ceiling.Create(doc, outer_ceiling_loops, ceiling_type.Id, base_level.Id)\n\
    param = base_ceiling.LookupParameter('Height Offset From Level')\n\
    if param:\n\
        param.Set(outer_height - level_elevation)\n\
    \n\
    # Create inner (raised) ceiling\n\
    inner_loops = [inner_loop]\n\
    tray_ceiling = Ceiling.Create(doc, inner_loops, ceiling_type.Id, base_level.Id)\n\
    param = tray_ceiling.LookupParameter('Height Offset From Level')\n\
    if param:\n\
        param.Set(inner_height - level_elevation)\n\
    \n\
    return {\n\
        \"base_ceiling\": base_ceiling,\n\
        \"tray_ceiling\": tray_ceiling\n\
    }\n\
\n\
# Main execution\n\
# Prompt user to select a room\n\
reference = uidoc.Selection.PickObject(ObjectType.Element, \n\
                                     CustomRoomFilter(), \n\
                                     \"Please select a room\")\n\
room = doc.GetElement(reference.ElementId)\n\
\n\
# Start transaction\n\
\n\
# Create tray ceiling with 10' outer and 11' inner heights\n\
result = create_tray_ceiling(doc, room, offset_distance=2.0, outer_height=10.0, inner_height=11.0)"
            }
        }
    ],
    "edges": [
        {
            "id": "1-2",
            "source": "1",
            "target": "2"
        },
        {
            "id": "2-3",
            "source": "2",
            "target": "3"
        }
    ]
}