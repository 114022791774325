import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Modal = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 560px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
`;

export const InviteForm = styled.form`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

export const EmailInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const SendButton = styled.button`
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const Section = styled.div`
  margin-bottom: 24px;

  h3 {
    margin-bottom: 12px;
    color: #666;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
`;

export const ItemInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Status = styled.span`
  font-size: 0.9em;
  color: #666;
`;

export const Email = styled.span`
  color: #666;
`;

export const Role = styled.span`
  font-size: 0.9em;
  color: #666;
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 12px;
`;

export const DeleteButton = styled.button`
  padding: 4px 8px;
  background-color: transparent;
  color: #ff4444;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`; 