import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
`;

const Title = styled.h2`
  margin: 0 0 16px 0;
  font-size: 18px;
  color: #000000;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 16px;
  
  &:focus {
    border-color: #3C94D6;
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  
  ${props => props.primary ? `
    background: #3C94D6;
    color: white;
    &:hover {
      background: #2d7ab8;
    }
  ` : `
    background: #f5f5f5;
    color: #666666;
    &:hover {
      background: #e0e0e0;
    }
  `}
`;

interface WorkflowNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  initialName?: string;
}

const WorkflowNameModal: React.FC<WorkflowNameModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialName = ''
}) => {
  const [name, setName] = React.useState(initialName);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim()) {
      onSubmit(name.trim());
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <form onSubmit={handleSubmit}>
          <Title>{initialName ? 'Edit Workflow Name' : 'New Workflow'}</Title>
          <Input
            type="text"
            placeholder="Enter workflow name"
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus
          />
          <ButtonGroup>
            <Button type="button" onClick={onClose}>Cancel</Button>
            <Button type="submit" primary disabled={!name.trim()}>
              {initialName ? 'Save' : 'Create'}
            </Button>
          </ButtonGroup>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default WorkflowNameModal; 