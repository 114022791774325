import React, { useEffect, useState, useRef } from 'react';
import { useEditorStore } from '../../stores/editorStore';
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarHeaderButton,
  SidebarHeaderButtonGroup,
  SidebarTitle,
  ToggleButton,
} from '../../ui-components/Sidebar.styles';
import { DynamicSvg } from '../../ui-components/DynamicSvg';
import {
  ChatLogScrollContainer,
} from './ChatComponents.style';
import ChatInputBox from './ChatInputBox';
import { UserChatMessageBox } from './UserChatMessageBox';
import { AIChatMessageBox } from './AIChatMessageBox';
import { Checkpoint, Client, Config } from "@langchain/langgraph-sdk";
import { captureActiveView, runCodeInRevit, runWorkflowInRevit, startTransactionGroup, endTransactionGroup } from '../../utils/socketUtils';
import { useSocketStore } from '../../stores/socketStore';
import { ThinkingStep } from './ThinkingStep';
import { WorkflowDefinition } from '../../workflow/types';
import { useWorkflowStore } from '../../stores/workflowStore';
import { useEdges } from 'reactflow';
import { PendingAttachment } from './ChatInputBox';
import { getLangGraphSupabaseClient } from '../../utils/langgraphSupabase';
import { supabase } from '../../utils/supabase';

// Instead of caching the client, we'll cache the service
let serviceInstance: any = null;
const getClient = async () => {
  if (!serviceInstance) {
    serviceInstance = await getLangGraphSupabaseClient(supabase);
  }
  return serviceInstance.getClient();
};

const ChatPanel = () => {
  const [threadMessageCounts, setThreadMessageCounts] = useState<Record<string, number>>({});
  const { 
    isRightSidebarCollapsed, toggleRightSidebar,
    chatMessages, addChatMessage, updateLastChatMessage, getChatThreadId,
    setThinkingState, getThinkingState, updateSignedUrl, activeId: editorActiveId 
  } = useEditorStore();
  const [isDemoMode, setIsDemoMode] = useState(false);

  const {executionBridge, activeId, workflows} = useWorkflowStore();
  const { socket } = useSocketStore();

  const roomAreaMockChatMessages = require("../../mock-data/room-area-chat-responses.json");
  const modWindows1MockChatMessages = require("../../mock-data/mod-windows-1-chat-responses.json");
  const modWindows2MockChatMessages = require("../../mock-data/mod-windows-2-chat-responses.json");
  const addTrayCeilingMockChatMessages = require("../../mock-data/add-tray-ceiling-chat-responses.json");

  const chatLogRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setIsDemoMode(urlParams.has('d') && urlParams.get('d') === 'true');
  }, []);

  useEffect(() => {
    const threadId = getChatThreadId();
    // Only update counts for non-assistant messages
    const assistantMessageCount = chatMessages?.filter(msg => msg.role === "assistant").length;
    setThreadMessageCounts(prev => ({
      ...prev,
      [threadId]: assistantMessageCount
    }));
  }, [getChatThreadId()]);

  // Modified scroll behavior with delay and force
  useEffect(() => {


    const scrollToBottom = () => {
      if (chatLogRef.current) {
        chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
      }
    };

    // Immediate scroll
    scrollToBottom();
    
    // Delayed scroll to handle any dynamic content
    const timeoutId = setTimeout(scrollToBottom, 100);

    return () => clearTimeout(timeoutId);
  }, [chatMessages]);

  const onUserSubmit = async (text: string, attachments: PendingAttachment[] = []) => {
    const chatThreadId = getChatThreadId();
  

    // Update the message count BEFORE adding the assistant's message
    const currentAssistantCount = chatMessages.filter(msg => msg.role === "assistant").length;
    setThreadMessageCounts(prev => ({
      ...prev,
      [chatThreadId]: currentAssistantCount
    }));


    // Then add the mock response
    if (text.includes("area") && isDemoMode) {
      addChatMessage({"role": "user", "content": [{ type: "text", text }]}, chatThreadId);
      addChatMessage(roomAreaMockChatMessages[0], chatThreadId);
    } else if (text.includes("window") && !text.includes("south") && isDemoMode) {
      addChatMessage({"role": "user", "content": [{ type: "text", text }]}, chatThreadId);
      addChatMessage(modWindows1MockChatMessages[0], chatThreadId);
    } else if (text.includes("south") && isDemoMode) {
      addChatMessage({"role": "user", "content": [{ type: "text", text }]}, chatThreadId);
      addChatMessage(modWindows2MockChatMessages[0], chatThreadId);
    } else if (text.includes("ceiling") && isDemoMode) {
      addChatMessage({"role": "user", "content": [{ type: "text", text }]}, chatThreadId);
      addChatMessage(addTrayCeilingMockChatMessages[0], chatThreadId);
    }
    else {
      // Use the lazy-loaded client
      const client = await getClient();
      const thread = await client.threads.create();
      const messages = [
        ...chatMessages.slice(-15).map(msg => ({
          role: msg.role,
          content: msg.content.map(content => {
            if (content.type === "run-log-in-chat") {
              return {
                type: "text",
                text: `Previous workflow run: ${JSON.stringify(content)}`
              };
            }
            if (content.type === "workflow-button") {
              return {
                type: "text",
                text: `A previous workflow in the chat: ${JSON.stringify(content.workflow)}`
              };
            }
            return content;
          })
        })),
        { 
          role: "user", 
          content: [
            { type: "text", text },
            ...attachments.map(att => ({
              type: att.fileType !== "image/png" && att.fileType !== "image/jpeg" ? "document" : "image" as "document" | "image",
              url: att.url,
              fileName: att.fileName,
              fileType: att.fileType,
              expiresAt: att.expiresAt,
              filePath: att.filePath
            }))
          ] 
        }
      ]

      for (const message of messages) {
        // Check each content item for a filePath property
        for (const content of message.content) {
          if ('filePath' in content && content.filePath) {
            const signedUrl = await updateSignedUrl(chatThreadId, content.filePath, 
              content.expiresAt ? new Date(content.expiresAt).getTime() : 0, 
              content.url || "");
            content.url = signedUrl;
          }
          if (content.type === "text" && content.text == "") {
            content.text = "no response"
          }
        }
      }

      let stream = client.runs.stream(
        thread.thread_id, // Thread ID
        "revit_assistant", // Assistant ID
        { 
          input: { 
            messages: messages,
            current_workflow: workflows[chatThreadId]
          },
          streamMode: ["values", "custom", "updates"],
          streamSubgraphs: true,
        }
      );
      addChatMessage({
        role: "user",
        content: [
            { type: "text", text },
            ...attachments.map(att => ({
              type: att.fileType !== "image/png" && att.fileType !== "image/jpeg" ? "document" : "image" as "document" | "image",
              url: att.url,
              fileName: att.fileName,
              fileType: att.fileType,
              filePath: att.filePath,
              expiresAt: att.expiresAt
            }))
        ]
    }, chatThreadId);
      setThinkingState({
        isThinking: true,
        stepTitle: "Thinking"
      }, chatThreadId);
      let runId = null;
      let continueRunning = true;
      const langgraph_messages_state: any[] = [];
      while (continueRunning) {
        continueRunning = false;
        for await (const chunk of stream) {
          console.log(chunk);
          if (chunk.event === "metadata") {
            runId = chunk.data.run_id;
          }
          if (chunk.event === "error") {
            addChatMessage({
              role: "assistant",
              content: [{ type: "text", text: "I encountered an error processing your request. Please try again." }]
            }, chatThreadId);
            setThinkingState(null, chatThreadId);
            break;
          }
          if (chunk.event === "custom") {
            if (chunk.data.workflow_json) {
              addChatMessage({
                role: "assistant",
                content: [{ type: "workflow-button", title: "New Custom Workflow", workflow: chunk.data.workflow_json }]
              }, chatThreadId);
            }
            else {
              setThinkingState({
                isThinking: true,
                stepTitle: chunk.data.step_display_title
              }, chatThreadId);
            }
          }
          if (chunk.event === "updates") {
            if (chunk.data?.coordinator?.messages) {
              langgraph_messages_state.concat(chunk.data.coordinator.messages);
              for (const message of chunk.data.coordinator.messages) {
                if (message.type == "ai") {
                  addChatMessage({
                    role: "assistant",
                    content: [{ type: "text", text: message.content }]
                  }, chatThreadId);
                }
              }
            } else if (chunk.data?.output_workflow_commentary?.messages) {
              langgraph_messages_state.concat(chunk.data.output_workflow_commentary.messages);
              for (const message of chunk.data.output_workflow_commentary.messages) {
                if (message.type == "ai") {
                  addChatMessage({
                    role: "assistant",
                    content: [{ type: "text", text: message.content }]
                  }, chatThreadId);
                }
              }
            }
          }

          // const state = await client.threads.getState(
          //   thread.thread_id, 
          //   undefined, 
          //   { subgraphs: true }
          // );
          if (chunk.event.startsWith("values")) {
            if (chunk.data["view_capture_response"] && chunk.data["view_capture_response"] == "pending") {
              console.log("View capture");
              if (!socket) {
                console.error("Socket is not connected");
                // TODO: handle this better
                continue;
              }
              const imageUrl = await captureActiveView(socket);
              if (imageUrl) {
                console.log("View capture image url", imageUrl);
                const state = await client.threads.getState(
                  thread.thread_id, 
                  undefined, 
                  { subgraphs: true }
                );
                const task = state.tasks[0];
                console.log("Task", task);
                const subgraphState = task?.state;
                if (!subgraphState) {
                  console.error("Subgraph state not found");
                  continue;
                }
                const updateResponse = await client.threads.updateState(thread.thread_id, {
                  values: {
                    view_capture_response: {
                      view_name: "Living Room",
                      image_data: imageUrl,
                    },
                  },
                  checkpoint: subgraphState.checkpoint,
                  checkpointId: subgraphState.checkpoint.checkpoint_id!,
                  asNode: "view_capture_execute"
                });
                stream = client.runs.stream(
                  thread.thread_id, // Thread ID
                  "revit_assistant", // Assistant ID
                  {
                    input: null,
                    streamMode: ["values", "custom", "updates"],
                    streamSubgraphs: true,
                    config: updateResponse,
                  });
                  continueRunning = true;
                  break; // break out of the inner loop to process the new stream
              }
            }
            else if (chunk.data["script_execution_outcome"]) {
              if (chunk.data["script_execution_outcome"] === "pending") {
                const script = chunk.data["script"];
                if (script) {
                  // Run script in Revit and await response
                  if (!socket) {
                    console.error("Socket is not connected");
                    // TODO: handle this better
                    continue;
                  }

                  console.log("Workflow", workflows[chatThreadId]);
                  var workflow: WorkflowDefinition = {
                    nodes: [],
                    edges: [],
                    state: {}
                  }
                  workflow.nodes.push({
                    id: "start",
                    type: "start",
                    data: {
                      type: "start",
                      label: "Start"
                    },
                    position: { x: 0, y: 0 }
                  });

                  workflow.nodes.push({
                    id: "code",
                    type: "code",
                    data: {
                      code: "\n" + script,
                      type: "code",
                      label: "Code"
                    },
                    position: { x: 0, y: 0 }
                  });
                  workflow.edges.push({
                    id: "start-code",
                    source: "start",
                    target: "code",
                    type: "smoothstep"
                  });

                  console.log("Workflow", workflow);

                  const workflowResult = await executionBridge.temporaryWorkflowStartExecution(120, workflow);

                  console.log("Script result:", workflowResult);
                  const state = await client.threads.getState(
                    thread.thread_id, 
                    undefined, 
                    { subgraphs: true }
                  );
                  const task = state.tasks[0];
                  const subgraphState = task?.state;
                  if (!subgraphState) {
                    continue;
                  }

                  var script_execution = {
                    error: null as string | null,
                    output: null as string | null
                  };
                  
                  // Find the script execution result from nodeResults
                  if (workflowResult.nodesExecutedResults) {
                    const scriptNode = workflowResult.nodesExecutedResults.find(
                      (node: any) => node.nodeId === "code"
                    );
                    if (scriptNode) {
                      console.log("Script node", scriptNode.result);
                      script_execution = {
                        error: scriptNode.error || null,
                        output: typeof scriptNode.result === 'string' 
                          ? scriptNode.result 
                          : JSON.stringify(scriptNode.result) || null
                      };
                    }
                  }

                  var values = {
                    script_execution_outcome: script_execution.error ? "failure" : "success",
                    script_execution_result: script_execution.output,
                    script_execution_error: script_execution.error,
                  }

                  console.log("Values", values);

                  const updateResponse = await client.threads.updateState(thread.thread_id, {
                    values: values,
                    checkpoint: subgraphState.checkpoint,
                    checkpointId: subgraphState.checkpoint.checkpoint_id!,
                    asNode: "python_query_executor"
                  });
                  
                  stream = client.runs.stream(
                    thread.thread_id, // Thread ID
                    "revit_assistant", // Assistant ID
                    {
                      input: null,
                      streamMode: ["values", "custom", "updates"],
                      streamSubgraphs: true,
                      config: updateResponse,
                    });
                  
                  continueRunning = true;
                  break; // break out of the inner loop to process the new stream
                }
              }
            }
            else if (chunk.data["workflow_execution_outcome"]) {
              console.log("HERE!!!", chunk);
              if (chunk.data["workflow_execution_outcome"] === "pending") {
                const workflow = chunk.data["workflow_json"];
                if (workflow) {
                  console.log("Workflow initial", workflow);
                  console.log("chunk.data", chunk.data);
                  // Run script in Revit and await response
                  if (!socket) {
                    console.error("Socket is not connected");
                    // TODO: handle this better
                    continue;
                  }
                  // Find the last node by looking for a node that isn't a source in any edge
                  const sources = workflow.edges.map((edge: any) => edge.source);
                  const validationNode = chunk.data["validation_node"];
                  console.log("Validation node", validationNode, workflow);
                  //THIS IS A SPECIAL ID; it will always be run at the end of the workflow
                  validationNode.id = "archilabs_reserved_validation_node";
                  validationNode.data = {
                    type: validationNode.type || 'code',
                    label: validationNode.label || 'Validation',
                    code: validationNode.code || 'def execute(state): return {"success": True}',
                    explanation: validationNode.explanation || 'This is a validation node that will always be run at the end of the workflow'
                  };
                  workflow.nodes.push(validationNode);
                  console.log("Workflow", workflow);
                  const workflowResult = await executionBridge.temporaryWorkflowStartExecution(120, workflow)
                  console.log("Workflow result", workflowResult);
                  const state = await client.threads.getState(
                    thread.thread_id, 
                    undefined, 
                    { subgraphs: true }
                  );
                  const task = state.tasks[0];
                  const subgraphState = task?.state;
                  if (!subgraphState) {
                    continue;
                  }
                  
                  const updateResponse = await client.threads.updateState(thread.thread_id, {
                    values: {
                      workflow_execution_outcome: !workflowResult.nodesExecutedResults || 
                        workflowResult.nodesExecutedResults.some((node: any) => node.error) 
                          ? "failure" 
                          : "success",
                      workflow_execution_result: workflowResult,
                    },
                    checkpoint: subgraphState.checkpoint,
                    checkpointId: subgraphState.checkpoint.checkpoint_id!,
                    asNode: "workflow_executor"
                  });
                  stream = client.runs.stream(
                    thread.thread_id, // Thread ID
                    "revit_assistant", // Assistant ID
                    {
                      input: null,
                      streamMode: ["values", "custom", "updates"],
                      streamSubgraphs: true,
                      config: updateResponse,
                    });
                  
                  continueRunning = true;
                  break; // break out of the inner loop to process the new stream
                }
              }
            }
          }
        }
      }
      setThinkingState(null, chatThreadId);
    }

    // Stream response from assistant
    // const streamResponse = client.runs.stream(
    //   null, // Threadless run
    //   "revit_assistant", // Assistant ID
    //   {
    //       input: {
    //           "messages": [
    //               { "role": "user", "content": text}
    //           ]
    //       },
    //       streamMode: "messages",
    //   }
    // );
    // let lastMessage = null;
    // for await (const chunk of streamResponse) {
    //     console.log(`Receiving new event of type: ${chunk.event}...`);
    //     console.log(chunk);
    //     if (!lastMessage) {
    //       if (!chunk.data || chunk.data.length == 0) {
    //         continue;
    //       }
    //       if (!chunk.data[0]) {
    //         console.log("No data in chunk");
    //         continue;
    //       }
    //       lastMessage = chunk.data[0];
    //       addChatMessage({
    //         role: "assistant",
    //         content: [{
    //           type: "text",
    //           text: lastMessage.content
    //         }]
    //       });
    //     } else {
    //       if (!chunk.data || chunk.data.length == 0) {
    //         continue;
    //       }
    //       if (!chunk.data[0]) {
    //         console.log("No data in chunk");
    //         continue;
    //       }
    //       lastMessage = chunk.data[0];
    //       updateLastChatMessage({
    //         role: "assistant",
    //         content: [{
    //           type: "text",
    //           text: lastMessage.content
    //         }],
    //       });
    //     }
    //     console.log("\n\n");
    // }

    // if (text.includes("area")) {
    //   addChatMessage(roomAreaMockChatMessages[0]);
    // } else if (text.includes("window") && !text.includes("south")) {
    //   addChatMessage(modWindows1MockChatMessages[0]);
    // } else if (text.includes("south")) {
    //   addChatMessage(modWindows2MockChatMessages[0]);
    // } else if (text.includes("ceiling")) {
    //   addChatMessage(addTrayCeilingMockChatMessages[0]);
    // }
  }
  
  return (
    <Sidebar 
      isCollapsed={isRightSidebarCollapsed} 
      sidebarPosition="right"
      width={350}
    >
      <ToggleButton 
        onClick={() => toggleRightSidebar(!isRightSidebarCollapsed)}
        sidebarPosition="right"
      >
        <DynamicSvg 
          name={isRightSidebarCollapsed ? "ChevronLeft" : "ChevronRight"} 
          width={12} 
          height={12}
          customIconColor="#3C94D6"
        />
      </ToggleButton>
      
      <SidebarContent isCollapsed={isRightSidebarCollapsed}>
        <SidebarHeader> 
          <SidebarTitle>Chat</SidebarTitle>
          {/**
          <SidebarHeaderButtonGroup>
            <SidebarHeaderButton>
              <DynamicSvg name="Plus" /> 
            </SidebarHeaderButton>
            <SidebarHeaderButton>
              <DynamicSvg name="History" />
            </SidebarHeaderButton>
          </SidebarHeaderButtonGroup>
          **/}
        </SidebarHeader>
        <ChatLogScrollContainer ref={chatLogRef} style={{ overflowX: 'hidden' }}>
          {chatMessages?.map((message, index) => {
            const threadId = getChatThreadId();
            const isNew = index > (threadMessageCounts[threadId] || 0) && index === chatMessages.length - 1;
            
            if (message.role === "user") {
              return <UserChatMessageBox key={`message-${index}`} message={message} />;
            } else if (message.role === "assistant") {
              return <AIChatMessageBox key={`message-${index}`} message={message} isNew={isNew} />;
            }
          })}
          {getThinkingState(getChatThreadId()) && 
            <ThinkingStep step={getThinkingState(getChatThreadId())!.stepTitle} />
          }
          {/* <UserChatMessageBox>
            /workflow move all the electrical outlets in the living room to be 45" from the floor
          </UserChatMessageBox>
          <AIChatMessageBox>
            <p>
              Here's a workflow you can use to reposition all the outlets in the living room
            </p>
            <ChatEmbeddedWorkflowButton />
            <RunLogInChatTitle>Outlets Respositioning Workflow</RunLogInChatTitle>
            <RunLogInChatContainer>
              <RunLogInChatStepItem>
                <StepIcon>
                  <DynamicSvg name="Code" width={16} height={16} />
                </StepIcon>
                <StepContent>
                  <StepHeader>
                    <StepTitle>Find Living Room Outlets</StepTitle>
                    <StepMetadata>
                      <StepStatusTag type="time">0.05s</StepStatusTag>
                      <StepStatusTag type="success">Success</StepStatusTag>
                    </StepMetadata>
                  </StepHeader>
                  <StepDetails isExpanded={false}>
                    Found 6 electrical outlets in living room
                  </StepDetails>
                </StepContent>
              </RunLogInChatStepItem>
              
              <RunLogInChatStepItem>
                <StepIcon>
                  <DynamicSvg name="Entry" width={16} height={16} />
                </StepIcon>
                <StepContent>
                  <StepHeader>
                    <StepTitle>Adjust Outlet Heights</StepTitle>
                    <StepMetadata>
                      <StepStatusTag type="time">2.12s</StepStatusTag>
                      <StepStatusTag type="success">Success</StepStatusTag>
                    </StepMetadata>
                  </StepHeader>
                  <StepDetails isExpanded={false}>
                    Moved 6 outlets to 45" height from floor level
                  </StepDetails>
                </StepContent>
              </RunLogInChatStepItem>
            </RunLogInChatContainer>
          </AIChatMessageBox> */}
        </ChatLogScrollContainer>
        <div style={{ padding: '0 0px 20px 0px' }}>
          <ChatInputBox 
            placeholder={
              workflows[activeId]?.is_shared
                ? "Can't chat with an AI for shared workflows"
                : editorActiveId === activeId
                  ? "Cannot Chat With This Workflow"
                  : activeId === 'default'
                    ? "Create or select a workflow to begin"
                    : chatMessages?.length === 0
                      ? "What do you need help with?"
                      : "Ask a follow up question..."
            }
            onSubmit={onUserSubmit}
            disabled={activeId === 'default' || workflows[activeId]?.is_shared || editorActiveId == "default" || editorActiveId === activeId}
          />
        </div>
      </SidebarContent>
    </Sidebar>
  );
};

export default ChatPanel;
