import { Node } from 'reactflow';
import { CustomNodeData } from '../components/WorkflowPanel/types/nodes';
import { WorkflowEngine, WorkflowDefinition, NodeExecutor, WorkflowStateManager, WorkflowExecutionResult } from './types';
import { useSocketStore } from '../stores/socketStore';
import { startTransactionGroup, endTransactionGroup } from '../utils/socketUtils';
import { useWorkflowStore } from '../stores/workflowStore';

export function createWorkflowEngine(
  workflowDefinition: WorkflowDefinition,
  stateManager: WorkflowStateManager,
  executionId: string
): WorkflowEngine {
  const nodeExecutors = new Map<string, NodeExecutor>();
  
  async function execute(): Promise<WorkflowExecutionResult> {
    console.log('Executing workflow engine');
    // Try to acquire execution lock
    if (!stateManager.acquireExecutionLock(executionId)) {
      throw new Error('Another workflow is currently running');
    }
    var socket = useSocketStore.getState().socket;

    var commit = false;
    var finalState: WorkflowExecutionResult = {
      runtimeState: {},
      nodeErrors: {},
      runId: executionId,
      runLastAliveTime: Date.now(),
      nodesToExecute: [],
      edgesToExecute: [],
      nodesExecutedResults: [],
      nodeExecuting: null
    };
    try {
      // Initialize runtime state with deep copy of workflow state
      stateManager.addNodesToExecute(workflowDefinition.nodes);
      stateManager.addEdgesToExecute(workflowDefinition.edges);
      const initialState = JSON.parse(JSON.stringify(workflowDefinition.state));
      console.log('Initializing workflow state:', initialState);

      if (!socket) {
        throw new Error('Socket connection not available');
      }
      await startTransactionGroup(socket);

      stateManager.setState(initialState || {});

      const startNode = findStartNode();
      if (!startNode) {
        throw new Error('No start node found in workflow');
      }

      stateManager.setNodeExecuting(startNode.id);
      const visitCounts = new Map<string, number>();
      const MAX_NODE_VISITS = 20;
      let hasErrors = false;

      while (true && !hasErrors) {
        console.log('Getting next node to execute');
        const nextNode = stateManager.getNextNodeToExecute();
        if (!nextNode) {
          console.log('No next node to execute');
          break;
        }

        // Track visit count for this node
        const currentVisits = (visitCounts.get(nextNode.id) || 0) + 1;
        visitCounts.set(nextNode.id, currentVisits);

        // Max visits per node check
        if (currentVisits > MAX_NODE_VISITS) {
          const error = `Node ${nextNode.id} exceeded maximum visit limit of ${MAX_NODE_VISITS}`;
          stateManager.setNodeError(nextNode.id, error);
          hasErrors = true;
          break;
        }

        try {
          console.log('Executing node:', nextNode.id);
          stateManager.setNodeExecuting(nextNode.id);
          await executeNode(nextNode);
        } catch (error) {
          console.error(`Error executing node ${nextNode.id}:`, error);
          hasErrors = true;
          // Continue execution to find all errors
        }
      }

      finalState = stateManager.returnRuntimeState();

      if (!hasErrors) {
        // Only reset state if execution was successful
        commit = true;
      }
      await stateManager.reset();
      useWorkflowStore.getState().setWhichWorkflowIsRunning(null);
    } catch (error) {
      // Don't reset state on error so errors remain visible
      commit = false;
      throw error;
    } finally {
      if (socket) {
        await endTransactionGroup(socket, commit);
      }
      return finalState;
    }
  }

  async function executeNode(node: Node<CustomNodeData>): Promise<void> {
    const executor = nodeExecutors.get(node.type || '');
    if (!executor) {
      const error = `No executor found for node type: ${node.type}`;
      stateManager.setNodeError(node.id, error);
      throw new Error(error);
    }

    // Clear any previous errors before execution
    stateManager.clearNodeError(node.id);

    const startTime = performance.now();
    const executionResult = await executor.execute(node, {
      workflowDefinition,
      stateManager
    });
    const endTime = performance.now();
    const executionTime = endTime - startTime;

    executionResult.executionTime = executionTime;


    stateManager.setNodeExecutedResult(node.id, executionResult);
    
    if (executionResult.success) {
      // Update shared state with executor's result only if execution was successful
      stateManager.setState(executionResult.result);
    } else {
      // Record the error in the state manager
      stateManager.setNodeError(node.id, executionResult.error || 'Unknown error occurred');
      throw new Error(executionResult.error || 'Unknown error occurred during node execution');
    }
  }

  function findStartNode(): Node<CustomNodeData> | null {
    return workflowDefinition.nodes.find(node => node.type === 'start') || null;
  }

  return {
    execute,
    registerNodeExecutor: (nodeType, executor) => {
      nodeExecutors.set(nodeType, executor);
    },
    stop: () => {
      stateManager.reset();
    },
    getExecutionId: () => executionId
  };
} 