import { Node } from 'reactflow';
import { CustomNodeData } from '../../components/WorkflowPanel/types/nodes';
import { NodeExecutor, NodeExecutionContext, NodeExecutionResult } from '../types';
import { runFunctionInRevit } from '../../utils/socketUtils';
import { useSocketStore } from '../../stores/socketStore';
import { createSignedUrl } from '../../utils/supabase';
import { Client } from "@langchain/langgraph-sdk";
import { getLangGraphSupabaseClient } from '../../utils/langgraphSupabase';
import { supabase } from '../../utils/supabase';

// Instead of direct initialization, create a lazy-loaded singleton
let serviceInstance: any = null;
const getClient = async () => {
  if (!serviceInstance) {
    serviceInstance = await getLangGraphSupabaseClient(supabase);
  }
  return serviceInstance.getClient();
};
export function createCodeNodeExecutor(): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      console.log('Executing code node:', node.id);
      
      try {
        // Ensure we're dealing with a code node and get the code
        if (node.data.type !== 'code') {
          return {
            result: {},
            error: 'Invalid node type. Expected code node.',
            success: false
          };
        }
        
        const code = node.data.code;
        const socket = useSocketStore.getState().socket;

        if (!socket) {
          return {
            result: {},
            error: 'Socket connection not available',
            success: false
          };
        }

        // Get current state
        const currentState = context.stateManager.getState();

        console.log('Current state:', currentState);
        // Execute the function with current state
        var readOnly = false;
        if (node.data.codeBlockType === 'Python-Read-Only') {
          readOnly = true;
        }

        const response = await runFunctionInRevit(socket, code, currentState, readOnly);
        console.log('Response:', response);
        if (response.error) {
          return {
            result: {},
            error: response.error,
            success: false
          };
        }
        
        return {
          result: response.result,
          error: response.error,
          success: response.success
        };

      } catch (error) {
        console.error('Error executing code node:', error);
        return {
          result: {},
          error: error instanceof Error ? error.message : 'Unknown error occurred',
          success: false
        };
      }
    }
  };
}

export function createAIPromptNodeExecutor(): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      console.log('Executing AI prompt node:', node.id);
      
      try {
        if (node.data.type !== 'ai_prompt') {
          return {
            result: {},
            error: 'Invalid node type. Expected ai_prompt node.',
            success: false
          };
        }

        const prompt = node.data.prompt;
        const inputs = node.data.inputs || [];
        const outputs = node.data.outputs || [];

        // Get current state and process any file references
        const currentState = context.stateManager.getState();
        
        // Process inputs to match the format expected by the AI service
        const processedInputs = await Object.entries(currentState).reduce(async (accPromise, [key, value]) => {
          const acc = await accPromise;
          if (typeof value === 'string' && value.startsWith('ARCHILABS_STORED_FILE:')) {
            const [_, fullFilePath, fileType] = value.split(':');
            const signedUrlData = await createSignedUrl(fullFilePath);
            return {
              ...acc,
              [key]: {
                type: 'media',
                filePath: fullFilePath,
                fileType: fileType,
                url: signedUrlData?.data?.signedUrl || '',
              }
            };
          }
          return {
            ...acc,
            [key]: value
          };
        }, Promise.resolve({} as Record<string, any>));

        // Use the lazy-loaded client
        const client = await getClient();
        const thread = await client.threads.create();
        
        // Format the input for the AI service
        const formattedInputs = inputs.reduce((acc: Record<string, any>, input: string) => ({
          ...acc,
          [input]: processedInputs[input]
        }), {});

        let stream = client.runs.stream(
          thread.thread_id,
          "ai_node",
          {
            input: {
              prompt,
              inputs: formattedInputs,
              expected_outputs: outputs
            },
            streamMode: ["values", "custom", "updates"],
            streamSubgraphs: true,
          }
        );

        let finalResult: Record<string, any> = {};
        let finalError: string | null = null;

        for await (const chunk of stream) {
          console.log('AI node chunk:', chunk);
          
          if (chunk.event === "values" && chunk.data.outputs) {
            finalResult = chunk.data.outputs;
          }
          
          if (chunk.event === "custom" && chunk.data.error) {
            finalError = chunk.data.error;
          }
        }

        console.log("Final result:", finalResult);
        console.log("Final error:", finalError);

        if (finalError) {
          console.error('AI node error:', finalError);
          return {
            result: {},
            error: finalError,
            success: false
          };
        }

        // Validate that all expected outputs are present
        const missingOutputs = outputs.filter(output => !(output in finalResult));
        if (missingOutputs.length > 0) {
          return {
            result: {},
            error: `AI response missing required outputs: ${missingOutputs.join(', ')}`,
            success: false
          };
        }

        return {
          result: finalResult,
          error: null,
          success: true
        };

      } catch (error) {
        console.error('Error executing AI prompt node:', error);
        return {
          result: {},
          error: error instanceof Error ? error.message : 'Unknown error occurred',
          success: false
        };
      }
    }
  };
}


export function createBranchNodeExecutor(): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      console.log('Executing branch node:', node.id);
      
      try {
        if (node.data.type !== 'branch') {
          return {
            result: {},
            error: 'Invalid node type. Expected branch node.',
            success: false
          };
        }

        const socket = useSocketStore.getState().socket;
        if (!socket) {
          return {
            result: {},
            error: 'Socket connection not available',
            success: false
          };
        }

        const currentState = context.stateManager.getState();
        const sourceHandles = node.data.sourceHandles || [];

        if (node.data.mode === 'advanced') {
          // Execute the custom Python code in Revit
          const code = node.data.code || '';
          const response = await runFunctionInRevit(socket, code, currentState, true);
          
          if (response.error) {
            return {
              result: {},
              error: response.error,
              success: false
            };
          }

          return {
            result: response.result,
            error: null,
            success: true
          };

        } else {
          // Simple mode: Evaluate conditions and return the matching edge ID
          const conditionCode = `
def coerce_value(value):
    if not value:
        return value
    if isinstance(value, (int, float)):
        return value
    if isinstance(value, str):
        # Try to convert to number if it looks like one
        try:
            if '.' in value:
                return float(value)
            return int(value)
        except ValueError:
            # If it's not a number, return original string
            return value
    return value

def execute(state):
    # Unwrap state variables into the global scope
    ${Object.entries(currentState)
      .map(([key, value]) => `${key} = coerce_value(state.get("${key}"))`)
      .join('\n    ')}
    
    try:
        # Evaluate each condition in order
        ${sourceHandles.map(sourceHandle => `
        try:
            if ${sourceHandle.condition}:
                return {
                    "found_condition": True,
                    "source_handle_id": "${sourceHandle.id}"
                }
        except Exception as e:
            print(f"Error evaluating condition for edge ${sourceHandle.id}: {str(e)}")
        `).join('\n        ')}
        
        # If no conditions matched
        return {
            "found_condition": False,
            "error": "No conditions were met",
            "source_handle_id": ""
        }
    except Exception as e:
        return {
            "found_condition": False,
            "error": str(e),
            "source_handle_id": ""
        }
`;


          console.log("conditionCode", conditionCode);

          // Clear any state variables that match edge names to avoid conflicts
          sourceHandles.forEach(sourceHandle => {
            if (sourceHandle.name && sourceHandle.name in currentState) {
              delete currentState[sourceHandle.name];
            }
          });
          const response = await runFunctionInRevit(socket, conditionCode, currentState, true);
          
          if (response.error) {
            return {
              result: {},
              error: response.error,
              success: false
            };
          }


          return {
            result: {"archilabs_reserved_branch_source_handle_id": response.result.source_handle_id},
            error: null,
            success: true
          };
        }

      } catch (error) {
        console.error('Error executing branch node:', error);
        return {
          result: {},
          error: error instanceof Error ? error.message : 'Unknown error occurred',
          success: false
        };
      }
    }
  };
}

export function createUserInputNodeExecutor(): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      if (node.data.type !== 'user_input') {
        return {
          result: {},
          error: 'Invalid node type. Expected user_input node.',
          success: false
        };
      }

      // Get the user inputs and process them
      const userInputs = node.data.inputs;
      var allowUserToFill = false;

      // Check if any inputs can be edited during runtime
      for (const input of userInputs) {
        if (input.can_edit_during_runtime) {
          allowUserToFill = true;
        }
      }

      interface UserInputResult {
        [key: string]: any;
      }

      var res: UserInputResult = {};

      // If no inputs can be edited during runtime, use default values
      if (!allowUserToFill) {
        for (const input of userInputs) {
          const name = input.name;
          if (input.input_type === 'file') {
            if (input.default_file) {
              res[name] = `ARCHILABS_STORED_FILE:${input.default_file.filePath}:${input.default_file.fileType}`;
            } else {
              res[name] = '';
            }
          } else if (input.input_type.includes('multiselect')) {
            // Convert comma-separated string to array for multiselect
            res[name] = input.default_value ? input.default_value.split(',') : [];
          } else {
            // For text and dropdown types
            res[name] = input.default_value || '';
          }
        }
        return {
          result: res,
          error: null,
          success: true
        };
      }

      // Initialize runtime values with defaults when execution starts
      var updatedInputs = userInputs.map(input => ({
        ...input,
        runtime_value: input.default_value,
        runtime_file: input.default_file
      }));

      // Update options for dynamic dropdowns/multiselects based on current state
      updatedInputs = updatedInputs.map(input => {
        if (input.input_type.includes('_dynamic') && input.options_variable) {
          const stateValue = context.stateManager.getState()[input.options_variable];
          if (Array.isArray(stateValue)) {
            return {
              ...input,
              options: stateValue,
              runtime_value: input.default_value
            };
          }
          else {
            return {
              ...input,
              options: [],
              runtime_value: input.default_value
            };
          }
        }
        return input;
      });
      context.stateManager.updateNodeData(node.id, 'inputs', updatedInputs);

      // Wait for user input by checking the node data periodically
      const first_time_checked = Date.now();
      while (true) {
        const currentNodeUnwrapped = context.stateManager.getNodeData(node.id);
        if (!currentNodeUnwrapped || currentNodeUnwrapped.type !== 'user_input') {
          return {
            result: {},
            error: 'Node not found',
            success: false
          };
        }

        if (currentNodeUnwrapped.finish_inputs_button_time && 
          currentNodeUnwrapped.finish_inputs_button_time > first_time_checked) {
          
          // Process final input values
          for (const input of currentNodeUnwrapped.inputs) {
            const name = input.name;
            if (input.input_type === 'file') {
              if (input.can_edit_during_runtime && input.runtime_file) {
                res[name] = `ARCHILABS_STORED_FILE:${input.runtime_file.filePath}:${input.runtime_file.fileType}`;
              } else if (!input.runtime_file && input.default_file) {
                res[name] = `ARCHILABS_STORED_FILE:${input.default_file.filePath}:${input.default_file.fileType}`;
              } else {
                res[name] = '';
              }
            } else if (input.input_type.includes('multiselect')) {
              // Handle multiselect values
              if (input.can_edit_during_runtime && input.runtime_value != null) {
                res[name] = input.runtime_value ? input.runtime_value.split(',') : [];
              } else if (!input.can_edit_during_runtime && input.default_value) {
                res[name] = input.default_value.split(',');
              } else {
                res[name] = [];
              }
            } else {
              // Handle text and dropdown values
              if (input.can_edit_during_runtime && input.runtime_value != null && input.runtime_value !== '') {
                res[name] = input.runtime_value;
              } else if (!input.can_edit_during_runtime && input.default_value) {
                res[name] = input.default_value;
              } else {
                res[name] = '';
              }
            }
          }
          
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 500));
      }

      return {
        result: res,
        error: null,
        success: true
      };
    }
  };
}

export function createSelectInRevitNodeExecutor(automated = false): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      console.log('Executing Revit selection node:', node.id);

      if (node.data.type !== 'select_in_revit') {
        return {
          result: {},
          error: 'Invalid node type. Expected select_in_revit node.',
          success: false
        };
      }

      const selectionType: string = node.data.selectionType || "Any";

      
      console.log("ND:", node)
      try {
        const socket = useSocketStore.getState().socket;

        if (!socket) {
          return {
            result: {},
            error: 'Socket connection not available',
            success: false
          };
        }

        var code = `
from Autodesk.Revit.UI.Selection import ObjectType, ISelectionFilter
from Autodesk.Revit.DB import ElementId, Level, ViewSheet, Grid, FamilyInstance, BuiltInCategory, Document
from Autodesk.Revit.Exceptions import OperationCanceledException
import System

selection_type = "${selectionType}"  # Define the selection type as a Python variable

class CustomSelectionFilter(ISelectionFilter):
    def __init__(self, selectionType):
        self.selectionType = selectionType
    
    def AllowElement(self, element):
        if self.selectionType == "Any":
            return True
            
        # Handle common element types
        if self.selectionType == "Door":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Doors)
        elif self.selectionType == "Wall":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Walls)
        elif self.selectionType == "Window":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Windows)
        elif self.selectionType == "Floor":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Floors)
        elif self.selectionType == "Ceiling":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Ceilings)
        elif self.selectionType == "Room":
            return element.Category is not None and element.Category.Id.IntegerValue == int(BuiltInCategory.OST_Rooms)
        elif self.selectionType == "Level":
            return isinstance(element, Level)
        elif self.selectionType == "Grid":
            return isinstance(element, Grid)
            
        # Fallback to type name check
        return element.GetType().Name == self.selectionType
    
    def AllowReference(self, reference, point):
        return False

def execute(state):
    selection = uidoc.Selection
    doc = uidoc.Document
    
    try:
        if selection_type != "Views" and selection_type != "Points":
            filter = CustomSelectionFilter(selection_type)
            selected_elements = selection.PickObjects(
                ObjectType.Element, 
                filter, 
                f"Select {selection_type}(s) in the model. Click Finish when done."
            )
            
            # Get actual elements from references
            elements = [doc.GetElement(elem.ElementId) for elem in selected_elements]
            
            # Create a more detailed result
            selection_result = {
                "selectedElementIds": [str(elem.Id.IntegerValue) for elem in elements],
                "count": len(elements),
                "elementNames": [elem.Name if hasattr(elem, 'Name') else 'Unnamed Element' for elem in elements],
                "categoryNames": [elem.Category.Name if elem.Category else 'No Category' for elem in elements],
                f"{selection_type.lower()}_count": len(elements),
                "summary": f"Selected {len(elements)} {selection_type}(s)"
            }
            
            # Structure the result to match what downstream nodes expect
            return {
                f"selected_{selection_type.lower()}s": selection_result,
                "count": len(elements),
                f"{selection_type.lower()}_count": len(elements),
                "summary": f"Selected {len(elements)} {selection_type}(s)"
            }
            
        elif selection_type == "Views":
            selected_view = uidoc.ActiveView
            return {
                "selectedElementIds": [str(selected_view.Id.IntegerValue)],
                "count": 1,
                "elementNames": [selected_view.Name],
                "categoryNames": ["View"],
                "view_count": 1,
                "summary": f"Selected view: {selected_view.Name}"
            }
            
        elif selection_type == "Points":
            points = []
            try:
                while True:
                    point = selection.PickPoint("Select points. Press ESC when done.")
                    points.append(point)
            except OperationCanceledException:
                pass
            
            return {
                "points": [[p.X, p.Y, p.Z] for p in points],
                "count": len(points),
                "point_count": len(points),
                "summary": f"Selected {len(points)} point(s)"
            }
        
        else:
            raise Exception(f"Unsupported selection type: {selection_type}")

    except OperationCanceledException:
        empty_result = {
            "selectedElementIds": [],
            "count": 0,
            "elementNames": [],
            "categoryNames": [],
            f"{selection_type.lower()}_count": 0,
            "summary": "Selection cancelled by user"
        }
        return {
            f"selected_{selection_type.lower()}s": empty_result,
            "count": 0,
            f"{selection_type.lower()}_count": 0,
            "summary": "Selection cancelled by user",
            "error": "Selection cancelled"
        }
    except Exception as e:
        error_msg = str(e)
        print(f"Error during selection: {error_msg}")
        empty_result = {
            "selectedElementIds": [],
            "count": 0,
            "elementNames": [],
            "categoryNames": [],
            f"{selection_type.lower()}_count": 0,
            "summary": f"Error: {error_msg}"
        }
        return {
            f"selected_{selection_type.lower()}s": empty_result,
            "count": 0,
            f"{selection_type.lower()}_count": 0,
            "summary": f"Error: {error_msg}",
            "error": error_msg
        }
`;
    

        if (automated) {
          code = node.data.automated_selection_fallback;
        }

        const currentState = context.stateManager.getState();
        // Call Revit with a special selection mode flag
        const response = await runFunctionInRevit(socket, code, currentState, true);

        return {
          result: response.result,
          error: response.error,
          success: response.success
        };

      } catch (error) {
        console.error('Error executing selection node:', error);
        return {
          result: {},
          error: error instanceof Error ? error.message : 'Unknown error occurred',
          success: false
        };
      }
    }
  };
}

export function createStartNodeExecutor(): NodeExecutor {
  return {
    execute: async (node: Node<CustomNodeData>, context: NodeExecutionContext): Promise<NodeExecutionResult> => {
      console.log('Executing start node:', node.id);
      return {
        result: {},
        error: null,
        success: true
      };
    }
  };
} 