import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { supabase } from '../../utils/supabase';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90vw;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #666;
`;

const PluginList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PluginItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  
  &:hover {
    background: #eeeeee;
  }
`;

const LoadingSpinner = styled.span`
  color: #666;
  font-size: 14px;
`;

interface PluginDownloadProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PluginDownload: React.FC<PluginDownloadProps> = ({ isOpen, onClose }) => {
  const [plugins, setPlugins] = useState<string[]>([]);
  const [downloadingPlugin, setDownloadingPlugin] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      fetchPlugins();
    }
  }, [isOpen]);

  const fetchPlugins = async () => {
    const { data, error } = await supabase.storage
      .from('plugins')
      .list('private');

    if (error) {
      console.error('Error fetching plugins:', error);
      return;
    }

    setPlugins(data.map(file => file.name));
  };

  const handleDownload = async (fileName: string) => {
    if (downloadingPlugin === fileName) {
      return;
    }
    setDownloadingPlugin(fileName);
    try {
      const { data, error } = await supabase.storage
        .from('plugins')
        .download("private/" + fileName);

      if (error) {
        console.error('Error downloading plugin:', error);
        return;
      }

      // Create download link
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } finally {
      setDownloadingPlugin(null);
    }
  };

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Header>
          <h2>Download Revit Plugin</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <PluginList>
          {plugins.map((plugin) => (
            <PluginItem key={plugin} onClick={() => handleDownload(plugin)}>
              {plugin.replace(/\.zip$/, '')}
              {downloadingPlugin === plugin && (
                <LoadingSpinner>Downloading...</LoadingSpinner>
              )}
            </PluginItem>
          ))}
        </PluginList>
      </Modal>
    </Overlay>
  );
};