import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CodeNodeData, CustomNodeData } from '../types/nodes';
import { NodeTitle, NodeHeader, NodeTitleInput, NodeHeaderLeft, NodeHeaderRight, NodeHeaderButton, NodeFieldLabel, NodeFieldDropdown, NodeFields, NodeBody } from '../Node.styles';
import { Connection, NodeProps } from 'reactflow';
import { DynamicSvg } from '../../../ui-components/DynamicSvg';
import Editor from '@monaco-editor/react';
import styled from 'styled-components';
import { useSocketStore } from '../../../stores/socketStore';
import { useEditorStore } from '../../../stores/editorStore';
import { useWorkflowStore } from '../../../stores/workflowStore';
import { BaseNode } from './BaseNode';
import InProgressEffect from '../../../ui-components/InProgressEffect';
import { debounce } from 'lodash';

// Update the EditorOverlayButton to handle vertical positioning
const EditorOverlayButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
`;

const EditorOverlayButton = styled.button`
  padding: 5px;
  background: #3C94D6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #2d7ab8;
  }
`;

const ExpandButton = styled(NodeHeaderButton)`
  color: #3C94D6;
`;

const EditorContainer = styled.div`
  position: relative;
  background: #f0f0f0;
  border: 2px solid #e0e0e0;
  min-width: 300px;
  border-radius: 4px;

  .monaco-editor .margin {
    background-color: #f5f5f5;
  }
`;

interface CodeNodeProps extends NodeProps<CustomNodeData> {
  onExpandCode?: (code: string, language: string, nodeId: string) => void;
}

export function CodeNode({ data, id, selected, xPos, yPos, zIndex, onExpandCode }: CodeNodeProps) {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState(data.label || 'Code');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { socket } = useSocketStore();
  const { setInputBoxValue, addChatMessage } = useEditorStore();
  const { updateNodeCode, nodes, activeId: activeWorkflow, whichWorkflowIsRunning, nodeExecuting, updateNodeData } = useWorkflowStore();
  const [codeBlockType, setCodeBlockType] = useState('Python-Read-Write');
  const editorRef = useRef<any>(null);

  // Get code from workflow store and maintain local state
  const node = nodes.find(n => n.id === id);
  const [editorValue, setEditorValue] = useState(
    node?.data.type === 'code' ? (node.data as CodeNodeData).code || '' : ''
  );

  // Create a debounced version of updateNodeData
  const debouncedUpdateNodeData = useCallback(
    debounce((key: string, value: any) => {
      updateNodeData(id, key, value);
    }, 500),
    [id, updateNodeData]
  );

  // Sync local state with node data when it changes
  useEffect(() => {
    const currentNode = nodes.find(n => n.id === id);
    if (currentNode?.data) {
      const nodeData = currentNode.data as CodeNodeData;
      setEditorValue(nodeData.code || '');
      setCodeBlockType(nodeData.codeBlockType || 'Python-Read-Write');
    }
  }, [nodes, id]);

  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  };

  const handleCodeChange = (value: string | undefined) => {
    const newValue = value || '';
    setEditorValue(newValue);
    updateNodeCode(id, newValue);
    debouncedUpdateNodeData('code', newValue);
  };

  const runCodeViaSocket = () => {
    console.log('Running code');
    socket?.emit('connectPythonFromFrontend', {
      message: editorValue,
    });
  }

  const handleExpand = () => {
    console.log("Expand button clicked", { code: editorValue, onExpandCode });
    if (onExpandCode) {
      onExpandCode(editorValue, 'python', id);
    }
  };

  const onConnect = useCallback((connection: Connection) => {
    // This will be called when a valid connection is made
    console.log('Connection made:', connection);
    // TODO: Add node creation logic here
  }, []);

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTitleEditing(true);
    // Focus the input on the next tick after it's rendered
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputBlur = () => {
    setIsTitleEditing(false);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsTitleEditing(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value || 'Start');
  };

  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeWorkflow}
      executingNodeId={nodeExecuting}
    >
      <BaseNode 
        id={id} 
        data={data} 
        selected={selected} 
        xPos={xPos} 
        yPos={yPos} 
        zIndex={zIndex}
        type="code"
        isConnectable={true}
        dragging={false}
      >
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="Code" customIconColor="#D6C73C" width={20} height={20}  />
            {isTitleEditing ? (
              <NodeTitleInput
                ref={inputRef}
                value={title}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyDown}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <NodeTitle onDoubleClick={handleDoubleClick}>
                {title}
              </NodeTitle>
            )}
          </NodeHeaderLeft>
          <NodeHeaderRight>
            {isCollapsed ? (
              <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <DynamicSvg name="Plus" customIconColor="#7F7F7F" width={16} height={16} />
              </NodeHeaderButton>
            ) : (
              <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <DynamicSvg name="Minus" customIconColor="#7F7F7F" width={16} height={16} />
              </NodeHeaderButton>
            )}
            {/** 
            <NodeHeaderButton onClick={runCodeViaSocket}>
              <DynamicSvg name="Run" customIconColor="#3C94D6" width={16} height={16}  />
            </NodeHeaderButton>
            <NodeHeaderButton>
              <DynamicSvg name="Ellipsis" customIconColor="#7F7F7F" width={16} height={16}  />
            </NodeHeaderButton>
            */}
          </NodeHeaderRight>
        </NodeHeader>

        <NodeBody>
          <NodeFields>
            <div>
              <NodeFieldLabel>Type:</NodeFieldLabel>
              <NodeFieldDropdown 
                value={codeBlockType} 
                onChange={(e) => {
                  console.log('Type changed:', e.target.value);
                  setCodeBlockType(e.target.value);
                }}
              >
                <option value="Python-Read-Write">Python Read-Write to Revit</option>
                <option value="Python-Read-Only">Python Read-Only from Revit</option>
              </NodeFieldDropdown>
            </div>
          </NodeFields>

          {!isCollapsed && (
            <EditorContainer>
              <Editor
                height="200px"
                defaultLanguage="python"
                value={editorValue}
                onChange={handleCodeChange}
                onMount={handleEditorDidMount}
                options={{
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                  fontSize: 12,
                  theme: 'vs-light',
                  lineNumbersMinChars: 2,
                  padding: { top: 8, bottom: 8 },
                  lineDecorationsWidth: 5,
                  glyphMargin: false,
                  folding: false,
                  renderLineHighlight: 'none'
                }}
              />
              <EditorOverlayButtonContainer>
                <EditorOverlayButton
                  onClick={handleExpand}
                  title="Open in full editor"
                >
                  <DynamicSvg name="Expand" customIconColor="#FFFFFF" width={25} height={25} />
                </EditorOverlayButton>
                {/** 
                <EditorOverlayButton
                  onClick={() => {
                    addChatMessage({
                      role: "assistant",
                      content: [{ type: "text", text: "How can I help you with this code?" }],
                    });
                    setInputBoxValue("/code ");
                  }}
                >
                  <DynamicSvg name="AISparkles" customIconColor="#FFFFFF" width={25} height={25} /> 
                </EditorOverlayButton>
                */}
              </EditorOverlayButtonContainer>
            </EditorContainer>
          )}
        </NodeBody>
      </BaseNode>
    </InProgressEffect>
  );
}