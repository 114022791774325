import { NODE_TYPES } from '../constants/nodeTypes';

// Base interface for all node types
export interface BaseNodeData {
  label: string;
  iconName?: string;
  iconColor?: string;
  tooltip?: string;
}

export interface StartNodeData extends BaseNodeData {
  type: 'start';
  code?: string;
}

// Specific interface for Action nodes
export interface ActionNodeData extends BaseNodeData {
  type: 'action';  // Literal type to ensure type safety
  settings?: {
    inputs?: Record<string, any>;
    outputs?: Record<string, any>;
  };
}

export interface CodeNodeData extends BaseNodeData {
  type: 'code';
  code: string;
  codeBlockType?: string;
  ai_generated_outputs?: string[];
  ai_code_hash?: string;
}

interface SourceHandleDefinition {
  id: string;
  name: string;
  condition: string;
}

export interface BranchNodeData extends BaseNodeData {
  type: 'branch';
  mode: 'simple' | 'advanced';
  code?: string;  // Made optional since it's not directly used in component
  codeBlockType?: string;
  sourceHandles?: SourceHandleDefinition[];  // Added to support edge definitions
}

export interface AIPromptNodeData extends BaseNodeData {
  type: 'ai_prompt';
  inputs: string[];
  outputs: string[];
  prompt: string;
}

export interface SelectInRevitNodeData extends BaseNodeData {
  type: 'select_in_revit';
  selectionType: string;
  automated_selection_fallback: string;
}

export interface UserInput {
  id: string;
  name: string;
  input_type: 'text' | 'file' | 'dropdown_fixed' | 'dropdown_dynamic' | 'multiselect_fixed' | 'multiselect_dynamic';
  default_value?: string;  // For dropdowns/multiselect, comma-separated values
  default_file?: {
    fileName: string;
    filePath: string;
    fileType: string;
    signedUrl?: string;
  };
  runtime_value?: string;  // For dropdowns/multiselect, comma-separated values
  runtime_file?: {
    fileName: string;
    filePath: string;
    fileType: string;
    signedUrl?: string;
  };
  can_edit_during_runtime: boolean;
  options?: string[];  // For fixed dropdowns/multiselect
  options_variable?: string;  // For dynamic dropdowns/multiselect - references a variable in workflow state
}

export interface UserInputNodeData extends BaseNodeData {
  type: 'user_input';
  inputs: UserInput[];
  finish_inputs_button_time: number;
}

export const nodeToSVGName = {
  "start": "Entry",
  "action": "Code",
  "code": "Code",
  "ai_prompt": "AISparkles",
  "select_in_revit": "Select",
  "user_input": "UserInput",
  "branch": "Branch"
}
////
// Union type of all possible node data types
export type CustomNodeData = 
  StartNodeData
  | ActionNodeData
  | CodeNodeData
  | SelectInRevitNodeData
  | AIPromptNodeData
  | UserInputNodeData
  | BranchNodeData;

export function createDefaultNodeData(type: keyof typeof NODE_TYPES, label: string): CustomNodeData {
  const baseData = {
    type: type,
    label,
    tooltip: `${label} node`
  };

  switch (type) {
    case NODE_TYPES.code:
      return {
        ...baseData,
        type: NODE_TYPES.code,
        codeBlockType: 'Python-Read-Write',
        code: ''
      };
    case NODE_TYPES.ai_prompt:
      return {
        ...baseData,
        type: NODE_TYPES.ai_prompt,
        prompt: '',
        inputs: [],
        outputs: []
      };
    case NODE_TYPES.branch:
      return {
        ...baseData,
        type: NODE_TYPES.branch,
        mode: 'simple',
        sourceHandles: []
      };
    case NODE_TYPES.start:
      return {
        ...baseData,
        type: NODE_TYPES.start
      };
    case NODE_TYPES.select_in_revit:
      return {
        ...baseData,
        type: NODE_TYPES.select_in_revit,
        selectionType: 'Elements',
        automated_selection_fallback: "false"
      };
    case NODE_TYPES.user_input:
      return {
        ...baseData,
        type: NODE_TYPES.user_input,
        inputs: [],
        finish_inputs_button_time: Date.now()
      };
    default:
      throw new Error(`Unknown node type: ${type}`);
  }
}