import React, { useEffect, useState } from 'react';
import { PrimeReactProvider } from 'primereact/api';
import { AppContainer } from './App.styles';
import ChatPanel from './components/ChatPanel/ChatPanel';
import LibraryPanel from './components/LibraryPanel/LibraryPanel';
import MainContainer from './components/MainContainer/MainContainer';
import { Auth } from './components/Auth/Auth';
import { useSocketStore } from './stores/socketStore';
import { signInWithEmail, signUp, signOut, getCustomerDocuments, getWorkflowsWithWorksheets, getSingleWorkflowWithWorksheets } from './utils/supabase';
import { supabase, claimInvite } from './utils/supabase';
import { SavedWorkflow } from './types/workflow';
import { RunHistoryItem } from './components/RunHistory/RunHistory';
import { io } from './stores/socketStore';

function App() {
  const { setSocket } = useSocketStore();
  const [runHistory, setRunHistory] = useState<RunHistoryItem[]>([]);
  const [savedWorkflows, setSavedWorkflows] = useState<SavedWorkflow[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check auth state
  useEffect(() => {
    //signOut();
    const initializeAuth = async () => {
      // Check if current path is /signout
      if (window.location.pathname === '/signout') {
        await signOut();
        // Redirect to home page after signing out
        window.location.href = '/';
        return;
      }

      // Get initial auth state
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
    };
    initializeAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setIsAuthenticated(!!session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const newSocket = io();
    setSocket(newSocket);
    
    // Connect immediately
    newSocket.connect();
    console.log('Connected to Supabase Realtime');
    
    // Send initial connection message
    newSocket.emit('connectFrontend', {
      projectId: '123',
    });

    return () => {
      newSocket.disconnect();
      setSocket(null);
    };
  }, [setSocket]);

  useEffect(() => {
    const fetchCustomerDocuments = async () => {
      const { data, error } = await getCustomerDocuments();
      console.log(data, error);
    };
    fetchCustomerDocuments();
  }, []);

  // Add mock data for testing (modified to include savedWorkflows)
  useEffect(() => {
    setRunHistory([
      {
        id: 1,
        name: 'Test Workflow Run',
        status: 'completed',
        timestamp: new Date().toISOString(),
        nodeCount: 5,
        duration: 2.3
      },
      {
        id: 2,
        name: 'Production Workflow',
        status: 'failed',
        timestamp: new Date(Date.now() - 86400000).toISOString(),
        nodeCount: 8,
        duration: 4.1
      },
      {
        id: 3,
        name: 'New Workflow',
        status: 'running',
        timestamp: new Date().toISOString(),
        nodeCount: 3
      }
    ]);

    setSavedWorkflows([
      {
        id: 1,
        name: 'ADA Compliance Check',
        lastModified: new Date().toISOString(),
        nodeCount: 6,
        description: 'Automated ADA compliance check'
      },
      {
        id: 2,
        name: 'Associate Walls with Doors',
        lastModified: new Date(Date.now() - 172800000).toISOString(), // 2 days ago
        nodeCount: 4,
        description: 'Associate walls with doors'
      },
      {
        id: 3,
        name: 'Make all windows 1 inch smaller',
        lastModified: new Date(Date.now() - 86400000).toISOString(), // 1 day ago
        nodeCount: 8,
        description: 'Make all windows 1 inch smaller'
      }
    ]);
  }, []);

  // Add this effect to handle invite codes
  useEffect(() => {
    const handleInviteCode = async () => {
      // Only proceed if user is authenticated
      if (!isAuthenticated) return;

      // Get invite code from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const inviteCode = urlParams.get('invite');

      if (inviteCode) {
        try {
          await claimInvite(inviteCode);
          // Optionally remove the invite code from URL after claiming
          window.history.replaceState({}, '', window.location.pathname);
          //reload page
          window.location.reload();
        } catch (error) {
          console.error('Error claiming invite:', error);
        }
      }
    };

    handleInviteCode();
  }, [isAuthenticated]); // Run when authentication state changes

  return (
    <AppContainer>
      <PrimeReactProvider>
        {isAuthenticated ? (
          <>
            <LibraryPanel />
            <MainContainer />
            <ChatPanel />
          </>
        ) : (
          <Auth />
        )}
      </PrimeReactProvider>
    </AppContainer>
  );
}

export default App;
