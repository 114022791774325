import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { DynamicSvg } from "../../ui-components/DynamicSvg";
import { useEditorStore } from "../../stores/editorStore";
import { useSocketStore } from "../../stores/socketStore";
import { supabase } from "../../utils/supabase";
import {
    LoadingIcon,
    IconButton,
    DocumentItem,
    DocumentInfo,
    DocumentName,
    DocumentType,
    PendingAttachmentItem,
} from './ChatComponents.style';

const _ChatInputBox = styled.div`
    padding: 10px;
    background: rgba(246, 246, 246, 0.44);
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
`;

const TextArea = styled.textarea`
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.5;
    padding: 8px;
    resize: none;
    min-height: 24px;
    max-height: 150px;
    background: transparent;
    &::placeholder {
        color: #999;
    }
`;

interface ChatInputBoxProps {
    onSubmit: (text: string, attachments: PendingAttachment[]) => void;
    placeholder: string;
    disabled?: boolean;
}

export interface PendingAttachment {
    url: string;
    fileName: string;
    fileType: string;
    filePath: string;
    expiresAt: number;
}

const ChatInputBox: React.FC<ChatInputBoxProps> = ({ onSubmit, placeholder, disabled }) => {
    const { inputBoxValue, setInputBoxValue } = useEditorStore();
    const [text, setText] = React.useState(inputBoxValue);
    const { socket } = useSocketStore();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [pendingAttachments, setPendingAttachments] = useState<PendingAttachment[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (inputBoxValue !== text && inputBoxValue !== "") {
            setText(inputBoxValue);
        }
    }, [inputBoxValue]);

    const handleFileUpload = async (files: FileList) => {
        setIsUploading(true);
        try {
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                setIsUploading(false);
                return;
            }

            for (const file of Array.from(files)) {
                const fileExt = file.name.split('.').pop();
                const timestamp = Date.now();
                const randomId = crypto.randomUUID();
                const fileName = `${timestamp}-${randomId}.${fileExt}`;
                const filePath = `${user.id}/${fileName}`;

                const { data, error } = await supabase.storage
                    .from('chat_docs')
                    .upload(filePath, file);

                if (error) {
                    console.error('Error uploading file:', error);
                    continue;
                }

                const { data: urlData, error: signedUrlError } = await supabase.storage
                    .from('chat_docs')
                    .createSignedUrl(filePath, 60 * 60);

                if (signedUrlError || !urlData) {
                    console.error('Error getting signed URL:', signedUrlError);
                    continue;
                }

                const expiresAt = Date.now() + 60 * 60 * 1000;

                setPendingAttachments(prev => [...prev, {
                    url: urlData.signedUrl,
                    fileName: file.name,
                    fileType: file.type,
                    filePath: filePath,
                    expiresAt: expiresAt
                }]);
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSubmit = async () => {
        if (text.trim() || pendingAttachments.length > 0) {
            onSubmit(text, pendingAttachments);
            setText("");
            setPendingAttachments([]);
        }
        setInputBoxValue("");
    };

    const captureActiveView = () => {
        socket?.emit('viewCaptureRequest', {
            captureCurrentView: true,
        });
    }

    useEffect(() => {
        socket?.on('viewCaptureResponse', (data) => {
            console.log('View capture response:', data);
            if (data.imageData) {
                const dataUrl = `data:image/png;base64,${data.imageData}`;
                setImageUrl(dataUrl);
            }
        });
    }, [socket]);

    const handleRemoveAttachment = (index: number) => {
        setPendingAttachments(prev => prev.filter((_, i) => i !== index));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <_ChatInputBox>
            <TextArea 
                placeholder={placeholder}
                rows={1}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
                onInput={(e) => {
                    // Auto-resize the textarea
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'auto';
                    target.style.height = `${target.scrollHeight}px`;
                }}
                disabled={disabled}
                className={`chat-input ${disabled ? 'disabled' : ''}`}
            />
            <input 
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => e.target.files && handleFileUpload(e.target.files)}
                multiple
            />
            {pendingAttachments.length > 0 && (
                <div style={{ position: 'absolute', bottom: '100%', left: 0, right: 0, padding: '8px' }}>
                    {pendingAttachments.map((file, index) => (
                        <PendingAttachmentItem key={index}>
                            <span>{file.fileName}</span>
                            <IconButton onClick={() => handleRemoveAttachment(index)} style={{ color: 'currentColor' }}>
                                <DynamicSvg name="Minus" />
                            </IconButton>
                        </PendingAttachmentItem>
                    ))}
                </div>
            )}
            <IconButton 
                onClick={() => fileInputRef.current?.click()} 
                disabled={isUploading}
                style={{ opacity: isUploading ? 0.7 : 1 }}
            >
                {isUploading ? (
                    <LoadingIcon />
                ) : (
                    <DynamicSvg name="Paperclip" />
                )}
            </IconButton>
        </_ChatInputBox>
    );
};

export default ChatInputBox;
