import React, { useCallback, useRef, useState, useEffect } from 'react';
import { AIPromptNodeData, CustomNodeData } from '../types/nodes';
import { NodeTitle, NodeHeader, NodeTitleInput, NodeWrapper, StyledHandle, NodeHeaderLeft, NodeHeaderRight, NodeHeaderButton, NodeFieldLabel, NodeFieldDropdown, NodeFields, NodeFooter, NodeFooterTab, NodeBody } from '../Node.styles';
import { Connection, Position } from 'reactflow';
import { DynamicSvg } from '../../../ui-components/DynamicSvg';
import styled from 'styled-components';
import { useWorkflowStore } from '../../../stores/workflowStore';
import InProgressEffect from '../../../ui-components/InProgressEffect';
import { BaseNode } from './BaseNode';
import { debounce } from 'lodash';

const PromptChip = styled.div`
  display: inline-flex;
  align-items: center;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
  gap: 4px;
  
  svg {
    color: #3C94D6;
  }
`;

const PromptInput = styled.textarea`
  min-height: 120px;
  min-width: 280px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  resize: none;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 8px;

  &:focus {
    outline: none;
    border-color: #3C94D6;
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
`;

const Tab = styled.div<{ active?: boolean }>`
  color: ${props => props.active ? '#333' : '#7F7F7F'};
  font-size: 14px;
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: 2px solid ${props => props.active ? '#3C94D6' : 'transparent'};
`;

const InputsOutputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`;

const Chip = styled.div<{ isSelected?: boolean }>`
  display: inline-flex;
  align-items: center;
  background: ${props => props.isSelected ? '#E3F2FD' : '#f0f0f0'};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
  gap: 4px;
  cursor: pointer;
  border: 1px solid ${props => props.isSelected ? '#3C94D6' : 'transparent'};
  height: 24px;
  
  &:hover {
    background: #E3F2FD;
  }

  .delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    padding: 2px;
    border-radius: 50%;
    
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

const DeleteButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-top: 1px;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 12px;
  line-height: 0;
  padding: 0;
  user-select: none;
  position: relative;
  
  &::before {
    content: '×';
    position: absolute;
    top: 7px;
  }
  
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const WarningMessage = styled.div`
  color: #f57c00;
  font-size: 12px;
  padding: 8px 12px;
  background: #fff3e0;
  border-radius: 4px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AIPromptNode = ({ data, id, selected = false, xPos, yPos, zIndex = 0 }: {
  data: CustomNodeData;
  id: string;
  selected?: boolean;
  xPos: number;
  yPos: number;
  zIndex?: number;
}) => {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState(data.label || 'AI Prompt');
  const { updateNodeData, nodes } = useWorkflowStore();
  
  // Get initial values from the workflow store
  const node = nodes.find(n => n.id === id);
  const [prompt, setPrompt] = useState((node?.data as AIPromptNodeData)?.prompt || '');
  const [inputs, setInputs] = useState<string[]>((node?.data as AIPromptNodeData)?.inputs || []);
  const [newInput, setNewInput] = useState('');
  const [newOutput, setNewOutput] = useState('');
  const [outputs, setOutputs] = useState<string[]>((node?.data as AIPromptNodeData)?.outputs || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { activeId: activeWorkflow, whichWorkflowIsRunning, nodeExecuting, available_variables } = useWorkflowStore();

  // Create a debounced version of updateNodeData
  const debouncedUpdateNodeData = useCallback(
    debounce((key: string, value: any) => {
      updateNodeData(id, key, value);
    }, 500),
    [id, updateNodeData]
  );

  // Sync local state with node data when it changes
  useEffect(() => {
    const currentNode = nodes.find(n => n.id === id);
    if (currentNode?.data) {
      const nodeData = currentNode.data as AIPromptNodeData;
      setPrompt(nodeData.prompt || '');
      setInputs(nodeData.inputs || []);
      setOutputs(nodeData.outputs || []);
    }
  }, [nodes, id]);

  const onConnect = useCallback((connection: Connection) => {
    // This will be called when a valid connection is made
    console.log('Connection made:', connection);
    // TODO: Add node creation logic here
  }, []);

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTitleEditing(true);
    // Focus the input on the next tick after it's rendered
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputBlur = () => {
    setIsTitleEditing(false);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setIsTitleEditing(false);
    }
    if (event.key === 'Escape') {
      setIsTitleEditing(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value || 'Start');
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setPrompt(newValue);
    debouncedUpdateNodeData('prompt', newValue);
  };

  const handleAddInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newInput.trim()) {
      const newInputs = [...inputs, newInput.trim()];
      setInputs(newInputs);
      setNewInput('');
      debouncedUpdateNodeData('inputs', newInputs);
    }
  };

  const removeInput = (input: string) => {
    const newInputs = inputs.filter(i => i !== input);
    setInputs(newInputs);
    debouncedUpdateNodeData('inputs', newInputs);
  };

  const handleAddOutput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newOutput.trim()) {
      const newOutputs = [...outputs, newOutput.trim()];
      setOutputs(newOutputs);
      setNewOutput('');
      debouncedUpdateNodeData('outputs', newOutputs);
    }
  };

  const removeOutput = (output: string) => {
    const newOutputs = outputs.filter(o => o !== output);
    setOutputs(newOutputs);
    debouncedUpdateNodeData('outputs', newOutputs);
  };

  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeWorkflow}
      executingNodeId={nodeExecuting}
    >
      <BaseNode 
        id={id} 
        data={data} 
        selected={selected} 
        xPos={xPos} 
        yPos={yPos} 
        zIndex={zIndex}
        type="ai-prompt"
        isConnectable={true}
        dragging={false}
      >
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="AISparkles" customIconColor="#3C94D6" width={20} height={20} />
            {isTitleEditing ? (
              <NodeTitleInput
                  ref={inputRef}
                  value={title}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  onKeyDown={handleInputKeyDown}
                  onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <NodeTitle onDoubleClick={handleDoubleClick}>{title}</NodeTitle>
            )}
          </NodeHeaderLeft>
          <NodeHeaderRight>
            <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <DynamicSvg name={isCollapsed ? "Plus" : "Minus"} customIconColor="#7F7F7F" width={16} height={16} />
            </NodeHeaderButton>
          </NodeHeaderRight>
        </NodeHeader>

        {selected && inputs.length > 0 && inputs.some(input => !available_variables?.includes(input)) && (
          <WarningMessage>
            <DynamicSvg name="Warning" customIconColor="#f57c00" width={16} height={16} />
            Undefined variables in inputs: {inputs.filter(input => !available_variables?.includes(input)).join(', ')}
          </WarningMessage>
        )}

        <NodeBody>
          {!isCollapsed && (
            <>
              <NodeFields>
                <NodeFieldLabel>Prompt:</NodeFieldLabel>
                <PromptInput
                  value={prompt}
                  onChange={handlePromptChange}
                  placeholder="Enter your multi-paragraph prompt here..."
                />
              </NodeFields>

              <InputsOutputsContainer>
                <div>
                  <NodeFieldLabel>Define Inputs Field Name(s) (can be of any type i.e. text, image, csv, pdf, etc):</NodeFieldLabel>
                  <input
                    type="text"
                    value={newInput}
                    onChange={(e) => setNewInput(e.target.value)}
                    onKeyDown={handleAddInput}
                    placeholder="Type input name and press Enter"
                    style={{ marginBottom: '8px', padding: '4px 8px', width: '60%' }}
                  />
                  <ChipsContainer>
                    {inputs.map(input => (
                      <Chip key={input}>
                        {input}
                        <DeleteButton onClick={(e) => {
                          e.stopPropagation();
                          removeInput(input);
                        }} />
                      </Chip>
                    ))}
                  </ChipsContainer>
                </div>

                <div>
                  <NodeFieldLabel>Define Output Field Name(s):</NodeFieldLabel>
                  <div style={{ fontSize: '12px', color: '#666', marginBottom: '4px' }}>
                    Note: Names ending in .csv, .txt, or .json will create downloadable files
                  </div>
                  <input
                    type="text"
                    value={newOutput}
                    onChange={(e) => setNewOutput(e.target.value)}
                    onKeyDown={handleAddOutput}
                    placeholder="Type output name and press Enter"
                    style={{ marginBottom: '8px', padding: '4px 8px', width: '60%' }}
                  />
                  <ChipsContainer>
                    {outputs.map(output => (
                      <Chip key={output}>
                        {output}
                        <DeleteButton onClick={(e) => {
                          e.stopPropagation();
                          removeOutput(output);
                        }} />
                      </Chip>
                    ))}
                  </ChipsContainer>
                </div>
              </InputsOutputsContainer>
            </>
          )}
        </NodeBody>
      </BaseNode>
    </InProgressEffect>
  );
};
