import { Client } from "@langchain/langgraph-sdk";
import type { SupabaseClient } from '@supabase/supabase-js';

const USE_LANGGRAPH_PROD = process.env.REACT_APP_USE_LANGGRAPH_PROD === 'true';

class LangGraphClientService {
  private static instance: LangGraphClientService;
  private client: Client | null = null;
  private supabaseClient: SupabaseClient;

  private constructor(supabaseClient: SupabaseClient) {
    this.supabaseClient = supabaseClient;

    // Setup auth listener
    this.supabaseClient.auth.onAuthStateChange((_event, session) => {
      const token = session?.access_token;
      this.setupClient(token);
    });

    // Initial setup
    void this.initClient();
  }

  private setupClient(token: string | undefined) {
    this.client = new Client({ 
      apiUrl: USE_LANGGRAPH_PROD ? "https://revit-assistant-1-f873cce152145153914e4a6c489c9dde.us.langgraph.app": "http://localhost:2024",
      defaultHeaders: token ? {
        "Authorization": `Bearer ${token}`
      } : {}
    });
  }

  private async initClient() {
    const { data: { session } } = await this.supabaseClient.auth.getSession();
    const token = session?.access_token;
    this.setupClient(token);
  }

  public static async getInstance(supabaseClient: SupabaseClient): Promise<LangGraphClientService> {
    if (!LangGraphClientService.instance) {
      LangGraphClientService.instance = new LangGraphClientService(supabaseClient);
      await LangGraphClientService.instance.initClient();
    }
    return LangGraphClientService.instance;
  }

  public getClient(): Client {
    if (!this.client) {
      throw new Error('LangGraph client not initialized');
    }
    return this.client;
  }
}

// Direct access for non-React contexts - now returns the service instead of the client
export const getLangGraphSupabaseClient = async (supabaseClient: SupabaseClient) => {
  return await LangGraphClientService.getInstance(supabaseClient);
};

// Hook for React components
export const useLangGraphSupabaseClient = async (supabaseClient: SupabaseClient) => {
  const service = await getLangGraphSupabaseClient(supabaseClient);
  return service.getClient();
};