import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GaugeChart } from './GaugeChart';
import { KPICard } from './KPICard';
import { getLangGraphSupabaseClient } from '../../utils/langgraphSupabase';
import { supabase } from '../../utils/supabase';

interface ChartViewProps {
  visible: boolean;
  onHide: () => void;
  finalState: Record<string, any>;
}

interface ChartData {
  type: 'gauge' | 'kpi';
  title: string;
  value?: number;
  min?: number;
  max?: number;
  unit?: string;
}

interface ChartGroup {
  name: string;
  charts: ChartData[];
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #666;
`;

const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const GroupSection = styled.div`
  margin-bottom: 32px;
`;

const GroupTitle = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
`;

let serviceInstance: any = null;
const getClient = async () => {
  if (!serviceInstance) {
    serviceInstance = await getLangGraphSupabaseClient(supabase);
  }
  return serviceInstance.getClient();
};

export const ChartView: React.FC<ChartViewProps> = ({ visible, onHide, finalState }) => {
  const [chartGroups, setChartGroups] = useState<ChartGroup[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const processDataForCharts = async () => {
      if (!visible) return;
      
      setIsLoading(true);
      try {
        const client = await getClient();
        const thread = await client.threads.create();

        const stream = client.runs.stream(
          thread.thread_id,
          "ai_node",
          {
            input: {
              inputs: finalState,
              prompt: `
                Analyze this data and create chart configurations. Group related metrics together.
                For each metric, determine the best chart type (gauge or kpi) and provide necessary parameters.
                Return groups of charts with appropriate names and configurations.


                Do not make up data, but you can make reasonable inferences.
                If there is enough data for a KPI chart but not a gauge chart, make a KPI chart.

                Not every group needs multiple charts; it is ok to return a single chart in a group or just one group when that makes sense.

                Always do your best to return at least one chart.
                
                Required format for each chart type:
                - gauge: value, min, max, unit (optional), title
                - kpi: value, unit (optional), title

                Sample Return Format:
                {
                  "chart_groups": [
                    {
                      "name": "Group 1",
                      "charts": [
                        { "type": "gauge", "title": "Temperature", "min": 0, "max": 100, "value": 40, "unit": "°C" },
                        { "type": "kpi", "title": "Average Temperature", "value": 25, "unit": "°C" }
                      ]
                    }
                  ]
                }
              `,
              expected_outputs: ["output"]
            },
            streamMode: ["values"],
          }
        );

        for await (const chunk of stream) {
          console.log("CHUNK", chunk);
          if (chunk.event === "values" && chunk.data?.outputs?.output?.chart_groups) {
            setChartGroups(chunk.data.outputs.output.chart_groups);
          }
        }
      } catch (error) {
        console.error('Error processing chart data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    processDataForCharts();
  }, [visible, finalState]);

  const renderChart = (chart: ChartData) => {
    switch (chart.type) {
      case 'gauge':
        return (
          <GaugeChart
            value={chart.value || 0}
            min={chart.min || 0}
            max={chart.max || 100}
            unit={chart.unit || ''}
            title={chart.title}
          />
        );
      case 'kpi':
        return (
          <KPICard
            value={chart.value || 0}
            unit={chart.unit || ''}
            title={chart.title}
          />
        );
      default:
        return null;
    }
  };

  if (!visible) return null;

  return (
    <Overlay onClick={onHide}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Header>
          <h2>Analytics Dashboard</h2>
          <CloseButton onClick={onHide}>×</CloseButton>
        </Header>
        {isLoading ? (
          <div>Processing data...</div>
        ) : (
          chartGroups.map((group, groupIndex) => (
            <GroupSection key={groupIndex}>
              <GroupTitle>{group.name}</GroupTitle>
              <ChartContainer>
                {group.charts.map((chart, chartIndex) => (
                  <div key={chartIndex}>
                    {renderChart(chart)}
                  </div>
                ))}
              </ChartContainer>
            </GroupSection>
          ))
        )}
      </Modal>
    </Overlay>
  );
};
