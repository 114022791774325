import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const borderAnimation = keyframes`
  0% {
    clip-path: polygon(0 0, 2px 0, 2px 2px, 0 2px);
  }
  25% {
    clip-path: polygon(0 0, 100% 0, 100% 2px, 0 2px);
  }
  50% {
    clip-path: polygon(calc(100% - 2px) 0, 100% 0, 100% 100%, calc(100% - 2px) 100%);
  }
  75% {
    clip-path: polygon(0 calc(100% - 2px), 100% calc(100% - 2px), 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 2px 0, 2px 100%, 0 100%);
  }
`;

const EffectWrapper = styled.div<{ isExecuting: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  ${props => props.isExecuting && css`
    &::before {
      content: '';
      position: absolute;
      inset: -2px;
      background: #4CAF50;
      border-radius: 12px;
      z-index: -1;
      animation: ${borderAnimation} 1.5s linear infinite;
      box-shadow: 
        0 0 5px #4CAF50,
        0 0 10px #4CAF50,
        0 0 15px #4CAF50;
    }
  `}
`;

interface InProgressEffectProps {
  workflowId: string | null;
  nodeId: string;
  activeWorkflow: string | null;
  executingNodeId: string | null;
  children: React.ReactNode;
}

const InProgressEffect: React.FC<InProgressEffectProps> = ({
  workflowId,
  nodeId,
  activeWorkflow,
  executingNodeId,
  children
}) => {
  const isExecuting = workflowId === activeWorkflow && nodeId === executingNodeId;

  return (
    <EffectWrapper isExecuting={isExecuting}>
      {children}
    </EffectWrapper>
  );
};

export default InProgressEffect;
