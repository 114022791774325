import React from "react";
import { _UserChatMessageBox } from "./ChatComponents.style";
import { ChatMessage } from "../../types/message";
import { DocumentItem, DocumentInfo, DocumentName, DocumentType } from "./ChatComponents.style";
import { DynamicSvg } from "../../ui-components/DynamicSvg";
import { useEditorStore } from "../../stores/editorStore";

export const UserChatMessageBox = ({ message }: { message: ChatMessage }) => {
    const updateSignedUrl = useEditorStore(state => state.updateSignedUrl);
    const threadId = useEditorStore(state => state.getChatThreadId());

    const handleClick = async (content: any, e: React.MouseEvent) => {
        if ('filePath' in content && 'expiresAt' in content) {
            e.preventDefault();
            const newUrl = await updateSignedUrl(threadId, content.filePath, content.expiresAt, content.url);
            window.open(newUrl, '_blank');
        }
    };

    return (
        <_UserChatMessageBox>
            {message.content.map((content, idx) => {
                if (content.type === "text") {
                    return <React.Fragment key={`text-${idx}`}>{content.text}</React.Fragment>
                }
                if (content.type === "document" || content.type === "image") {
                    return (
                        <DocumentItem 
                            key={`${content.type}-${content.fileName}-${idx}`}
                            href={content.url} 
                            target="_blank"
                            url={content.url} 
                            fileName={content.fileName} 
                            fileType={content.fileType}
                            onClick={(e) => handleClick(content, e)}
                        >
                            <DynamicSvg name="Document" />
                            <DocumentInfo>
                                <DocumentName>{content.fileName}</DocumentName>
                                <DocumentType>{content.fileType}</DocumentType>
                            </DocumentInfo>
                        </DocumentItem>
                    )
                }
            })}
        </_UserChatMessageBox>
    )
}