import React from 'react';
import styled from 'styled-components';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface GaugeChartProps {
  value: number;
  min: number;
  max: number;
  title: string;
  unit?: string;
}

const ChartWrapper = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
  text-align: center;
`;

export const GaugeChart: React.FC<GaugeChartProps> = ({ value, min, max, title, unit = '' }) => {
  const options: ApexOptions = {
    chart: {
      type: 'radialBar' as const,
      offsetY: -20,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
            formatter: function(val: number) {
              const actualValue = (val / 100) * (max - min) + min;
              return `${actualValue.toFixed(1)}${unit}`;
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#3C94D6'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
  };

  const series = [((value - min) / (max - min)) * 100];

  return (
    <ChartWrapper>
      <Title>{title}</Title>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={200}
      />
    </ChartWrapper>
  );
};