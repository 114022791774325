import React, { useRef, useEffect } from 'react';
import { Position } from 'reactflow';
import { NodeWrapper, NodeHeader, NodeTitle, NodeTitleInput, StyledHandle, NodeHeaderLeft, NodeHeaderRight, NodeBody } from '../Node.styles';
import { CustomNodeData, nodeToSVGName, StartNodeData } from '../types/nodes';
import { DynamicSvg } from '../../../ui-components/DynamicSvg';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { Connection } from 'reactflow';
import { useEditorStore } from '../../../stores/editorStore';
import { useSocketStore } from '../../../stores/socketStore';
import { useWorkflowStore } from '../../../stores/workflowStore';
import InProgressEffect from '../../../ui-components/InProgressEffect';
import { ChatMessage } from '../../../types/message';
import { abortTransactionGroup } from '../../../utils/socketUtils';
import { supabase } from '../../../utils/supabase';

const StartNodeBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const StartNodeButton = styled.button`
  background-color: transparent;
  color: #4CAF50;
  padding: 8px 24px;
  border: 1.5px solid #4CAF50;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  
  &::after {
    content: '▶';
    font-size: 10px;
    margin-left: 4px;
  }

  &:hover {
    background-color: rgba(76, 175, 80, 0.04);
  }

  &:disabled {
    color: #999;
    border-color: #999;
    cursor: not-allowed;
    
    &:hover {
      background-color: transparent;
    }
  }
`;

const CancelButton = styled(StartNodeButton)`
  color: #dc3545;
  border-color: #dc3545;

  &:hover {
    background-color: rgba(220, 53, 69, 0.04);
  }

  &::after {
    content: '⨯';
    font-size: 14px;
  }
`;

const StartNode = ({ data, id, selected, xPos, yPos, zIndex }: { 
  data: StartNodeData;
  id: string;
  selected?: boolean;
  xPos: number;
  yPos: number;
  zIndex?: number;
}) => {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState(data.label || 'Start');
  const inputRef = useRef<HTMLInputElement>(null);
  const { socket } = useSocketStore();
  const { activeId, startWorkflowExecution, whichWorkflowIsRunning, nodeExecuting } = useWorkflowStore();

  const { chatMessages, addChatMessage } = useEditorStore();
  // Mock data for reference
  const mockChatMessages = require("../../../mock-data/chat-responses.json");
  const modWindows1MockChatMessages = require("../../../mock-data/mod-windows-1-chat-responses.json");
  const modWindows2MockChatMessages = require("../../../mock-data/mod-windows-2-chat-responses.json");
  const roomAreaMockChatMessages = require("../../../mock-data/room-area-chat-responses.json");
  const addTrayCeilingMockChatMessages = require("../../../mock-data/add-tray-ceiling-chat-responses.json");

  const onConnect = useCallback((connection: Connection) => {
    // This will be called when a valid connection is made
    console.log('Connection made:', connection);
    // TODO: Add node creation logic here
  }, []);

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTitleEditing(true);
    // Focus the input on the next tick after it's rendered
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputBlur = () => {
    setIsTitleEditing(false);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setIsTitleEditing(false);
    }
    if (event.key === 'Escape') {
      setIsTitleEditing(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value || 'Start');
  };

  const processFileInFinalState = async (value: string, key: string) => {
    try {
      // Generate unique filename while preserving extension
      const ext = key.split('.').pop();
      const timestamp = Date.now();
      const randomId = crypto.randomUUID();
      const fileName = `${timestamp}-${randomId}.${ext}`;
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return value;

      const filePath = `${user.id}/${fileName}`;
      
      // Set appropriate MIME type based on file extension
      let mimeType = 'text/plain';
      if (ext === 'csv') {
        mimeType = 'text/csv';
      } else if (ext === 'json') {
        mimeType = 'application/json';
      }

      // Convert string content to Blob with correct MIME type
      const blob = new Blob([value], { type: mimeType });
      const file = new File([blob], fileName);

      // Upload to Supabase storage
      const { error } = await supabase.storage
        .from('chat_docs')
        .upload(filePath, file);

      if (error) {
        console.error('Error uploading file:', error);
        return value;
      }

      // Return the special format
      return `ARCHILABS_STORED_FILE:${filePath}:${ext}`;
    } catch (error) {
      console.error('Error processing file:', error);
      return value;
    }
  };

  const processObject = async (obj: any): Promise<any> => {
    if (!obj) return obj;
    
    const processed: any = {};
    
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        processed[key] = await processObject(value);
      } else if (
        typeof value === 'string' && 
        (key.endsWith('.csv') || key.endsWith('.json') || key.endsWith('.txt'))
      ) {
        processed[key] = await processFileInFinalState(value, key);
      } else {
        processed[key] = value;
      }
    }
    
    return processed;
  };

  const runWorkflow = async () => {
    try {
      // Wait for socket connection if needed
      const maxAttempts = 5;
      let attempts = 0;
      while (!useSocketStore.getState().isConnected && attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, 200));
        attempts++;
      }

      console.log('Running workflow');
      const result = await startWorkflowExecution(activeId);
      console.log('Workflow result:', result);
      console.log('Node execution results:', result.nodesExecutedResults);

      // Get the workflow data from the store
      const workflow = useWorkflowStore.getState().workflows[activeId];

      // Create a run log message from the execution results
      const runLogMessage: ChatMessage = {
        role: "assistant",
        content: [{
          type: "run-log-in-chat",
          title: "Workflow Execution Results",
          steps: result.nodesExecutedResults.map(nodeResult => {
            console.log("Node result:", nodeResult);
            const node = workflow.nodes.find(n => n.id === nodeResult.nodeId);
            return {
              type: "step",
              title: node?.data.label || 'unknown',
              status: nodeResult.success ? "success" : "failure",
              time: (nodeResult.executionTime || 0) / 1000,
              nodeType: nodeToSVGName[node?.data.type || 'code']
            };
          }),
          final_state: result.runtimeState
        }]
      };

      // Process the final state before adding the chat message
      if (runLogMessage.content[0].type === 'run-log-in-chat' && runLogMessage.content[0].final_state) {
        runLogMessage.content[0].final_state = await processObject(runLogMessage.content[0].final_state);
      }

      addChatMessage(runLogMessage);

    } catch (error) {

    }
  };

  // // Mock code execution for reference
  const mockRunCode = () => {
     console.log('Running code');
     socket?.emit('connectPythonFromFrontend', {
       message: data?.code,
    });
  }

  useEffect(() => {
    console.log("SOCKET BEING USED")
    socket?.on('pythonResponse', (data) => {
      console.log('Python response:', data);
    });
  }, [socket]);

  const getButtonText = () => {
    if (whichWorkflowIsRunning === null) {
      return 'Run Workflow';
    }
    if (whichWorkflowIsRunning === activeId) {
      return 'Workflow in progress';
    }
    return 'Another workflow in progress';
  };

  const handleCancel = async () => {
    try {
      if (socket) {
        abortTransactionGroup(socket);
      }
    } catch (error) {
      console.error('Error cancelling workflow:', error);
    }
  };

  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeId}
      executingNodeId={nodeExecuting}
    >
      <NodeWrapper
        id={id}
        type={data.type}
        data={data}
        selected={selected || false}
        isConnectable={true}
        xPos={xPos}
        yPos={yPos}
        zIndex={zIndex || 0}
        dragging={false}
      >
        <StyledHandle 
          type="source" 
          position={Position.Right}
          onConnect={onConnect}
        />
        
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="Entry" customIconColor="#3C94D6" width={20} height={20}  />
            {isTitleEditing ? (
            <NodeTitleInput
              ref={inputRef}
              value={title}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <NodeTitle onDoubleClick={handleDoubleClick}>
              {title}
            </NodeTitle>
            )}
          </NodeHeaderLeft>
        </NodeHeader>
        <NodeBody>
          <StartNodeBody>
            <StartNodeButton 
              onClick={runWorkflow}
              disabled={whichWorkflowIsRunning !== null}
            >
              {getButtonText()}
            </StartNodeButton>
            {whichWorkflowIsRunning === activeId && (
              <CancelButton onClick={handleCancel}>
                Cancel Workflow Execution
              </CancelButton>
            )}
          </StartNodeBody>
        </NodeBody>
      </NodeWrapper>
    </InProgressEffect>
  );
};

export default StartNode;