export default {
    "nodes": [
        {
            "id": "1",
            "type": "start",
            "position": { "x": 100, "y": 100 },
            "data": {
                "type": "start",
                "label": "Mod Room Windows Workflow",
                "iconName": "Entry",
                "iconColor": "#000000",
                "tooltip": "Starts the workflow"
            }
        },
        {
            "id": "2",
            "type": "code",
            "position": { "x": 450, "y": -8 },
            "data": {
                "type": "code",
                "label": "Modify Window Heights",
                "iconName": "Code",
                "iconColor": "#000000",
                "tooltip": "Modifies the heights of specified windows",
                "code": `
import clr
clr.AddReference('RevitAPI')
clr.AddReference('RevitAPIUI')

from Autodesk.Revit.UI import TaskDialog
from System.Collections.Generic import List

def get_element_by_ifc_guid(guid):
    param_id = ElementId(BuiltInParameter.IFC_GUID)
    param_provider = ParameterValueProvider(param_id)
    filter_rule = FilterStringRule(param_provider, FilterStringEquals(), guid)
    param_filter = ElementParameterFilter(filter_rule)
    
    collector = FilteredElementCollector(doc).WherePasses(param_filter)
    return collector.FirstElement()

def modify_window_height(window, host_wall):
    # Get the wall height
    wall_height = host_wall.get_Parameter(BuiltInParameter.WALL_USER_HEIGHT_PARAM).AsDouble()
    
    # Get the window height parameter
    window_height_param = window.get_Parameter(BuiltInParameter.WINDOW_HEIGHT)
    
    # Set the window height to half the wall height
    new_height = wall_height / 2
    window_height_param.Set(new_height)

def execute(state):
    # Get window GUIDs from state
    window_guids = state.get('window_guids', [])
    results = {
        'modified_windows': [],
        'failed_windows': []
    }

    # Process each window
    for guid in window_guids:
        try:
            window = get_element_by_ifc_guid(guid)
            if window:
                host_wall = doc.GetElement(window.Host.Id)
                modify_window_height(window, host_wall)
                
                # Store successful modification
                results['modified_windows'].append({
                    'guid': guid,
                    'wall_id': host_wall.Id.ToString()
                })
            else:
                results['failed_windows'].append({
                    'guid': guid,
                    'reason': 'Window not found'
                })
        except Exception as e:
            results['failed_windows'].append({
                'guid': guid,
                'reason': str(e)
            })

    # Display results in TaskDialog
    dialog = TaskDialog("Window Modification Results")
    dialog.MainInstruction = "Window Height Modification Results"

    report = "WINDOW MODIFICATION REPORT\\n"
    report += "=" * 50 + "\\n"
    report += f"Successfully modified windows: {len(results['modified_windows'])}\\n"
    report += f"Failed modifications: {len(results['failed_windows'])}\\n\\n"

    if results['modified_windows']:
        report += "Modified Windows:\\n"
        report += "-" * 30 + "\\n"
        for win in results['modified_windows']:
            report += f"GUID: {win['guid']}\\n"
            report += f"Host Wall ID: {win['wall_id']}\\n"

    if results['failed_windows']:
        report += "\\nFailed Windows:\\n"
        report += "-" * 30 + "\\n"
        for win in results['failed_windows']:
            report += f"GUID: {win['guid']}\\n"
            report += f"Reason: {win['reason']}\\n"

    dialog.MainContent = report
    dialog.Show()

    # Return updated state
    return {
        'results': results
    }`
            }
        }
    ],
    "edges": [
        {
            "id": "e1-2",
            "source": "1",
            "target": "2"
        }
    ],
    "state": {
        "window_guids": [
            "1ikhyTwD17yAnDcdaZ57Om",
            "1ikhyTwD17yAnDcdaZ57O9",
            "0ELdlxJuP1AwzgUXpRT7iS",
            "1ikhyTwD17yAnDcdaZ57Pn"
        ],
        "results": null    // Will be updated by function execution
    }
} 