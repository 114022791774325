import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BranchNodeData, CustomNodeData } from '../types/nodes';
import { NodeTitle, NodeHeader, NodeTitleInput, NodeHeaderLeft, NodeHeaderRight, NodeHeaderButton, NodeFieldLabel, NodeFields, NodeBody } from '../Node.styles';
import { Connection, NodeProps, Edge } from 'reactflow';
import { DynamicSvg } from '../../../ui-components/DynamicSvg';
import Editor from '@monaco-editor/react';
import styled from 'styled-components';
import { useWorkflowStore } from '../../../stores/workflowStore';
import { BaseNode } from './BaseNode';
import InProgressEffect from '../../../ui-components/InProgressEffect';
import { debounce } from 'lodash';

const EdgeContainer = styled.div`
  margin-bottom: 12px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const EdgeRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
  
  &:disabled {
    background-color: #f5f5f5;
    color: #999;
  }
`;

const DeleteButton = styled(NodeHeaderButton)`
  color: #FF0000;
  &:hover {
    background-color: rgba(255, 0, 0, 0.1);
  }
`;

const AddButton = styled(NodeHeaderButton)`
  color: #3C94D6;
  padding: 4px 8px;
  font-size: 12px;
`;

const AdvancedToggle = styled.button<{ active: boolean }>`
  background: ${props => props.active ? '#3C94D6' : 'transparent'};
  color: ${props => props.active ? 'white' : '#3C94D6'};
  border: 1px solid #3C94D6;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 8px;

  &:hover {
    background: ${props => props.active ? '#2d7ab8' : 'rgba(60, 148, 214, 0.1)'};
  }
`;

const EditorContainer = styled.div`
  position: relative;
  background: #f0f0f0;
  border: 2px solid #e0e0e0;
  min-width: 300px;
  border-radius: 4px;
  margin-top: 8px;

  .monaco-editor .margin {
    background-color: #f5f5f5;
  }
`;

const HandleLabel = styled.span`
  font-size: 11px;
  color: #666;
  background: white;
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  white-space: nowrap;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  pointer-events: none;
`;

const WarningMessage = styled.div`
  color: #f57c00;
  font-size: 12px;
  padding: 8px 12px;
  background: #fff3e0;
  border-radius: 4px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface SourceHandleDefinition {
  id: string;
  name: string;
  condition: string;
}

const extractPotentialVariables = (condition: string): string[] => {
  const withoutStrings = condition.replace(/'[^']*'|"[^"]*"/g, '');
  
  const pythonKeywords = new Set([
    'and', 'or', 'not', 'True', 'False', 'None', 'in', 'is'
  ]);
  
  const matches = withoutStrings.match(/[a-zA-Z_]\w*/g) || [];
  
  return matches
    .filter(match => !pythonKeywords.has(match))
    .filter(match => !['len', 'sum', 'min', 'max'].includes(match))
    .filter((match, index, array) => array.indexOf(match) === index);
};

export function BranchNode({ 
  data, 
  id, 
  selected = false,
  xPos, 
  yPos, 
  zIndex = 0,
}: { 
  data: CustomNodeData;
  id: string;
  selected?: boolean | undefined;
  xPos: number;
  yPos: number;
  zIndex?: number | undefined;
}) {

  const branchData = data as BranchNodeData;  // Cast data to BranchNodeData
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [advancedCode, setAdvancedCode] = useState('');
  const [localTitle, setLocalTitle] = useState(branchData.label || 'Edge');
  const [localSourceHandles, setLocalSourceHandles] = useState(branchData.sourceHandles || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<any>(null);
  
  const { 
    updateNodeData, 
    activeId: activeWorkflow, 
    whichWorkflowIsRunning, 
    removeEdgeMatchingSourceHandle,
    nodes,
    nodeExecuting,
    available_variables
  } = useWorkflowStore();

  // Add these refs for tracking cursor positions
  const nameInputRefs = useRef<{ [key: string]: HTMLInputElement }>({});
  const conditionInputRefs = useRef<{ [key: string]: HTMLInputElement }>({});

  // Create debounced update function
  const debouncedUpdateNodeData = useCallback(
    debounce((key: string, value: any) => {
      updateNodeData(id, key, value);
    }, 500),
    [id, updateNodeData]
  );

  // Update local state when props change
  useEffect(() => {
    setLocalTitle(branchData.label || 'Edge');
    setLocalSourceHandles(branchData.sourceHandles || []);
  }, [branchData.label, branchData.sourceHandles]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value || 'Edge';
    setLocalTitle(newTitle); // Immediate UI update
    debouncedUpdateNodeData('label', newTitle); // Debounced store update
  };

  const handleAddSourceHandle = () => {
    const newSourceHandle: SourceHandleDefinition = {
      id: crypto.randomUUID(),
      name: `Edge ${localSourceHandles.length + 1}`,
      condition: ''
    };
    
    const updatedHandles = [...localSourceHandles, newSourceHandle];
    setLocalSourceHandles(updatedHandles); // Immediate UI update
    debouncedUpdateNodeData('sourceHandles', updatedHandles); // Debounced store update
  };

  const handleRemoveSourceHandle = (sourceHandleId: string) => {
    const updatedHandles = localSourceHandles.filter(handle => handle.id !== sourceHandleId);
    setLocalSourceHandles(updatedHandles); // Immediate UI update
    removeEdgeMatchingSourceHandle(sourceHandleId);
    debouncedUpdateNodeData('sourceHandles', updatedHandles); // Debounced store update
  };

  const handleSourceHandleChange = (sourceHandleId: string, field: keyof SourceHandleDefinition, value: string) => {
    // Store cursor position before update
    const inputRef = field === 'name' ? nameInputRefs.current[sourceHandleId] : conditionInputRefs.current[sourceHandleId];
    const cursorPosition = inputRef?.selectionStart || 0;

    const updatedHandles = localSourceHandles.map(sourceHandle => {
      if (sourceHandle.id === sourceHandleId) {
        return { ...sourceHandle, [field]: value };
      }
      return sourceHandle;
    });
    setLocalSourceHandles(updatedHandles); // Immediate UI update
    debouncedUpdateNodeData('sourceHandles', updatedHandles); // Debounced store update

    // Restore cursor position after React re-render
    setTimeout(() => {
      if (inputRef) {
        inputRef.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  const toggleMode = () => {
    const newMode = branchData.mode === 'advanced' ? 'simple' : 'advanced';
    updateNodeData(id, 'mode', newMode);
  };

  const getMissingVariables = () => {
    const allConditions = (branchData.sourceHandles || [])
      .map(handle => handle.condition)
      .filter(Boolean);
      
    const allVariables = allConditions.flatMap(extractPotentialVariables);
    return allVariables.filter(variable => !available_variables?.includes(variable));
  };
  if (data.type !== 'branch') {
    return null;
  }

  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeWorkflow}
      executingNodeId={nodeExecuting}
    >
      <BaseNode 
        id={id} 
        data={data} 
        selected={selected} 
        xPos={xPos} 
        yPos={yPos} 
        zIndex={zIndex}
        type="edge"
        isConnectable={true}
        dragging={false}
        sourceHandles={localSourceHandles}
        isCollapsed={isCollapsed}
      >
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="Branch" customIconColor="#D63C3C" width={20} height={20} />
            {isTitleEditing ? (
              <NodeTitleInput
                ref={inputRef}
                value={localTitle}
                onChange={handleTitleChange}
                onBlur={() => setIsTitleEditing(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Escape') {
                    setIsTitleEditing(false);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <NodeTitle onDoubleClick={() => setIsTitleEditing(true)}>
                {localTitle}
              </NodeTitle>
            )}
          </NodeHeaderLeft>
          <NodeHeaderRight>
            <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <DynamicSvg 
                name={isCollapsed ? "Plus" : "Minus"} 
                customIconColor="#7F7F7F" 
                width={16} 
                height={16} 
              />
            </NodeHeaderButton>
          </NodeHeaderRight>
        </NodeHeader>

        {selected && getMissingVariables().length > 0 && (
          <WarningMessage>
            <DynamicSvg name="Warning" customIconColor="#f57c00" width={16} height={16} />
            Undefined variables in conditions: {getMissingVariables().join(', ')}
          </WarningMessage>
        )}

        <NodeBody>
          {!isCollapsed && (
            <NodeFields>
              {/* 
                <AdvancedToggle 
                  active={data.mode === 'advanced'}
                  onClick={toggleMode}
                >
                  {data.mode === 'advanced' ? 'Switch to Simple Mode' : 'Switch to Advanced Mode'}
                </AdvancedToggle>
              */}
              {localSourceHandles.map((sourceHandle) => (
                <EdgeContainer key={sourceHandle.id}>
                  <EdgeRow key={`${sourceHandle.id}-row`}>
                    <StyledInput
                      key={`${sourceHandle.id}-name`}
                      ref={(el) => nameInputRefs.current[sourceHandle.id] = el!}
                      type="text"
                      value={sourceHandle.name}
                      onChange={(e) => handleSourceHandleChange(sourceHandle.id, 'name', e.target.value)}
                      placeholder="Edge name"
                    />
                    <DeleteButton onClick={() => handleRemoveSourceHandle(sourceHandle.id)}>
                      <DynamicSvg name="Delete" customIconColor="#FF0000" width={16} height={16} />
                    </DeleteButton>
                  </EdgeRow>
                  {data.mode !== 'advanced' && (
                    <StyledInput
                      key={`${sourceHandle.id}-condition`}
                      ref={(el) => conditionInputRefs.current[sourceHandle.id] = el!}
                      type="text"
                      value={sourceHandle.condition}
                      onChange={(e) => handleSourceHandleChange(sourceHandle.id, 'condition', e.target.value)}
                      placeholder="Condition (e.g. output_1 > 10)"
                      style={{ width: '80%' }}
                    />
                  )}
                </EdgeContainer>
              ))}

              <AddButton onClick={handleAddSourceHandle}>
                Add Edge
              </AddButton>

              {data.mode === 'advanced' && (
                <EditorContainer>
                  <Editor
                    height="200px"
                    defaultLanguage="python"
                    value={advancedCode}
                    onChange={(value) => setAdvancedCode(value || '')}
                    onMount={(editor) => editorRef.current = editor}
                    options={{
                      minimap: { enabled: false },
                      scrollBeyondLastLine: false,
                      fontSize: 12,
                      theme: 'vs-light',
                      lineNumbersMinChars: 2,
                      padding: { top: 8, bottom: 8 },
                      lineDecorationsWidth: 5,
                      glyphMargin: false,
                      folding: false,
                      renderLineHighlight: 'none'
                    }}
                  />
                </EditorContainer>
              )}
            </NodeFields>
          )}
        </NodeBody>
      </BaseNode>
    </InProgressEffect>
  );
}
