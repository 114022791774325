import { createWorkflowEngine } from './WorkflowEngine';
import { useWorkflowStore } from '../stores/workflowStore';
import { abortTransactionGroup } from '../utils/socketUtils';
import { 
  createCodeNodeExecutor, 
  createAIPromptNodeExecutor, 
  createSelectInRevitNodeExecutor,
  createStartNodeExecutor,
  createUserInputNodeExecutor,
  createBranchNodeExecutor
} from './executors/nodeExecutors';
import { NodeExecutionResult, WorkflowDefinition, WorkflowEngine, WorkflowExecutionResult, WorkflowStateManager } from './types';
import { useSocketStore } from '../stores/socketStore';

export function createWorkflowExecutionBridge() {
  let currentEngine: WorkflowEngine | null = null;

  function createStateManager(executionId: string): WorkflowStateManager {
    return {
      // Node state management
      getState: () => {
        console.log('Getting state:', useWorkflowStore.getState().runtimeState);
        return useWorkflowStore.getState().runtimeState;
      },
      setState: (updates: Record<string, any>) => {
        console.log('Setting state with updates:', updates);
        useWorkflowStore.setState(state => ({
          runtimeState: {
            ...state.runtimeState,
            ...updates
          }
        }));
        console.log('State updated:', useWorkflowStore.getState().runtimeState);
      },

      getNodeData: (nodeId: string) => {
        var workflowId = useWorkflowStore.getState().whichWorkflowIsRunning;
        if (!workflowId) {
          return null;
        }
        var res = useWorkflowStore.getState().workflows[workflowId].nodes.find(node => node.id === nodeId);
        if (!res) {
          return null;
        }
        return res.data;
      },

      updateNodeData: (nodeId: string, key: string, value: any) => {
        useWorkflowStore.getState().updateNodeData(nodeId, key, value);
      },

      // Error state management
      setNodeError: (nodeId: string, error: string | null) => {
        useWorkflowStore.getState().setNodeError(nodeId, error);
      },
      getNodeErrors: () => useWorkflowStore.getState().getNodeErrors(),
      clearNodeError: (nodeId: string) => {
        useWorkflowStore.getState().clearNodeError(nodeId);
      },

      // Execution control
      acquireExecutionLock: (executionId: string) => {
        if (useWorkflowStore.getState().isExecutionLocked(executionId)) {
          return false;
        }
        useWorkflowStore.setState({ runId: executionId });
        return true;
      },

      setNodeExecuting: (nodeId: string) => {
        useWorkflowStore.getState().setNodeExecuting(nodeId);
      },
      setNodeExecutedResult: (nodeId: string, result: NodeExecutionResult) => {
        useWorkflowStore.getState().setNodeExecutedResult(nodeId, result);
      },

      returnRuntimeState: () => {
        return useWorkflowStore.getState().returnRuntimeState();
      },

    markWorkflowChanged: (workflowId: string) => {
      useWorkflowStore.getState().markWorkflowChanged(workflowId);
    },

    getPendingChanges: () => {
      return useWorkflowStore.getState().getPendingChanges();
    },
      
      isExecutionLocked: (executionId: string) => useWorkflowStore.getState().isExecutionLocked(executionId),

      // Node queue management
      addNodesToExecute: (nodes) => useWorkflowStore.getState().addNodesToExecute(nodes, executionId),
      addEdgesToExecute: (edges) => useWorkflowStore.getState().addEdgesToExecute(edges, executionId),
      getNextNodeToExecute: () => useWorkflowStore.getState().getNextNodeToExecute(executionId),

      // Reset execution state
      reset: () => useWorkflowStore.getState().resetRuntime()
    };
  }

  async function startExecution(timeoutSeconds: number, workflowId: string): Promise<WorkflowExecutionResult> {
    const store = useWorkflowStore.getState();
    const workflow = store.workflows[workflowId];

    if (!workflow) {
      throw new Error(`Workflow with id ${workflowId} not found`);
    }

    const executionId = crypto.randomUUID();
    const stateMan = createStateManager(executionId);
    if (!stateMan.acquireExecutionLock(executionId)) {
      throw new Error('Another workflow is currently running');
    }
    useWorkflowStore.getState().setWhichWorkflowIsRunning(workflowId);

    const engine = createWorkflowEngine(
      workflow,
      stateMan,
      executionId
    );

    registerNodeExecutors(engine, false);
    currentEngine = engine;

    // Set up timeout
    const timeoutId = setTimeout(() => {
      console.log(`Workflow execution timed out after ${timeoutSeconds} seconds`);
      const socket = useSocketStore.getState().socket;
      if (socket) {
        abortTransactionGroup(socket);
      }

    }, timeoutSeconds * 1000);

    try {
      const result = await engine.execute();
      clearTimeout(timeoutId); // Clear timeout if execution completes successfully
      return result;
    } finally {
      clearTimeout(timeoutId); // Ensure timeout is cleared even if execution fails
      currentEngine = null;
    }
  }

  async function temporaryWorkflowStartExecution(timeoutSeconds: number, workflow: WorkflowDefinition): Promise<WorkflowExecutionResult> {
    const executionId = crypto.randomUUID();
    const stateMan = createStateManager(executionId);
    const engine = createWorkflowEngine(
      workflow,
      stateMan,
      executionId
    );

    console.log("State manager:", stateMan);

    registerNodeExecutors(engine, true);
    currentEngine = engine;
    var result: WorkflowExecutionResult = {
      runtimeState: {},
      nodeErrors: {},
      runId: null,
      runLastAliveTime: null,
      nodesToExecute: [],
      edgesToExecute: [],
      nodesExecutedResults: [],
      nodeExecuting: null
    };

    let timeoutId: NodeJS.Timeout | undefined;

    try {
      while (true) {
        try {
          if (stateMan.acquireExecutionLock(executionId)) {
            // Set up timeout only after acquiring the lock
            timeoutId = setTimeout(() => {
              console.log(`Workflow execution timed out after ${timeoutSeconds} seconds`);
              const socket = useSocketStore.getState().socket;
              if (socket) {
                abortTransactionGroup(socket);
              }
            }, timeoutSeconds * 1000);

            result = await engine.execute();
            break; // Success - exit the retry loop
          }
        } catch (error) {
          if (error instanceof Error && error.message === 'Another workflow is currently running') {
            await new Promise(resolve => setTimeout(resolve, 500));
            continue; // Retry
          }
          throw error; // Rethrow any other errors
        }
      }
      if (timeoutId) clearTimeout(timeoutId); // Clear timeout if execution completes successfully
      return result;
    } finally {
      if (timeoutId) clearTimeout(timeoutId); // Only clear if timeout was set
      currentEngine = null;
    }
  }

  function registerNodeExecutors(engine: WorkflowEngine, automated: boolean) {
    engine.registerNodeExecutor('start', createStartNodeExecutor());
    engine.registerNodeExecutor('code', createCodeNodeExecutor());
    engine.registerNodeExecutor('ai_prompt', createAIPromptNodeExecutor());
    engine.registerNodeExecutor('select_in_revit', createSelectInRevitNodeExecutor(automated));
    engine.registerNodeExecutor('user_input', createUserInputNodeExecutor());
    engine.registerNodeExecutor('branch', createBranchNodeExecutor());
  }

  function stop(): void {
    currentEngine?.stop();
  }

  return {
    startExecution,
    stop,
    temporaryWorkflowStartExecution
  };
} 