import ReactFlow, { Node, Edge, Background, Controls, applyNodeChanges, applyEdgeChanges, MiniMap, Connection, ReactFlowProvider, useReactFlow, useKeyPress, SelectionMode } from 'reactflow';
import 'reactflow/dist/style.css';
import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { NODE_TYPES } from './constants/nodeTypes';
import { FlowWrapper } from '../../App.styles';
import { CustomNodeData } from './types/nodes';
import StartNode from './nodes/StartNode';
import { CodeNode } from './nodes/CodeNode';
import { AIPromptNode } from './nodes/AIPromptNode';
import { UserInputNode } from './nodes/UserInput';
import { BranchNode } from './nodes/BranchNode';
import { SelectInRevitNode } from './nodes/SelectInRevitNode';
import { useWorkflowStore } from '../../stores/workflowStore';
import { DynamicSvg } from '../../ui-components/DynamicSvg';
import styled from 'styled-components';
import { duplicateSelection } from '../../utils/flowHelpers';
import { createDefaultNodeData } from './types/nodes';

const ButtonGroup = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
  display: flex;
  gap: 8px;
`;

const ActionButton = styled.button<{ active?: boolean }>`
  padding: 8px;
  background: ${props => props.active ? '#3C94D6' : 'white'};
  border: 1px solid #3C94D6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props => props.active ? 'white' : '#3C94D6'};
  
  &:hover {
    background: ${props => props.active ? '#2d7ab8' : '#f0f9ff'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SuccessBanner = styled.div`
    background-color: rgba(198, 246, 213, 0.95);
    color: #2F855A;
    padding: 8px 16px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    max-width: 90%;
    font-size: 14px;
    border: 1px solid #C6F6D5;
    display: flex;
    align-items: center;
    gap: 12px;
`;

function Flow({ onExpandCode }: { onExpandCode: (code: string, language: string, nodeId: string) => void }) {
  const { 
    nodes, 
    edges, 
    setNodes, 
    setEdges,
    onNodesChange, 
    onEdgesChange, 
    onConnect,
    markWorkflowChanged,
    activeId,
    available_variables,
    grabAvailableVariablesFromPreviousNodes,
    undo,
    redo,
    canUndo,
    canRedo
  } = useWorkflowStore();
  
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const deleteKeyPressed = useKeyPress('Backspace');
  const deleteKeyPressed2 = useKeyPress('Delete');
  const [selectionMode, setSelectionMode] = useState(false);
  const [copiedNodes, setCopiedNodes] = useState<Node[]>([]);
  const [copiedEdges, setCopiedEdges] = useState<Edge[]>([]);
  const [banner, setBanner] = useState<{ message: string; visible: boolean }>({ message: '', visible: false });

  const handleDelete = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => !node.selected));
    setEdges((edges) => edges.filter((edge) => !edge.selected));
  }, [setNodes, setEdges]);

  useEffect(() => {
    if (deleteKeyPressed || deleteKeyPressed2) {
      handleDelete();
    }
  }, [deleteKeyPressed, deleteKeyPressed2, handleDelete]);

  const showBanner = (message: string) => {
    setBanner({ message, visible: true });
    setTimeout(() => {
      setBanner(prev => ({ ...prev, visible: false }));
    }, 3000);
  };

  const handleCopy = useCallback(() => {
    const selectedNodes = nodes.filter(node => node.selected);
    if (selectedNodes.length === 0) return;

    const selectedEdges = edges.filter(edge => 
      selectedNodes.some(node => node.id === edge.source) && 
      selectedNodes.some(node => node.id === edge.target)
    );
    
    setCopiedNodes(selectedNodes);
    setCopiedEdges(selectedEdges);
    showBanner('Blocks copied successfully');
  }, [nodes, edges]);

  const handlePaste = useCallback(() => {
    if (copiedNodes.length === 0) return;

    const { nodes: newNodes, edges: newEdges } = duplicateSelection(copiedNodes, copiedEdges);
    
    setNodes(nds => [...nds, ...newNodes]);
    setEdges(eds => [...eds, ...newEdges]);
  }, [copiedNodes, copiedEdges, setNodes, setEdges]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        handleCopy();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        handlePaste();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleCopy, handlePaste]);


  useEffect(() => {
    if (activeId) {
      setTimeout(() => {
        reactFlowInstance.fitView({
          padding: 0.2,
          duration: 200,
          minZoom: 0.1,
          maxZoom: 0.5
        });
      }, 50);
    }
  }, [activeId, reactFlowInstance]);

  const onSelectionDragStart = useCallback(() => {
    if (!selectionMode) return;
    
    const selectedNodes = nodes.filter(node => node.selected);
    if (selectedNodes.length > 1) {
    }
  }, [selectionMode, nodes]);

  const onConnectStart = useCallback((event: any, { nodeId, handleType }: any) => {
    console.log('Started connection from:', nodeId, handleType);
  }, []);

  const onConnectEnd = useCallback((event: any) => {
    const targetIsPane = event.target.classList.contains('react-flow__pane');
    if (targetIsPane) {
      const { x, y } = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      console.log('Connection ended at coordinates:', { x, y });
    }

  }, [reactFlowInstance]);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    console.log("Dragging over")
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type || !reactFlowBounds) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode: Node<CustomNodeData> = {
        id: `${type}__${crypto.randomUUID().replace(/-/g, '')}`,
        type: type,
        position,
        data: createDefaultNodeData(type as keyof typeof NODE_TYPES, type)
      };

      // Create new array with the new node
      const newNodes = [...nodes, newNode];
      
      setNodes(newNodes);
      markWorkflowChanged();
    },
    [reactFlowInstance, nodes, setNodes, markWorkflowChanged]
  );

  const nodeTypes = useMemo(() => ({
    [NODE_TYPES.start]: StartNode,
    [NODE_TYPES.code]: (props: any) => <CodeNode {...props} onExpandCode={onExpandCode} />,
    [NODE_TYPES.ai_prompt]: AIPromptNode,
    [NODE_TYPES.select_in_revit]: SelectInRevitNode,
    [NODE_TYPES.user_input]: UserInputNode,
    [NODE_TYPES.branch]: BranchNode
  }), [onExpandCode]);

  const onNodeDragStart = useCallback((event: React.MouseEvent, node: Node) => {
    console.log('Started dragging node:', node.id);
  }, []);

  const onNodeDrag = useCallback((event: React.MouseEvent, node: Node) => {
    console.log('Dragging node:', node.id);
  }, []);

  const onNodeDragStop = useCallback((event: React.MouseEvent, node: Node) => {
    console.log('Stopped dragging node:', node.id);
    markWorkflowChanged();
  }, []);

  const onSelectionChange = useCallback((event: any) => {
    console.log('Selection changed:', event);
    if (event.nodes.length == 1) {
      grabAvailableVariablesFromPreviousNodes(event.nodes[0].id);
    }
    else{
      grabAvailableVariablesFromPreviousNodes(undefined);
    }
  }, [grabAvailableVariablesFromPreviousNodes]);

  const onNodeClick = useCallback((event: any, node: Node) => {
    console.log('Node clicked:', node.id);
    grabAvailableVariablesFromPreviousNodes(node.id);
  }, [grabAvailableVariablesFromPreviousNodes]);



  return (
    <div ref={reactFlowWrapper} style={{ width: '100%', height: '100%' }}>
      {banner.visible && (
        <SuccessBanner>
          {banner.message}
        </SuccessBanner>
      )}
      <ButtonGroup>
        <ActionButton 
          active={selectionMode} 
          onClick={() => setSelectionMode(!selectionMode)}
          title={selectionMode ? "Exit Bulk Selection Mode" : "Enter Bulk Selection Mode"}
        >
          <DynamicSvg 
            name="Select" 
            customIconColor={selectionMode ? "white" : "#3C94D6"} 
            width={16} 
            height={16} 
          />
          {selectionMode ? "Exit Bulk Selection" : "Bulk Selection Mode"}
        </ActionButton>
        
        <ActionButton
          onClick={handleCopy}
          disabled={!nodes.some(node => node.selected)}
          title="Copy Selected (Ctrl+C)"
        >
          <DynamicSvg 
            name="Copy" 
            customIconColor="#3C94D6" 
            width={16} 
            height={16} 
          />
          Copy
        </ActionButton>
        
        <ActionButton
          onClick={handlePaste}
          disabled={copiedNodes.length === 0}
          title="Paste (Ctrl+V)"
        >
          <DynamicSvg 
            name="Paste" 
            customIconColor="#3C94D6" 
            width={16} 
            height={16} 
          />
          Paste
        </ActionButton>
        <ActionButton
          onClick={undo}
          disabled={!canUndo()}
          title="Undo"
        >
          <DynamicSvg 
            name="Undo" 
            customIconColor="#3C94D6" 
            width={16} 
            height={16} 
          />
          Undo
        </ActionButton>
        <ActionButton
          onClick={redo}
          disabled={!canRedo()}
          title="Redo"
        >
          <DynamicSvg 
            name="Redo" 
            customIconColor="#3C94D6" 
            width={16} 
            height={16} 
          />
          Redo
        </ActionButton>
      </ButtonGroup>
      

      <ReactFlow

        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        onNodeDragStart={onNodeDragStart}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onNodeClick={onNodeClick}
        onSelectionDragStart={onSelectionDragStart}
        selectionOnDrag={selectionMode}
        onSelectionChange={onSelectionChange}
        panOnDrag={!selectionMode}
        selectionMode={selectionMode ? SelectionMode.Full : SelectionMode.Partial}
        deleteKeyCode="Backspace"
        fitView
        proOptions={{ hideAttribution: true }}
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
}

interface WorkflowPanelProps {
  onExpandCode: (code: string, language: string, nodeId: string) => void;
}

export const WorkflowPanel: React.FC<WorkflowPanelProps> = ({ onExpandCode }) => {
  return (
    <FlowWrapper>
      <ReactFlowProvider>
        <Flow onExpandCode={onExpandCode} />
      </ReactFlowProvider>
    </FlowWrapper>
  );
};