import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getSecretKey } from '../../utils/supabase';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90vw;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #666;
`;

const KeyContainer = styled.div`
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;
  font-family: monospace;
  word-break: break-all;
`;

const CopyButton = styled.button`
  background: #3C94D6;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: #2d7ab8;
  }
`;

interface SecretKeyProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SecretKey: React.FC<SecretKeyProps> = ({ isOpen, onClose }) => {
  const [secretKey, setSecretKey] = useState<string>('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchSecretKey();
    }
  }, [isOpen]);

  const fetchSecretKey = async () => {
    try {
      const key = await getSecretKey();
      setSecretKey(key);
    } catch (error) {
      console.error('Error fetching secret key:', error);
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(secretKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Header>
          <h2>Your Secret Key</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <KeyContainer>
          {secretKey}
        </KeyContainer>
        <CopyButton onClick={handleCopy}>
          {copied ? 'Copied!' : 'Copy to Clipboard'}
        </CopyButton>
      </Modal>
    </Overlay>
  );
}; 