import React from 'react';
import { Tab } from '../../types/tabs';
import {
  TabsContainer,
  TabsList,
  TabButton,
  CloseButton
} from './TabsBar.styles';

interface TabsBarProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
  onCloseTab?: (tabId: string) => void;
}

const TabsBar: React.FC<TabsBarProps> = ({
  tabs,
  activeTab,
  onTabChange,
  onCloseTab,
}) => {
  return (
    <TabsContainer>
      <TabsList>
        {tabs.map((tab) => (
          <TabButton
            key={tab.id}
            active={activeTab === tab.id}
            onClick={() => onTabChange(tab.id)}
          >
            {tab.title}
            {tab.type !== 'workflow' && onCloseTab && (
              <CloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseTab(tab.id);
                }}
              >
                ×
              </CloseButton>
            )}
          </TabButton>
        ))}
      </TabsList>
    </TabsContainer>
  );
};

export default TabsBar; 