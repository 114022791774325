export default {
    "nodes": [
        {
            "id": "1",
            "type": "start",
            "position": { "x": 100, "y": 100 },
            "data": {
                "type": "start",
                "label": "Mod Room Windows Workflow 2",
                "iconName": "Entry",
                "iconColor": "#000000",
                "tooltip": "Starts the workflow"
            }
        },
        {
            "id": "2",
            "type": "code",
            "position": { "x": 450, "y": -8 },
            "data": {
                "type": "code",
                "label": "Find and Modify Bedroom Windows",
                "iconName": "Code",
                "iconColor": "#000000",
                "tooltip": "Finds all bedroom windows and modifies their heights",
                "code": `
import clr
clr.AddReference('RevitAPI')
clr.AddReference('RevitAPIUI')

from System.Collections.Generic import List

def get_element_by_ifc_guid(guid):
    """Gets a Revit element by its IFC GUID"""
    param_id = ElementId(BuiltInParameter.IFC_GUID)
    param_provider = ParameterValueProvider(param_id)
    filter_rule = FilterStringRule(param_provider, FilterStringEquals(), guid)
    param_filter = ElementParameterFilter(filter_rule)
    
    collector = FilteredElementCollector(doc).WherePasses(param_filter)
    return collector.FirstElement()

def get_rooms_from_element(host_element, doc):
    """Gets rooms associated with an element using spatial relationships"""
    rooms = set()
    try:
        # Get the bounding box of the host element
        bbox = host_element.get_BoundingBox(None)
        if not bbox:
            return []
            
        # Create an outline from the bounding box
        outline = Outline(bbox.Min, bbox.Max)
        
        # Create a 3D bounding box filter
        bbox_filter = BoundingBoxIntersectsFilter(outline)
        
        # Find all rooms that intersect with this bounding box
        room_collector = FilteredElementCollector(doc)\
            .OfCategory(BuiltInCategory.OST_Rooms)\
            .WherePasses(bbox_filter)\
            .ToElements()
            
        for room in room_collector:
            if room.Area > 0:  # Only consider placed rooms
                rooms.add(room)
                
        return list(rooms)
        
    except Exception as e:
        return []

def modify_window_height(window, host_wall):
    """Modifies the window height to be half of its host wall height"""
    try:
        # Get the wall height
        wall_height = host_wall.get_Parameter(BuiltInParameter.WALL_USER_HEIGHT_PARAM).AsDouble()
        
        # Get the window height parameter
        window_height_param = window.get_Parameter(BuiltInParameter.WINDOW_HEIGHT)
        
        # Set the window height to half the wall height
        new_height = wall_height / 2
        window_height_param.Set(new_height)
        return True, None
    except Exception as e:
        return False, str(e)

# Get settings from state
settings = state.get('settings', {})
min_height = settings.get('min_height', 0)  # Minimum window height in feet
max_height = settings.get('max_height')  # Maximum window height in feet

# Get window GUIDs from state
window_guids = state.get('window_guids', [])

# Initialize results
results = {
    'found_windows': [],
    'modified_windows': [],
    'failed_windows': [],
    'bedroom_count': 0
}

# Get all rooms and identify bedrooms
room_collector = FilteredElementCollector(doc).OfCategory(BuiltInCategory.OST_Rooms)
bedrooms = {}  # Store bedroom elements for reference

for room in room_collector:
    if room.Area > 0:  # Check if room is placed
        room_name = room.get_Parameter(BuiltInParameter.ROOM_NAME).AsString()
        if 'bedroom' in room_name.lower() or 'bed' in room_name.lower():
            bedrooms[room.Id] = room_name
            results['bedroom_count'] += 1

# Process each window by GUID
for guid in window_guids:
    try:
        window = get_element_by_ifc_guid(guid)
        if not window:
            results['failed_windows'].append({
                'window_id': guid,
                'status': 'failed',
                'reason': 'Window not found by GUID'
            })
            continue

        window_info = {
            'window_id': window.Id.IntegerValue,
            'guid': guid,
            'status': 'processing'
        }
        
        # Get host wall
        host = window.Host
        if not host:
            window_info['status'] = 'failed'
            window_info['reason'] = 'No host wall found'
            results['failed_windows'].append(window_info)
            continue
        
        window_info['host_id'] = host.Id.IntegerValue
        
        # Get rooms near this window's host element
        adjacent_rooms = get_rooms_from_element(host, doc)
        
        # Check if any adjacent room is a bedroom
        is_bedroom_window = False
        for room in adjacent_rooms:
            if room.Id in bedrooms:
                is_bedroom_window = True
                window_info['bedroom_name'] = bedrooms[room.Id]
                window_info['bedroom_id'] = room.Id.IntegerValue
                break
        
        if is_bedroom_window:
            # Get current window height
            window_type = doc.GetElement(window.GetTypeId())
            current_height = window_type.get_Parameter(BuiltInParameter.WINDOW_HEIGHT).AsDouble()
            window_info['original_height'] = round(current_height * 304.8, 2)  # Convert to mm
            
            # Check height constraints - only check max_height if it's not None
            if current_height < min_height or (max_height is not None and current_height > max_height):
                window_info['status'] = 'skipped'
                window_info['reason'] = f'Window height {current_height} outside allowed range'
                results['failed_windows'].append(window_info)
                continue
            
            # Modify window height
            success, error = modify_window_height(window, host)
            
            if success:
                window_info['status'] = 'modified'
                new_height = window_type.get_Parameter(BuiltInParameter.WINDOW_HEIGHT).AsDouble()
                window_info['new_height'] = round(new_height * 304.8, 2)  # Convert to mm
                results['modified_windows'].append(window_info)
            else:
                window_info['status'] = 'failed'
                window_info['reason'] = error
                results['failed_windows'].append(window_info)
        else:
            window_info['status'] = 'skipped'
            window_info['reason'] = 'Not a bedroom window'
            results['found_windows'].append(window_info)
            
    except Exception as e:
        results['failed_windows'].append({
            'window_id': guid,
            'status': 'failed',
            'reason': str(e)
        })

# Display results in TaskDialog
dialog = TaskDialog("Bedroom Windows Modification Results")
dialog.MainInstruction = "Bedroom Windows Modification Results"

report = "BEDROOM WINDOWS MODIFICATION REPORT\\n"
report += "=" * 50 + "\\n"
report += f"Total Bedrooms Found: {results['bedroom_count']}\\n"
report += f"Total Windows Processed: {len(window_guids)}\\n"
report += f"Modified Windows: {len(results['modified_windows'])}\\n"
report += f"Failed/Skipped Windows: {len(results['failed_windows'])}\\n"
report += f"Other Windows: {len(results['found_windows'])}\\n\\n"

if results['modified_windows']:
    report += "Successfully Modified Windows:\\n"
    report += "-" * 30 + "\\n"
    for win in results['modified_windows']:
        report += f"Window ID: {win['window_id']}\\n"
        report += f"GUID: {win['guid']}\\n"
        report += f"Bedroom: {win.get('bedroom_name', 'Unknown')}\\n"
        report += f"Original Height: {win['original_height']} mm\\n"
        report += f"New Height: {win['new_height']} mm\\n\\n"

if results['failed_windows']:
    report += "\\nFailed/Skipped Windows:\\n"
    report += "-" * 30 + "\\n"
    for win in results['failed_windows']:
        report += f"Window ID: {win.get('window_id', 'N/A')}\\n"
        report += f"GUID: {win.get('guid', 'N/A')}\\n"
        report += f"Status: {win['status']}\\n"
        report += f"Reason: {win['reason']}\\n\\n"

dialog.MainContent = report
dialog.Show()

# Return results to update state
return {
    'results': results
}`
            }
        }
    ],
    "edges": [
        {
            "id": "e1-2",
            "source": "1",
            "target": "2"
        }
    ],
    "state": {
        "settings": {
            "min_height": 0,           // Minimum window height in feet
            "max_height": 20,          // Maximum window height in feet
        },
        "window_guids": [             // Updated GUIDs to match the start node
            "1ikhyTwD17yAnDcdaZ57Om",
            "1ikhyTwD17yAnDcdaZ57O9",
            "1ikhyTwD17yAnDcdaZ57Pn"
        ],
        "results": null               // Will be updated by function execution
    }
} 