import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CodeBlock as StyledCodeBlock, CodeBlockApplyButton } from './ChatComponents.style';

interface CodeBlockProps {
    code?: string;
    language?: string;
    onApply?: (code: string) => void;
}

const sampleCode = `import clr
clr.AddReference('RevitAPI')
clr.AddReference('RevitServices')
from Autodesk.Revit.DB import FilteredElementCollector, BuiltInCategory, Wall
from RevitServices.Persistence import DocumentManager
from Autodesk.Revit.DB import UnitUtils, DisplayUnitType

# Get the current document
doc = DocumentManager.Instance.CurrentDBDocument

# Define a function to convert feet to internal units
def feet_to_internal(feet):
    return UnitUtils.ConvertToInternalUnits(feet, DisplayUnitType.DUT_DECIMAL_FEET)`;


const stripCode = (code: string) => {
    return code.replace(/```python\n/g, '').replace(/```/g, '');
}

export const CodeBlock: React.FC<CodeBlockProps> = ({ 
    code, 
    language = 'python',
    onApply
}) => {
    if (!code) {
        return null;
    }
    return (
        <StyledCodeBlock>
            <SyntaxHighlighter
                language={language}
                style={oneDark}
                customStyle={{
                    padding: '15px',
                    fontSize: '14px',
                    lineHeight: '1.4',
                }}
            >
                {stripCode(code)}
            </SyntaxHighlighter>
            {onApply && <CodeBlockApplyButton onClick={() => onApply(stripCode(code))}>Apply</CodeBlockApplyButton>}
        </StyledCodeBlock>
    );
};