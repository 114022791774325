import React, { useEffect, useState } from 'react';
import { NodeProps } from 'reactflow';
import { CustomNodeData } from '../types/nodes';
import { useWorkflowStore } from '../../../stores/workflowStore';
import { NodeWrapper, StyledHandle } from '../Node.styles';
import { Position } from 'reactflow';
import styled from 'styled-components';
import InProgressEffect from '../../../ui-components/InProgressEffect';

const ErrorMessage = styled.div`
  font-size: 0.875rem;
  color: #ef4444;
  margin-top: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: #fef2f2;
  border-radius: 0.375rem;
`;

const HandleContainer = styled.div<{ isCollapsed?: boolean }>`
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isCollapsed ? 'flex-start' : 'space-around'};
  align-items: flex-start;
  height: 100%;
  padding: ${props => props.isCollapsed ? '8px 0' : '20px 0'};
  gap: 8px;
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  padding: 4px 0;
`;

const HandleLabel = styled.span`
  font-size: 11px;
  color: #666;
  background: white;
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  white-space: nowrap;
  position: absolute;
  left: 20px;
  transform: translateY(-50%);
  top: 50%;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
`;

const InputsSection = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #f9fafb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
`;

const InputsList = styled.ul`
  margin: 0.25rem 0 0 0;
  padding-left: 1.25rem;
  color: #4b5563;
`;

interface BaseNodeProps extends NodeProps<CustomNodeData> {
  children: React.ReactNode;
  showSourceHandle?: boolean;
  showTargetHandle?: boolean;
  sourceHandles?: Array<{ id: string; name: string }>;
  isCollapsed?: boolean;
}

export function BaseNode({ 
  id,
  data,
  selected,
  children,
  showSourceHandle = true,
  showTargetHandle = true,
  sourceHandles = [],
  xPos,
  yPos,
  zIndex,
  isCollapsed = false,
}: BaseNodeProps) {
  const [error, setError] = useState<string | null>(null);
  const previousErrors = useWorkflowStore(state => state.previousErrors);
  const { activeId: activeWorkflow, whichWorkflowIsRunning, nodeExecuting, available_variables } = useWorkflowStore();

  useEffect(() => {
    // Check if there are previous errors for the active workflow
    console.log("previousErrors", previousErrors);
    if (previousErrors[activeWorkflow] && previousErrors[activeWorkflow].nodeErrors[id]) {
      setError(previousErrors[activeWorkflow].nodeErrors[id]);
    } else {
      setError(null);
    }
  }, [id, previousErrors, activeWorkflow]);

  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeWorkflow}
      executingNodeId={nodeExecuting}
    >
      <NodeWrapper
        id={id}
        type={data.type}
        data={data}
        selected={selected || false}
        isConnectable={true}
        dragging={false}
        className={error ? 'error' : ''}
        xPos={xPos}
        yPos={yPos}
        zIndex={zIndex}
      >
        {showTargetHandle && (
          <StyledHandle
            type="target"
            position={Position.Left}
          />
        )}
        
        {children}
        
        {error && (
          <ErrorMessage>
            {error}
          </ErrorMessage>
        )}

        {selected && available_variables && available_variables.length > 0 && (
          <InputsSection>
            <strong>Available Inputs:</strong>
            <InputsList>
              {available_variables.map((variable, index) => (
                <li key={index}>{variable}</li>
              ))}
            </InputsList>
          </InputsSection>
        )}

        {showSourceHandle && (
          data.type === 'branch' ? (
            <HandleContainer isCollapsed={isCollapsed}>
              {sourceHandles.map((sourceHandle, index) => (
                <HandleWrapper key={sourceHandle.id}>
                  <StyledHandle
                    type="source"
                    position={Position.Right}
                    id={sourceHandle.id}
                  />
                  <HandleLabel>{sourceHandle.name}</HandleLabel>
                </HandleWrapper>
              ))}
            </HandleContainer>
          ) : (
            <StyledHandle
              type="source"
              position={Position.Right}
            />
          )
        )}
      </NodeWrapper>
    </InProgressEffect>
  );
} 