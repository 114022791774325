import { Node, Edge } from 'reactflow';
import { CustomNodeData } from '../components/WorkflowPanel/types/nodes';

export interface WorkflowSnapshot {
    nodes: Node<CustomNodeData>[];
    edges: Edge[];
    state: Record<string, any>;
}

export interface WorkflowChangeRecord {
    nodesAdded: Node<CustomNodeData>[];
    nodesRemoved: Node<CustomNodeData>[];
    nodesUpdated: {
        before: Node<CustomNodeData>;
        after: Node<CustomNodeData>;
    }[];
    edgesAdded: Edge[];
    edgesRemoved: Edge[];
    edgesUpdated: {
        before: Edge;
        after: Edge;
    }[];
    stateUpdated: {
        [key: string]: {
            before: any;
            after: any;
        };
    };
}

export class WorkflowChangeLog {
    private baseSnapshot: WorkflowSnapshot;
    private currentSnapshot: WorkflowSnapshot;
    private undoStack: WorkflowChangeRecord[] = [];
    private redoStack: WorkflowChangeRecord[] = [];

    private deepClone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    private areNodesEquivalent(node1: Node, node2: Node): boolean {
        // Create copies without React Flow layout properties
        const node1Copy = { ...node1 };
        const node2Copy = { ...node2 };

        // Remove React Flow layout properties that are added after rendering
        delete node1Copy.width;
        delete node1Copy.height;
        delete node1Copy.selected;
        delete node1Copy.dragging;
        delete node1Copy.resizing;
        delete node2Copy.width;
        delete node2Copy.height;
        delete node2Copy.selected;
        delete node2Copy.dragging;
        delete node2Copy.resizing;

        return JSON.stringify(node1Copy) === JSON.stringify(node2Copy);
    }

    // Constructor
    constructor(baseSnapshot: WorkflowSnapshot) {
        this.baseSnapshot = this.deepClone(baseSnapshot);
        this.currentSnapshot = this.deepClone(baseSnapshot);
    }

    commitNewChange(newSnapshot: WorkflowSnapshot) {
        // Create a deep clone of the new snapshot
        const clonedNewSnapshot = this.deepClone(newSnapshot);
        
        // Calculate changes between current and new snapshot
        const changes = this.compareSnapshots(this.currentSnapshot, clonedNewSnapshot);
        
        // Only commit if there are actual changes
        if (
            changes.nodesAdded.length > 0 ||
            changes.nodesRemoved.length > 0 ||
            changes.nodesUpdated.length > 0 ||
            changes.edgesAdded.length > 0 ||
            changes.edgesRemoved.length > 0 ||
            changes.edgesUpdated.length > 0 ||
            Object.keys(changes.stateUpdated).length > 0
        ) {
            console.log('Committing new change:', {
                nodesAdded: changes.nodesAdded,
                nodesRemoved: changes.nodesRemoved,
                nodesUpdated: changes.nodesUpdated,
                edgesAdded: changes.edgesAdded,
                edgesRemoved: changes.edgesRemoved,
                edgesUpdated: changes.edgesUpdated,
                stateUpdated: changes.stateUpdated

            });
            
            // Add changes to undo stack (changes are already cloned by compareSnapshots)
            this.undoStack.push(changes);
            console.log('Undo stack size after commit:', this.undoStack.length);
            
            // Clear redo stack since we can't redo after a new change
        this.redoStack = [];
            
            // Update current snapshot with the cloned version
            this.currentSnapshot = clonedNewSnapshot;
        } else {
            console.log('No changes detected in commitNewChange');
        }
    }
    
    compareSnapshots(snapshot1: WorkflowSnapshot, snapshot2: WorkflowSnapshot): WorkflowChangeRecord {
        const changeRecord: WorkflowChangeRecord = {
            nodesAdded: [],
            nodesRemoved: [],
            nodesUpdated: [],
            edgesAdded: [],
            edgesRemoved: [],
            edgesUpdated: [],
            stateUpdated: {},
        };
        
        // Create maps for faster lookups
        const nodes1Map = new Map(snapshot1.nodes.map(node => [node.id, node]));
        const nodes2Map = new Map(snapshot2.nodes.map(node => [node.id, node]));
        
        // Find added nodes (nodes in snapshot2 that aren't in snapshot1)
        snapshot2.nodes.forEach(node => {
            if (!nodes1Map.has(node.id)) {
                changeRecord.nodesAdded.push(this.deepClone(node));
            } else {
                // Only check for updates if the node existed before
                const node1 = nodes1Map.get(node.id)!;
                if (!this.areNodesEquivalent(node1, node)) {
                    changeRecord.nodesUpdated.push({
                        before: this.deepClone(node1),
                        after: this.deepClone(node)
                    });
                }
            }
        });
        
        // Find removed nodes (nodes in snapshot1 that aren't in snapshot2)
        snapshot1.nodes.forEach(node => {
            if (!nodes2Map.has(node.id)) {
                changeRecord.nodesRemoved.push(this.deepClone(node));
            }
        });
        
        // Create maps for edges
        const edges1Map = new Map(snapshot1.edges.map(edge => [edge.id, edge]));
        const edges2Map = new Map(snapshot2.edges.map(edge => [edge.id, edge]));
        
        // Find added edges (edges in snapshot2 that aren't in snapshot1)
        snapshot2.edges.forEach(edge => {
            if (!edges1Map.has(edge.id)) {
                changeRecord.edgesAdded.push(this.deepClone(edge));
            } else {
                // Only check for updates if the edge existed before
                const edge1 = edges1Map.get(edge.id)!;
                if (JSON.stringify(edge1) !== JSON.stringify(edge)) {
                    changeRecord.edgesUpdated.push({
                        before: this.deepClone(edge1),
                        after: this.deepClone(edge)
                    });
                }
            }
        });
        
        // Find removed edges (edges in snapshot1 that aren't in snapshot2)
        snapshot1.edges.forEach(edge => {
            if (!edges2Map.has(edge.id)) {
                changeRecord.edgesRemoved.push(this.deepClone(edge));
            }
        });
        
        // Compare state objects
        const state1Keys = Object.keys(snapshot1.state);
        const state2Keys = Object.keys(snapshot2.state);
        
        // Find changed or added state properties
        state2Keys.forEach(key => {
            if (!snapshot1.state[key] || JSON.stringify(snapshot1.state[key]) !== JSON.stringify(snapshot2.state[key])) {
                changeRecord.stateUpdated[key] = {
                    before: snapshot1.state[key] ? this.deepClone(snapshot1.state[key]) : null,
                    after: this.deepClone(snapshot2.state[key])
                };
            }
        });
        
        // Add removed state properties
        state1Keys.forEach(key => {
            if (!snapshot2.state.hasOwnProperty(key)) {
                changeRecord.stateUpdated[key] = {
                    before: this.deepClone(snapshot1.state[key]),
                    after: null
                };
            }
        });
        
        return changeRecord;
    }

    undo(): WorkflowSnapshot | null {
        console.log('Attempting to undo. Stack size:', this.undoStack.length);
        const lastChange = this.undoStack.pop();
        if (!lastChange) {
            console.log('Nothing to undo - stack is empty');
            return null;
        }

        console.log('Undoing change:', {
            nodesAdded: lastChange.nodesAdded,
            nodesRemoved: lastChange.nodesRemoved,
            nodesUpdated: lastChange.nodesUpdated,
            edgesAdded: lastChange.edgesAdded,
            edgesRemoved: lastChange.edgesRemoved,
            edgesUpdated: lastChange.edgesUpdated,
            stateUpdated: lastChange.stateUpdated
        });

        // Create new snapshot by applying reverse changes
        const newSnapshot: WorkflowSnapshot = {
            nodes: [...this.currentSnapshot.nodes],
            edges: [...this.currentSnapshot.edges],
            state: { ...this.currentSnapshot.state }
        };

        // First restore previous versions of updated nodes (before removing/adding nodes)
        const updatedNodeIds = new Set(lastChange.nodesUpdated.map(update => update.before.id));
        newSnapshot.nodes = newSnapshot.nodes.filter(node => !updatedNodeIds.has(node.id));
        newSnapshot.nodes.push(...lastChange.nodesUpdated.map(update => update.before));

        // Then remove added nodes
        const nodeIdsToRemove = new Set(lastChange.nodesAdded.map(node => node.id));
        newSnapshot.nodes = newSnapshot.nodes.filter(node => !nodeIdsToRemove.has(node.id));

        // Finally add back removed nodes
        newSnapshot.nodes.push(...lastChange.nodesRemoved);

        // Remove added edges
        const edgeIdsToRemove = new Set(lastChange.edgesAdded.map(edge => edge.id));
        newSnapshot.edges = newSnapshot.edges.filter(edge => !edgeIdsToRemove.has(edge.id));

        // Add back removed edges
        newSnapshot.edges.push(...lastChange.edgesRemoved);

        // Restore previous versions of updated edges
        const updatedEdgeIds = new Set(lastChange.edgesUpdated.map(update => update.before.id));
        newSnapshot.edges = newSnapshot.edges.filter(edge => !updatedEdgeIds.has(edge.id));
        newSnapshot.edges.push(...lastChange.edgesUpdated.map(update => update.before));

        // Revert state changes
        Object.entries(lastChange.stateUpdated).forEach(([key, update]) => {
            if (update.before === null) {
                // If before value was null, it means the property was added, so remove it
                delete newSnapshot.state[key];
            } else {
                // Otherwise restore the previous value
                newSnapshot.state[key] = update.before;
            }
        });

        // Add to redo stack
        this.redoStack.push(lastChange);

        // Update current snapshot
        this.currentSnapshot = newSnapshot;
        
        return newSnapshot;
    }

    redo(): WorkflowSnapshot | null {
        const nextChange = this.redoStack.pop();
        if (!nextChange) {
            return null;
        }

        // Create new snapshot by applying forward changes
        const newSnapshot: WorkflowSnapshot = {
            nodes: [...this.currentSnapshot.nodes],
            edges: [...this.currentSnapshot.edges],
            state: { ...this.currentSnapshot.state }
        };

        // Add back the nodes that were previously removed
        newSnapshot.nodes.push(...nextChange.nodesAdded);

        // Remove the nodes that were previously restored
        const nodeIdsToRemove = new Set(nextChange.nodesRemoved.map(node => node.id));
        newSnapshot.nodes = newSnapshot.nodes.filter(node => !nodeIdsToRemove.has(node.id));

        // Apply the newer versions of updated nodes
        const updatedNodeIds = new Set(nextChange.nodesUpdated.map(update => update.after.id));
        newSnapshot.nodes = newSnapshot.nodes.filter(node => !updatedNodeIds.has(node.id));
        newSnapshot.nodes.push(...nextChange.nodesUpdated.map(update => update.after));

        // Add back the edges that were previously removed
        newSnapshot.edges.push(...nextChange.edgesAdded);

        // Remove the edges that were previously restored
        const edgeIdsToRemove = new Set(nextChange.edgesRemoved.map(edge => edge.id));
        newSnapshot.edges = newSnapshot.edges.filter(edge => !edgeIdsToRemove.has(edge.id));

        // Apply the newer versions of updated edges
        const updatedEdgeIds = new Set(nextChange.edgesUpdated.map(update => update.after.id));
        newSnapshot.edges = newSnapshot.edges.filter(edge => !updatedEdgeIds.has(edge.id));
        newSnapshot.edges.push(...nextChange.edgesUpdated.map(update => update.after));

        // Reapply state changes
        Object.entries(nextChange.stateUpdated).forEach(([key, update]) => {
            if (update.after === null) {
                // If after value is null, it means the property was removed, so remove it
                delete newSnapshot.state[key];
            } else {
                // Otherwise apply the newer value
                newSnapshot.state[key] = update.after;
            }
        });

        // Add to undo stack so we can undo this redo
        this.undoStack.push(nextChange);

        // Update current snapshot
        this.currentSnapshot = newSnapshot;
        
        return newSnapshot;
    }

    // Add methods to check if undo/redo are available
    canUndo(): boolean {
        return this.undoStack.length > 0;
    }

    canRedo(): boolean {
        return this.redoStack.length > 0;
    }
}
