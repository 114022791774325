import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Position } from 'reactflow';
import { CustomNodeData, UserInput, UserInputNodeData } from '../types/nodes';
import { NodeTitle, StyledHandle, NodeHeader, NodeHeaderLeft, NodeHeaderRight, 
         NodeHeaderButton, NodeBody, NodeFields, NodeTitleInput, NodeFieldLabel } from '../Node.styles';
import { DynamicSvg } from '../../../ui-components/DynamicSvg';
import { useWorkflowStore } from '../../../stores/workflowStore';
import InProgressEffect from '../../../ui-components/InProgressEffect';
import { NodeWrapper } from '../Node.styles';
import { supabase } from '../../../utils/supabase';
import { createSignedUrl } from '../../../utils/supabase';
import { BaseNode } from './BaseNode';
import { debounce } from 'lodash';

const InputContainer = styled.div`
  margin-bottom: 12px;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
  
  &:disabled {
    background-color: #f5f5f5;
    color: #999;
  }
`;

const StyledCheckbox = styled.input`
  margin-right: 4px;
`;

const AddButton = styled(NodeHeaderButton)`
  color: #3C94D6;
  padding: 4px 8px;
  font-size: 12px;
`;

const FinishButton = styled.button`
  background: #3C94D6;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 8px;

  &:hover {
    background: #2d7ab8;
  }
`;

const DeleteButton = styled(NodeHeaderButton)`
  color: #FF0000;
  &:hover {
    background-color: rgba(255, 0, 0, 0.1);
  }
`;

const FilePreviewButton = styled.button`
  background: transparent;
  border: 1px solid #3C94D6;
  color: #3C94D6;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    background: rgba(60, 148, 214, 0.1);
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileUploadButton = styled.button`
  background: #3C94D6;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: #2d7ab8;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const FileDeleteButton = styled(DeleteButton)`
  padding: 2px;
  min-width: auto;
  margin-left: -4px;
`;

const StyledSelect = styled.select`
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
  min-width: 200px;
  min-height: ${props => props.multiple ? '100px' : 'auto'};
  
  &:disabled {
    background-color: #f5f5f5;
    color: #999;
  }
`;

const MultiSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MultiSelectButton = styled.button`
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
  background: white;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const OptionItem = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #f5f5f5;
  }
`;

const WarningMessage = styled.div`
  color: #f57c00;
  font-size: 12px;
  padding: 8px 12px;
  background: #fff3e0;
  border-radius: 4px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MultiSelect = ({ 
    options, 
    value, 
    onChange, 
    disabled 
  }: {
    options: string[];
    value: string[];
    onChange: (values: string[]) => void;
    disabled?: boolean;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
  
      if (isOpen) {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
      }
    }, [isOpen]);
    
    const toggleOption = (option: string, event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the click from bubbling up
      const newValue = value.includes(option)
        ? value.filter(v => v !== option)
        : [...value, option];
      onChange(newValue);
    };
  
    const handleButtonClick = (event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the click from bubbling up
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };
  
    return (
      <MultiSelectWrapper ref={wrapperRef}>
        <MultiSelectButton 
          onClick={handleButtonClick}
          disabled={disabled}
        >
          <span>{value.length ? `${value.length} selected` : 'Select options'}</span>
          <DynamicSvg 
            name={isOpen ? "ChevronUp" : "ChevronDown"} 
            customIconColor="#7F7F7F" 
            width={16} 
            height={16} 
          />
        </MultiSelectButton>
        
        {isOpen && !disabled && (
          <DropdownList>
            {options.map((option) => (
              <OptionItem 
                key={option} 
                onClick={(e) => toggleOption(option, e)}
              >
                <input
                  type="checkbox"
                  checked={value.includes(option)}
                  onChange={() => {}}
                  style={{ cursor: 'pointer' }}
                />
                {option}
              </OptionItem>
            ))}
          </DropdownList>
        )}
      </MultiSelectWrapper>
    );
  };
  

const inputTypeOptions = [
  { value: 'text', label: 'Text' },
  { value: 'file', label: 'File' },
  { value: 'dropdown_fixed', label: 'Dropdown (Fixed)' },
  { value: 'dropdown_dynamic', label: 'Dropdown (Dynamic)' },
  { value: 'multiselect_fixed', label: 'Multi-Select (Fixed)' },
  { value: 'multiselect_dynamic', label: 'Multi-Select (Dynamic)' }
];

export function UserInputNode({ data, id, selected, xPos, yPos, zIndex }: {
  data: CustomNodeData;
  id: string;
  selected?: boolean;
  xPos: number;
  yPos: number;
  zIndex?: number;
}) {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState(data.label || 'User Input');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { updateNodeData, activeId: activeWorkflow, whichWorkflowIsRunning, nodeExecuting, nodes, available_variables } = useWorkflowStore();
  
  // Get initial values from the workflow store
  const node = nodes.find(n => n.id === id);
  const [inputs, setInputs] = useState<UserInput[]>((node?.data as UserInputNodeData)?.inputs || []);

  // Create a debounced version of updateNodeData
  const debouncedUpdateNodeData = useCallback(
    debounce((key: string, value: any) => {
      updateNodeData(id, key, value);
    }, 500),
    [id, updateNodeData]
  );

  // Sync local state with node data when it changes
  useEffect(() => {
    const currentNode = nodes.find(n => n.id === id);
    if (currentNode?.data) {
      const nodeData = currentNode.data as UserInputNodeData;
      setInputs(nodeData.inputs || []);
    }
  }, [nodes, id]);

  const activeWorkflowIsRunning = whichWorkflowIsRunning == activeWorkflow;
  const thisNodeIsExecuting = nodeExecuting == id;
  const handleAddInput = () => {
    if (activeWorkflowIsRunning) return;
    
    const newInput: UserInput = {
      id: crypto.randomUUID(),
      name: `Input_${inputs.length + 1}`,
      input_type: 'text',
      default_value: '',
      can_edit_during_runtime: true,
    };
    
    const updatedInputs = [...inputs, newInput];
    setInputs(updatedInputs);
    debouncedUpdateNodeData('inputs', updatedInputs);
  };

  const handleRemoveInput = (inputId: string) => {
    if (activeWorkflowIsRunning) return;
    
    if (window.confirm('Are you sure you want to delete this input?')) {
      const updatedInputs = inputs.filter(input => input.id !== inputId);
      setInputs(updatedInputs);
      debouncedUpdateNodeData('inputs', updatedInputs);
    }
  };

  const handleInputChange = (inputId: string, field: keyof UserInput, value: string | boolean | string[]) => {
    const updatedInputs = inputs.map(input => {
      if (input.id === inputId) {
        const updatedInput = { ...input, [field]: value };
        
        // Clear values when switching input types
        if (field === 'input_type') {
          updatedInput.default_value = '';
          updatedInput.runtime_value = '';
          updatedInput.default_file = undefined;
          
          // Clear options/options_variable based on new type
          if ((value as string).includes('_dynamic')) {
            updatedInput.options = undefined;
          } else if ((value as string).includes('_fixed')) {
            updatedInput.options_variable = undefined;
          }
        }
        
        return updatedInput;
      }
      return input;
    });
    
    setInputs(updatedInputs);
    debouncedUpdateNodeData('inputs', updatedInputs);
  };

  const handleFinishInputs = () => {
    updateNodeData(id, 'finish_inputs_button_time', Date.now());
  };

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTitleEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleFileUpload = async (inputId: string, files: FileList | null, isDefault: boolean = true) => {
    if (!files || files.length === 0) return;
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const file = files[0];
      const fileExt = file.name.split('.').pop();
      const timestamp = Date.now();
      const randomId = crypto.randomUUID();
      const fileName = `${timestamp}-${randomId}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      const { error } = await supabase.storage
        .from('chat_docs')
        .upload(filePath, file);

      if (error) {
        console.error('Error uploading file:', error);
        return;
      }

      const signedUrlResponse = await createSignedUrl(filePath);
      const signedUrl = signedUrlResponse.data?.signedUrl || '';

      const fileInfo = {
        fileName: file.name,
        filePath: filePath,
        fileType: file.type,
        signedUrl
      };

      const updatedInputs = inputs.map(input => {
        if (input.id === inputId) {
          if (isDefault) {
            return {
              ...input,
              default_file: fileInfo,
              runtime_file: fileInfo // Pre-fill runtime with default
            };
          } else {
            return {
              ...input,
              runtime_file: fileInfo
            };
          }
        }
        return input;
      });

      setInputs(updatedInputs);
      debouncedUpdateNodeData('inputs', updatedInputs);
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const handlePreviewFile = async (filePath: string) => {
    try {
      const signedUrlResponse = await createSignedUrl(filePath);
      const signedUrl = signedUrlResponse.data?.signedUrl || '';
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  const handleDeleteFile = (inputId: string, isDefault: boolean = true) => {
    if (activeWorkflowIsRunning && !isDefault) {
      // For runtime files, update only the runtime_file
      const updatedInputs = inputs.map(input => {
        if (input.id === inputId) {
          return {
            ...input,
            runtime_file: undefined
          };
        }
        return input;
      });
      setInputs(updatedInputs);
      debouncedUpdateNodeData('inputs', updatedInputs);
    } else if (!activeWorkflowIsRunning && isDefault) {
      // For default files, update both default and runtime
      const updatedInputs = inputs.map(input => {
        if (input.id === inputId) {
          return {
            ...input,
            default_file: undefined,
            runtime_file: undefined
          };
        }
        return input;
      });
      setInputs(updatedInputs);
      debouncedUpdateNodeData('inputs', updatedInputs);
    }
  };

  const renderInputOptions = (input: UserInput) => {
    if (input.input_type.includes('_fixed')) {
      return (
        <InputRow>
          <StyledInput
            type="text"
            value={input.options?.join(',') || ''}
            onChange={(e) => {
              const options = e.target.value.split(',').map(opt => opt.trim());
              handleInputChange(input.id, 'options', options);
            }}
            placeholder="Enter options (comma-separated)"
            disabled={activeWorkflowIsRunning}
          />
        </InputRow>
      );
    } else if (input.input_type.includes('_dynamic')) {
      return (
        <InputRow>
          <StyledInput
            type="text"
            value={input.options_variable || ''}
            onChange={(e) => handleInputChange(input.id, 'options_variable', e.target.value)}
            placeholder="Enter Variable Name"
            disabled={activeWorkflowIsRunning}
          />
        </InputRow>
      );
    }
    return null;
  };

  const renderInputValue = (input: UserInput, isRuntime: boolean) => {
    // Don't show default value field for dynamic inputs
    if (!isRuntime && input.input_type.includes('_dynamic')) {
      return null;
    }

    const value = isRuntime ? input.runtime_value : input.default_value;
    const disabled = isRuntime 
      ? (!input.can_edit_during_runtime || !activeWorkflowIsRunning || !thisNodeIsExecuting)
      : false;
    
    if (input.input_type === 'text') {
      return (
        <StyledInput
          key={`${input.id}-${isRuntime ? 'runtime' : 'default'}`}
          type="text"
          value={value || ''}
          onChange={(e) => handleInputChange(input.id, isRuntime ? 'runtime_value' : 'default_value', e.target.value)}
          placeholder={isRuntime ? "Runtime value" : "Default value"}
          disabled={disabled}
        />
      );
    } else if (input.input_type === 'file') {
      // Re-add file input handling
      const fileInfo = isRuntime ? input.runtime_file : input.default_file;
      return (
        <div>
          {fileInfo ? (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <FilePreviewButton onClick={() => handlePreviewFile(fileInfo.filePath)}>
                <DynamicSvg name="File" customIconColor="#3C94D6" width={16} height={16} />
                {fileInfo.fileName}
              </FilePreviewButton>
              <FileDeleteButton onClick={() => handleDeleteFile(input.id, !isRuntime)}>
                <DynamicSvg name="Delete" customIconColor="#FF0000" width={12} height={12} />
              </FileDeleteButton>
            </div>
          ) : (
            <>
              <FileInput
                type="file"
                id={`file-${input.id}-${isRuntime ? 'runtime' : 'default'}`}
                onChange={(e) => handleFileUpload(input.id, e.target.files, !isRuntime)}
                disabled={disabled}
              />
              <FileUploadButton
                onClick={() => document.getElementById(`file-${input.id}-${isRuntime ? 'runtime' : 'default'}`)?.click()}
                disabled={disabled}
              >
                Upload File
              </FileUploadButton>
            </>
          )}
        </div>
      );
    } else if (input.input_type.includes('dropdown') || input.input_type.includes('multiselect')) {
      const multiple = input.input_type.includes('multiselect');
      
      const currentValues = value ? value.split(',').filter(Boolean) : [];
      
      if (multiple) {
        return (
          <MultiSelect
            options={input.options || []}
            value={currentValues}
            onChange={(selectedValues) => {
              handleInputChange(
                input.id,
                isRuntime ? 'runtime_value' : 'default_value',
                selectedValues.join(',')
              );
            }}
            disabled={disabled}
          />
        );
      }
      
      // Regular dropdown remains the same
      return (
        <StyledSelect
          key={`${input.id}-${isRuntime ? 'runtime' : 'default'}`}
          value={currentValues[0] || ''}
          onChange={(e) => {
            handleInputChange(
              input.id,
              isRuntime ? 'runtime_value' : 'default_value',
              e.target.value
            );
          }}
          disabled={disabled}
        >
          <option value="">Select...</option>
          {input.options?.map((opt, idx) => (
            <option key={`${input.id}-${isRuntime ? 'runtime' : 'default'}-${idx}`} value={opt}>{opt}</option>
          ))}
        </StyledSelect>
      );
    }
    return null;
  };

  const getMissingVariables = () => {
    return inputs
      .filter(input => input.input_type.includes('_dynamic') && input.options_variable)
      .filter(input => !available_variables?.includes(input.options_variable || ''))
      .map(input => input.options_variable)
      .filter(Boolean) as string[];
  };

  return (
      <BaseNode
        id={id}
        type={data.type}
        data={data}
        selected={selected || false}
        isConnectable={true}
        xPos={xPos}
        yPos={yPos}
        zIndex={zIndex || 0}
        dragging={false}
      >

        
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="UserInput" customIconColor="#3C94D6" width={20} height={20} />
            {isTitleEditing ? (
              <NodeTitleInput
                ref={inputRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={() => setIsTitleEditing(false)}
              />
            ) : (
              <NodeTitle onDoubleClick={handleDoubleClick}>{title}</NodeTitle>
            )}
          </NodeHeaderLeft>
          <NodeHeaderRight>
            <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <DynamicSvg name={isCollapsed ? "Plus" : "Minus"} customIconColor="#7F7F7F" width={16} height={16} />
            </NodeHeaderButton>
          </NodeHeaderRight>
        </NodeHeader>

        {selected && getMissingVariables().length > 0 && (
          <WarningMessage>
            <DynamicSvg name="Warning" customIconColor="#f57c00" width={16} height={16} />
            Undefined variables in dynamic inputs: {getMissingVariables().join(', ')}
          </WarningMessage>
        )}

        <NodeBody>
          {!isCollapsed && (
            <NodeFields>
              {inputs.map((input) => (
                <InputContainer key={input.id}>
                  <InputRow>
                    <StyledInput
                      key={`${input.id}-name`}
                      type="text"
                      value={input.name}
                      onChange={(e) => handleInputChange(input.id, 'name', e.target.value)}
                      placeholder="Input name"
                      disabled={activeWorkflowIsRunning}
                    />
                    <select
                      value={input.input_type}
                      onChange={(e) => handleInputChange(input.id, 'input_type', e.target.value)}
                      disabled={activeWorkflowIsRunning}
                    >
                      {inputTypeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {!activeWorkflowIsRunning && (
                      <DeleteButton onClick={() => handleRemoveInput(input.id)}>
                        <DynamicSvg name="Delete" customIconColor="#FF0000" width={16} height={16} />
                      </DeleteButton>
                    )}
                  </InputRow>
                  
                  {renderInputOptions(input)}
                  
                  <InputRow>
                    {renderInputValue(input, activeWorkflowIsRunning)}
                  </InputRow>

                  <InputRow>
                    <StyledCheckbox
                      type="checkbox"
                      checked={input.can_edit_during_runtime}
                      onChange={(e) => handleInputChange(input.id, 'can_edit_during_runtime', e.target.checked)}
                      disabled={activeWorkflowIsRunning}
                    />
                    <NodeFieldLabel>Can edit during workflow run?</NodeFieldLabel>
                  </InputRow>
                </InputContainer>
              ))}

              {!activeWorkflowIsRunning && (
                <AddButton onClick={handleAddInput}>
                  Add Input
                </AddButton>
              )}

              {thisNodeIsExecuting && (
                <FinishButton onClick={handleFinishInputs}>
                  Finish Inputs
                </FinishButton>
              )}
            </NodeFields>
          )}
        </NodeBody>
    </BaseNode>
  );
}
