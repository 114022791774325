import { create } from 'zustand';
import { Node, Edge, Connection, applyNodeChanges, applyEdgeChanges, NodeChange, EdgeChange } from 'reactflow';
import { CustomNodeData } from '../components/WorkflowPanel/types/nodes';
import { createWorkflowExecutionBridge } from '../workflow/WorkflowExecutionBridge';
import { NodeExecutionResult, WorkflowDefinition, WorkflowExecutionResult } from '../workflow/types';
import { supabase } from '../utils/supabase';
import { fetchUserWorkflows, syncWorkflow, createWorkflowSupabase } from '../utils/supabase';
import { hash } from 'crypto';
import { getLangGraphSupabaseClient } from '../utils/langgraphSupabase';
import { WorkflowChangeLog } from '../utils/changeLog';

const RUN_ID_EXPIRATION_TIME = 30000; // 30 seconds
const SYNC_INTERVAL = 3000; // 3 seconds - how often to push changes to DB
const PULL_INTERVAL = 15000; // 5 seconds - how often to pull changes from DB
const SESSION_ID = crypto.randomUUID();


interface WorkflowState {
  // Workflow definitions
  workflows: Record<string, WorkflowDefinition>;
  activeId: string;
  
  // Top-level state for ReactFlow UI
  nodes: Node<CustomNodeData>[];
  edges: Edge[];
  state: Record<string, any>;

  getClient: () => Promise<any>;
  ai_client: any;
  available_variables: string[];

  // Editor state management
  setNodes: (nodes: Node<CustomNodeData>[] | ((nodes: Node<CustomNodeData>[]) => Node<CustomNodeData>[]), id?: string) => void;
  setEdges: (edges: Edge[] | ((edges: Edge[]) => Edge[]), id?: string) => void;
  setGlobalWorkflowState: (workflowState: Record<string, any>, id?: string) => void;
  onNodesChange: (changes: NodeChange[], id?: string) => void;
  onEdgesChange: (changes: EdgeChange[], id?: string) => void;
  onConnect: (connection: Connection, id?: string) => void;
  changeActiveId: (id: string) => void;

  // Runtime state
  runtimeState: Record<string, any>;
  nodeErrors: Record<string, string>;
  runId: string | null;
  runLastAliveTime: number | null;
  nodesToExecute: Node<CustomNodeData>[];
  edgesToExecute: Edge[];
  nodesExecutedResults: NodeExecutionResult[];
  nodeExecuting: string | null;
  


  whichWorkflowIsRunning: string | null;
  
  // Runtime management
  updateRuntimeState: (runtimeState: Record<string, any>, id: string) => boolean;
  addNodesToExecute: (nodes: Node<CustomNodeData>[], id: string) => boolean;
  addEdgesToExecute: (edges: Edge[], id: string) => boolean;
  getNextNodeToExecute: (id: string) => Node<CustomNodeData> | null;
  resetRuntime: () => void;
  
  // Error management
  setNodeError: (nodeId: string, error: string | null) => void;
  getNodeErrors: () => Record<string, string>;
  clearNodeError: (nodeId: string) => void;
  
  // Execution control
  executionBridge: ReturnType<typeof createWorkflowExecutionBridge>;
  startWorkflowExecution: (workflowId: string) => Promise<WorkflowExecutionResult>;
  stopWorkflowExecution: () => void;
  isExecutionLocked: (executionId?: string) => boolean;

  lastSyncTime: number;
  lastPullTime: number; // New field
  pendingChanges: Record<string, boolean>;
  syncIntervalId?: NodeJS.Timeout;
  pullIntervalId?: NodeJS.Timeout; // New field

  initializeWorkflows: () => Promise<void>;
  fetchWorkflows: () => Promise<void>;
  syncWorkflows: () => Promise<void>;

  hashString: (str: string) => Promise<string>;
  grabAvailableVariablesFromPreviousNodes: (id?: string) => Promise<string[]>;

  markWorkflowChanged: (workflowId?: string) => void;
  getPendingChanges: () => Record<string, boolean>;
  createWorkflow: (name: string) => Promise<{workflowId: string, chatId: string} | null>;
  updateWorkflowName: (workflowId: string, name: string) => void;

  updateNodeCode: (nodeId: string, code: string, id?: string) => void;

  reset: (reinitialize?: boolean) => void;

  returnRuntimeState: () => WorkflowExecutionResult;

  updateNodeData: <T>(nodeId: string, key: string, value: T, id?: string) => void;

  setNodeExecuting: (nodeId: string) => void;
  setNodeExecutedResult: (nodeId: string, result: NodeExecutionResult) => void;

  setWhichWorkflowIsRunning: (workflowId: string | null) => void;

  previousErrors: Record<string, { whichWorkflowIsRunning: string | null, nodeErrors: Record<string, string> }>;
  setPreviousErrors: (errors: { whichWorkflowIsRunning: string | null, nodeErrors: Record<string, string> }) => void;

  deleteWorkflowLocally: (workflowId: string) => void;
  addWorkflow: (workflowId: string, workflow: WorkflowDefinition) => void;
  removeEdgeMatchingSourceHandle: (sourceHandle: string) => void;

  // Add new fields for change logs
  changeLogs: Record<string, WorkflowChangeLog>;
  
  // Add undo/redo methods
  undo: () => void;
  redo: () => void;
  
  // Add method to get current changelog
  getCurrentChangeLog: () => WorkflowChangeLog | null;

  // Add new methods
  canUndo: () => boolean;
  canRedo: () => boolean;
}

export const useWorkflowStore = create<WorkflowState>((set, get) => {
  // Call initializeWorkflows immediately after store creation
  setTimeout(() => {
    get().initializeWorkflows();
  }, 0);

  return {
    // Initial state
    workflows: {  },
    activeId: 'default',
    nodes: [],  // Top-level nodes for ReactFlow
    edges: [],  // Top-level edges for ReactFlow
    state: {},  // Top-level state for ReactFlow
    runtimeState: {},
    nodeErrors: {},
    runId: null,
    runLastAliveTime: null,
    nodesToExecute: [],
    edgesToExecute: [],
    lastSyncTime: 0,
    lastPullTime: 0, // Initialize new field
    pendingChanges: {},
    nodesExecutedResults: [],
    nodeExecuting: null,
    whichWorkflowIsRunning: null,
    previousErrors: {},
    ai_client: null,
    available_variables: [],
    changeLogs: {},
    
    getCurrentChangeLog: () => {
      const state = get();
      
      return state.changeLogs[state.activeId];
    },

    undo: () => {
      console.log('Workflow store: attempting undo');
      const changeLog = get().getCurrentChangeLog();
      if (!changeLog) {
        console.log('No changelog available for the current workflow');
        return;
      }

      const newSnapshot = changeLog.undo();
      if (!newSnapshot) {
        console.log('No changes to undo');
        return;
      }

      console.log('Applying undo snapshot:', {
        nodesCount: newSnapshot.nodes.length,
        edgesCount: newSnapshot.edges.length,
        stateKeys: Object.keys(newSnapshot.state)
      });

      const activeId = get().activeId;
      set(state => ({
        workflows: {
          ...state.workflows,
          [activeId]: {
            nodes: newSnapshot.nodes,
            edges: newSnapshot.edges,
            state: newSnapshot.state
          }
        },
        nodes: newSnapshot.nodes,
        edges: newSnapshot.edges,
        state: newSnapshot.state
      }));
    },

    redo: () => {
      console.log('Workflow store: attempting redo');
      const changeLog = get().getCurrentChangeLog();
      if (!changeLog) {
        console.log('No changelog available for the current workflow');
        return;
      }

      const newSnapshot = changeLog.redo();
      if (!newSnapshot) {
        console.log('No changes to redo');
        return;
      }

      console.log('Applying redo snapshot:', {
        nodesCount: newSnapshot.nodes.length,
        edgesCount: newSnapshot.edges.length,
        stateKeys: Object.keys(newSnapshot.state)
      });

      const activeId = get().activeId;
      set(state => ({
        workflows: {
          ...state.workflows,
          [activeId]: {
            nodes: newSnapshot.nodes,
            edges: newSnapshot.edges,
            state: newSnapshot.state
          }
        },
        nodes: newSnapshot.nodes,
        edges: newSnapshot.edges,
        state: newSnapshot.state
      }));
    },

    changeActiveId: (id) => {
      if (id === 'default') {
        set({ activeId: id, nodes: [], edges: [], state: {} });
        return;
      }

      // First update the activeId
      set({ activeId: id });
      
      // Initialize workflow if it doesn't exist
      if (!get().workflows[id]) {
        console.log('Initializing new workflow and changelog for:', id);
        const emptyWorkflow = { nodes: [], edges: [], state: {} };
        set(state => ({
          workflows: {
            ...state.workflows,
            [id]: emptyWorkflow
          },
          // Initialize a new changelog for this workflow
          changeLogs: {
            ...state.changeLogs,
            [id]: new WorkflowChangeLog(emptyWorkflow)
          }
        }));
      } else if (!get().changeLogs[id]) {
        console.log('Initializing changelog for existing workflow:', id);
        set(state => ({
          changeLogs: {
            ...state.changeLogs,
            [id]: new WorkflowChangeLog(state.workflows[id])
          }
        }));
      }
      
      // Update the top level state for ReactFlow UI updates
      const workflow = get().workflows[id];
      if (workflow) {
        // First clear the state
        set({ nodes: [], edges: [], state: {} });
        
        // Then set the new state after a small delay
        setTimeout(() => {
          set({ 
            nodes: workflow.nodes,
            edges: workflow.edges,
            state: workflow.state
          });
        }, 0);
      }
    },

    updateNodeData: (nodeId, key, value, id) => set((state) => {
      const targetId = id || state.activeId;
      if (targetId === 'default') return state;

      const newNodes = state.workflows[targetId].nodes.map(node => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              [key]: value
            }
          };
        }
        return node;
      });

      // Create new snapshot and commit change
      const newSnapshot = {
        nodes: newNodes,
        edges: state.workflows[targetId].edges,
        state: state.workflows[targetId].state
      };
      
      // Initialize changelog if it doesn't exist
      if (!state.changeLogs[targetId]) {
        state.changeLogs[targetId] = new WorkflowChangeLog(state.workflows[targetId]);
      }
      
      state.changeLogs[targetId].commitNewChange(newSnapshot);
      get().markWorkflowChanged(targetId);

      return {
        workflows: {
          ...state.workflows,
          [targetId]: {
            ...state.workflows[targetId],
            nodes: newNodes
          }
        },
        nodes: targetId === state.activeId ? newNodes : state.nodes
      };
    }),

    setNodes: (nodes, id) => {
      const state = get();
      const targetId = id || state.activeId;
      // Skip if targetId is 'default'
      if (targetId === 'default') return;

      const newNodes = typeof nodes === 'function' ? nodes(state.workflows[targetId].nodes) : nodes;
      
      // Create new snapshot and commit change
      const newSnapshot = {
        nodes: newNodes,
        edges: state.workflows[targetId].edges,
        state: state.workflows[targetId].state
      };
      
      // Initialize changelog if it doesn't exist
      if (!state.changeLogs[targetId]) {
        console.log('Initializing changelog for workflow:', targetId);
        state.changeLogs[targetId] = new WorkflowChangeLog(state.workflows[targetId]);
      }
      
      if (state.changeLogs[targetId]) {
        console.log('Committing node changes to changelog');
        state.changeLogs[targetId].commitNewChange(newSnapshot);
      }
      
      get().markWorkflowChanged(targetId);
      
      set(state => ({
        workflows: {
          ...state.workflows,
          [targetId]: {
            ...state.workflows[targetId],
            nodes: newNodes
          }
        },
        // Update top level nodes for ReactFlow
        nodes: targetId === state.activeId ? newNodes : state.nodes
      }));
    },

    setEdges: (edges, id) => {
      const state = get();
      const targetId = id || state.activeId;
      console.log('Setting edges for workflow:', targetId, {
        hasChangelog: !!state.changeLogs[targetId]
      });
      
      if (targetId === 'default') return;

      const newEdges = typeof edges === 'function' ? edges(state.workflows[targetId].edges) : edges;
      
      // Create new snapshot and commit change
      const newSnapshot = {
        nodes: state.workflows[targetId].nodes,
        edges: newEdges,
        state: state.workflows[targetId].state
      };
      
      if (state.changeLogs[targetId]) {
        console.log('Committing edge changes to changelog');
        state.changeLogs[targetId].commitNewChange(newSnapshot);
      } else {
        console.warn('No changelog available for committing edge changes');
      }
      
      get().markWorkflowChanged(targetId);
      
      set(state => ({
        workflows: {
          ...state.workflows,
          [targetId]: newSnapshot
        },
        // Update top level edges for ReactFlow
        edges: targetId === state.activeId ? newEdges : state.edges
      }));
    },

    setGlobalWorkflowState: (workflowState, id) => {
      const state = get();
      const targetId = id || state.activeId;
      // Skip if targetId is 'default'
      if (targetId === 'default') return state;

      // Preserve archilabs_reserved_name unless explicitly set in new state
      const currentState = state.workflows[targetId]?.state || {};
      const newState = {
        ...workflowState,
        name: workflowState.name ?? currentState.name
      };
      
      // Create new snapshot and commit change
      const newSnapshot = {
        nodes: state.workflows[targetId].nodes,
        edges: state.workflows[targetId].edges,
        state: newState
      };
      
      state.changeLogs[targetId]?.commitNewChange(newSnapshot);
      get().markWorkflowChanged(targetId);
      
      return {
        workflows: {
          ...state.workflows,
          [targetId]: newSnapshot
        },
        // Update top level state for ReactFlow
        state: targetId === state.activeId ? newState : state.state
      };
    },

    onNodesChange: (changes, id) => {
      set((state) => {
        const targetId = id || state.activeId;
        // Skip if targetId is 'default'
        if (targetId === 'default') return state;

        const newNodes = applyNodeChanges(changes, state.workflows[targetId].nodes);
        
        // Only commit to changelog if this is a final position change (drag end)
        // or if it's not a position change at all (e.g., selection, deletion)
        const shouldCommitToChangelog = changes.every(change => 
          change.type !== 'position' || // Not a position change
          change.dragging === false    // Or it's the end of a drag operation
        );
        
        if (shouldCommitToChangelog && state.changeLogs[targetId]) {
          console.log('Committing node changes to changelog');
          const newSnapshot = {
            nodes: newNodes,
            edges: state.workflows[targetId].edges,
            state: state.workflows[targetId].state
          };
          state.changeLogs[targetId].commitNewChange(newSnapshot);
        }

        return {
          workflows: {
            ...state.workflows,
            [targetId]: {
              ...state.workflows[targetId],
              nodes: newNodes
            }
          },
          // Update top level nodes for ReactFlow
          nodes: targetId === state.activeId ? newNodes : state.nodes
        };
      });
    },

    onEdgesChange: (changes, id) => {
      set((state) => {
        const targetId = id || state.activeId;
        // Skip if targetId is 'default'
        if (targetId === 'default') return state;

        const newEdges = applyEdgeChanges(changes, state.workflows[targetId].edges);
        
        // Create new snapshot and commit change
        const newSnapshot = {
          nodes: state.workflows[targetId].nodes,
          edges: newEdges,
          state: state.workflows[targetId].state
        };
        
        if (state.changeLogs[targetId]) {
          console.log('Committing edge changes to changelog');
          state.changeLogs[targetId].commitNewChange(newSnapshot);
        }

        return {
          workflows: {
            ...state.workflows,
            [targetId]: {
              ...state.workflows[targetId],
              edges: newEdges
            }
          },
          // Update top level edges for ReactFlow
          edges: targetId === state.activeId ? newEdges : state.edges
        };
      });
    },

    onConnect: (connection: Connection, id?: string) => {
      if (!connection.source || !connection.target) return;

      console.log("Connection:", connection)
      
      set((state) => {
        const targetId = id || state.activeId;
        // Skip if targetId is 'default'
        if (targetId === 'default') return state;

        // Filter out any existing edges with the same sourceHandle
        const filteredEdges = state.workflows[targetId].edges.filter(edge => 
          connection.sourceHandle == null 
            ? edge.source != connection.source
            : edge.sourceHandle != connection.sourceHandle
        );

        // Ensure source and target are strings (not null)
        const newEdge: Edge = {
          id: `e${connection.source}-${connection.target}-${connection.sourceHandle}-${connection.targetHandle}`,
          source: connection.source as string,
          target: connection.target as string,
          sourceHandle: connection.sourceHandle,
          targetHandle: connection.targetHandle,
          type: 'default',
        };

        const newEdges = [...filteredEdges, newEdge];

        // Create new snapshot and commit change
        const newSnapshot = {
          nodes: state.workflows[targetId].nodes,
          edges: newEdges,
          state: state.workflows[targetId].state
        };
        
        if (state.changeLogs[targetId]) {
          console.log('Committing new connection to changelog');
          state.changeLogs[targetId].commitNewChange(newSnapshot);
        }

        get().markWorkflowChanged(targetId);
        return {
          workflows: {
            ...state.workflows,
            [targetId]: {
              ...state.workflows[targetId],
              edges: newEdges
            }
          },
          // Update top level edges for ReactFlow
          edges: targetId === state.activeId ? newEdges : state.edges
        };
      });
    },

    updateRuntimeState: (runtimeState, id) => {
      if (get().isExecutionLocked(id)) {
        return false;
      }
      set({
        runId: id,
        runLastAliveTime: Date.now(),
        runtimeState
      });
      return true;
    },

    addNodesToExecute: (nodes, id) => {
      if (get().isExecutionLocked(id)) {
        return false;
      }
      set(state => ({ 
        runId: id,
        runLastAliveTime: Date.now(),
        nodesToExecute: [...state.nodesToExecute, ...nodes] 
      }));
      return true;
    },

    addEdgesToExecute: (edges, id) => {
      if (get().isExecutionLocked(id)) {
        return false;
      }
      set(state => ({ 
        edgesToExecute: [...state.edgesToExecute, ...edges] 
      }));
      return true;
    },

    hashString: async (str: string) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    },

    getClient: async () => {
      if (!get().ai_client) {
        const client = await getLangGraphSupabaseClient(supabase);
        set({ai_client: client});
        return client.getClient();
      }
      return get().ai_client.getClient();
    },


    grabAvailableVariablesFromPreviousNodes: async (id) => {
      if (id == null) {
        set({available_variables: []});
        return [];
      }
      const {nodes, edges, activeId} = get();
      const visited = new Set<string>();
      const outputNames = new Set<string>();

      const traverseNodes = async (currentId: string) => {
        // Skip if already visited (prevents infinite loops)
        if (visited.has(currentId)) return;
        visited.add(currentId);

        const outgoingEdges = edges.filter(edge => edge.target == currentId);
        
        for (const edge of outgoingEdges) {
          const targetNode = nodes.find(node => node.id == edge.source);
          if (targetNode?.data?.type == 'code') {
            const code = targetNode.data.code;
            const myHash = await get().hashString(code);
            if (myHash == targetNode.data?.ai_code_hash) {
              targetNode.data?.ai_generated_outputs?.forEach(output => outputNames.add(output));
            }
            else{
              try {
                const client = await get().getClient();
                const thread = await client.threads.create();
        
                const stream = client.runs.stream(
                  thread.thread_id,
                  "ai_get_outputs",
                  {
                    input: {
                      code: code,
                    },
                    streamMode: ["values"],
                  }
                );
        
                let ai_request_outputs: string[] = [];
                for await (const chunk of stream) {
                  console.log("OUTPUTS FROM AI", chunk.data);
                  if (chunk.event === "values" && chunk.data?.outputs) {
                    ai_request_outputs = chunk.data.outputs;
                  }
                }
                get().updateNodeData(targetNode.id, 'ai_generated_outputs', ai_request_outputs);
                get().updateNodeData(targetNode.id, 'ai_code_hash', myHash);
                ai_request_outputs.forEach(output => outputNames.add(output));
              } catch (error) {
                console.error("Error analyzing code outputs:", error);
                // Use empty array if analysis fails
                get().updateNodeData(targetNode.id, 'ai_generated_outputs', []);
                get().updateNodeData(targetNode.id, 'ai_code_hash', myHash);
              }
              get().markWorkflowChanged(activeId);
            }
          }
          else if (targetNode?.data?.type == 'select_in_revit') {
            if (targetNode.data.selectionType != "Points") {
              outputNames.add("selectedElementIds")
              outputNames.add("count")
            }
            else {
              outputNames.add("points")
              outputNames.add("count")
            }
          }
          else if (targetNode?.data?.type == 'user_input') {
            targetNode.data.inputs?.forEach(input => outputNames.add(input.name));
          }
          else if (targetNode?.data?.type == 'ai_prompt') {
            targetNode.data.outputs?.forEach(output => outputNames.add(output));
          }
          else if (targetNode?.data?.type == 'branch') {
            //pass
          }
          else if (targetNode?.data?.type == 'start') {
            //pass
          }
          else {
            console.error("Unknown node type", targetNode?.data?.type);
          }
          // Recursively traverse the target node
          await traverseNodes(edge.source);
        }
      };

      // Start traversal from the initial node
      await traverseNodes(id);
      set({available_variables: Array.from(outputNames)});
      console.log("Available variables:", Array.from(outputNames));
      return Array.from(outputNames);
    },

    getNextNodeToExecute: (id) => {
      console.log("Getting next node to execute", id);
      if (get().isExecutionLocked(id)) {
        console.log('Execution locked, skipping node execution');
        return null;
      }
      const { nodesToExecute, runtimeState, edgesToExecute, nodeExecuting } = get();
      
      console.log("NODE EXECUTING", nodeExecuting, nodesToExecute.find(node => node.id === nodeExecuting), runtimeState);
      // If there's a specified next node from branching, prioritize it
      //find the type of the node
      const nodeType = nodesToExecute.find(node => node.id == nodeExecuting)?.data.type;
      if (nodeType == 'branch' && runtimeState.archilabs_reserved_branch_source_handle_id) {
        const sourceHandleId = runtimeState.archilabs_reserved_branch_source_handle_id;
        
        // Find edge that matches the source handle
        const matchingEdge = edgesToExecute.find(edge => edge.sourceHandle == sourceHandleId);
        console.log("Matching edge", matchingEdge);
        if (matchingEdge) {
          const nextNodeId = matchingEdge.target;
          console.log("Found next node from branch:", nextNodeId);
          
          // Clear the branch directive after using it
          set(state => ({
            runtimeState: {
              ...state.runtimeState,
              archilabs_reserved_branch_source_handle_id: undefined
            }
          }));
          

          const nextNode = nodesToExecute.find(node => node.id == nextNodeId);
          if (nextNode) {
            return nextNode;
          }
        }
      }

      // If there's a currently executing node, find its outgoing edges
      if (nodeExecuting) {
        const outgoingEdges = edgesToExecute.filter(edge => edge.source == nodeExecuting);
        if (outgoingEdges.length > 0) {
          // Get the target node ID from the first outgoing edge
          const nextNodeId = outgoingEdges[0].target;
          const nextNode = nodesToExecute.find(node => node.id == nextNodeId);
          if (nextNode) {
            return nextNode;
          }
        }
      }
      
      if (nodesToExecute.find(node => node.id == "archilabs_reserved_validation_node") && nodeExecuting != "archilabs_reserved_validation_node") {
        return nodesToExecute.find(node => node.id == "archilabs_reserved_validation_node") || null;
      }

      // Default behavior: get next node from queue if no connected nodes found
      return null;
    },

    resetRuntime: () => {
      const currentState = get();
      if (currentState.whichWorkflowIsRunning) {
        get().setPreviousErrors({
          whichWorkflowIsRunning: currentState.whichWorkflowIsRunning,
          nodeErrors: currentState.nodeErrors
        });
      }
      set({ 
        runId: null, 
        runLastAliveTime: null, 
        nodesToExecute: [], 
        edgesToExecute: [],
        runtimeState: {},
        nodeErrors: {},
        nodesExecutedResults: [],
        nodeExecuting: null
      });
    },

    setWhichWorkflowIsRunning: (workflowId: string | null) => set({ whichWorkflowIsRunning: workflowId }),

    returnRuntimeState: () => ({
      runtimeState: get().runtimeState,
      nodeErrors: get().nodeErrors,
      runId: get().runId,
      runLastAliveTime: get().runLastAliveTime,
      nodesToExecute: get().nodesToExecute,  // Add this line
      edgesToExecute: get().edgesToExecute,
      nodesExecutedResults: get().nodesExecutedResults,
      nodeExecuting: get().nodeExecuting
    }),
    

    setNodeError: (nodeId, error) => set(store => {
      console.log('Setting node error:', { nodeId, error });
      return {
        nodeErrors: error === null
          ? Object.fromEntries(Object.entries(store.nodeErrors).filter(([id]) => id !== nodeId))
          : { ...store.nodeErrors, [nodeId]: error }
      };
    }),

    setNodeExecuting: (nodeId) => set({ nodeExecuting: nodeId }),

    setNodeExecutedResult: (nodeId: string, result: NodeExecutionResult) => 
      set({ nodesExecutedResults: [...get().nodesExecutedResults, { ...result, nodeId }] }),

    getNodeErrors: () => get().nodeErrors,

    clearNodeError: (nodeId) => set(store => ({
      nodeErrors: Object.fromEntries(Object.entries(store.nodeErrors).filter(([id]) => id !== nodeId))
    })),

    executionBridge: createWorkflowExecutionBridge(),

    startWorkflowExecution: async (workflowId: string) => {
      try {
        console.log('Starting workflow execution:', workflowId);
        const result = await get().executionBridge.startExecution(60, workflowId);
        if (!result) {
          throw new Error('Workflow execution failed: No result returned');
        }
        return result;
      } catch (error) {
        console.error('Workflow execution failed:', error);
        get().resetRuntime();
        throw error; // Re-throw the error to maintain the Promise rejection
      }
    },

    stopWorkflowExecution: () => {
      get().executionBridge.stop();
    },

    createWorkflow: async (name: string) => {
      const { data, error } = await createWorkflowSupabase(SESSION_ID, name);
      if (error) {
        console.error('Failed to create workflow:', error);
        return null;
      }
      console.log('Workflow created:', data);

      if (!data) return null;
      const workflow = data.workflow;
      const chat = data.chat;

      const initialWorkflow = {
        nodes: [],
        edges: [],
        state: {
          name: name
        },
        updated_at: workflow.updated_at
      };

      // Add the new workflow to state with the name and initialize its changelog
      set(state => ({
        workflows: {
          ...state.workflows,
          [workflow.id]: initialWorkflow
        },
        changeLogs: {
          ...state.changeLogs,
          [workflow.id]: new WorkflowChangeLog(initialWorkflow)
        }
      }));

      return {"workflowId": String(workflow.id), "chatId": String(chat.id)};
    },

    updateWorkflowName: (workflowId: string, name: string) => set(state => {
      const workflow = state.workflows[workflowId];
      if (!workflow) return state;

      const updatedWorkflow = {
        ...workflow,
        state: {
          ...workflow.state,
          name
        }
      };

      // Mark workflow for sync regardless of active status
      get().markWorkflowChanged(workflowId);

      return {
        workflows: {
          ...state.workflows,
          [workflowId]: updatedWorkflow
        },
        // Only update top-level state if this is the active workflow
        ...(workflowId === state.activeId ? {
          state: updatedWorkflow.state
        } : {})
      };
    }),

    isExecutionLocked: (executionId?: string) => {
      const state = get();
      if (state.runId === null) {
        return false;
      }
      
      if (executionId && state.runId === executionId) {
        return false;
      }

      return state.runLastAliveTime! + RUN_ID_EXPIRATION_TIME > Date.now();
    },

    initializeWorkflows: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Initial load
      await get().fetchWorkflows();

      // Set up separate intervals for fetch and sync
      const pullIntervalId = setInterval(() => {
        get().fetchWorkflows();
      }, PULL_INTERVAL);

      const syncIntervalId = setInterval(() => {
        get().syncWorkflows();
      }, SYNC_INTERVAL);

      set({ 
        syncIntervalId: syncIntervalId,
        pullIntervalId: pullIntervalId
      });
    },

    fetchWorkflows: async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const globalWorkflow = urlParams.get('globalView') === 'true';

      const { data: workflows, error } = await fetchUserWorkflows(SESSION_ID, get().lastPullTime, globalWorkflow);
      if (error || !workflows) return;

      set(state => {
        const updatedWorkflows = { ...state.workflows };
        const updatedChangeLogs = { ...state.changeLogs };
        
        workflows.forEach(workflow => {
          const existingWorkflow = updatedWorkflows[workflow.id];
          var newData;
          try{
            newData = JSON.parse(workflow.workflow_data);
          } catch (e) {
            console.error('Error parsing workflow data:', workflow.workflow_data);
            return;
          }
          
          if (!existingWorkflow?.updated_at || 
              !workflow.updated_at || 
              new Date(workflow.updated_at) > new Date(existingWorkflow.updated_at)) {
            
            const workflowData = {
              ...newData,
              is_shared: workflow.is_shared,
              updated_at: workflow.updated_at || new Date().toISOString()
            };
            
            // Only reinitialize changelog if the workflow data has actually changed
            if (JSON.stringify(workflowData) !== JSON.stringify(existingWorkflow)) {
              updatedWorkflows[workflow.id] = workflowData;
              updatedChangeLogs[workflow.id] = new WorkflowChangeLog(workflowData);
            }
          }
        });

        return { 
          workflows: updatedWorkflows,
          changeLogs: updatedChangeLogs,
          lastPullTime: Date.now()
        };
      });
    },

    syncWorkflows: async () => {
      const state = get();
      const now = Date.now();
      
      // Safety check: Get all pending workflows
      const pendingWorkflows = Object.entries(state.pendingChanges)
        .filter(([_, isPending]) => isPending)
        .map(([workflowId]) => state.workflows[workflowId]);

      // Count how many pending workflows are empty
      const emptyWorkflowCount = pendingWorkflows.filter(workflow => 
        !workflow || 
        ((!workflow.nodes || workflow.nodes.length === 0) && 
         (!workflow.edges || workflow.edges.length === 0)) &&
         (!workflow.state || Object.keys(workflow.state).length === 0)
      ).length;

      // Original sync logic
      const updates = Object.entries(state.pendingChanges)
        .filter(([_, isPending]) => isPending)
        .map(async ([workflowId]) => {
          const workflow = state.workflows[workflowId];
          if (workflow.is_shared) {
            return;
          }
          if (emptyWorkflowCount >= 2 && 
            (!workflow || 
            (!workflow.nodes || workflow.nodes.length === 0) && 
            (!workflow.edges || workflow.edges.length === 0)) &&
            (!workflow.state || Object.keys(workflow.state).length === 0))
          {
            return;
          }
          const workflowData = {
            nodes: workflow.nodes,
            edges: workflow.edges,
            state: workflow.state
          };
          return syncWorkflow(workflowId, workflowData, SESSION_ID);
        });

      await Promise.all(updates);

      set({
        lastSyncTime: now,
        pendingChanges: {}
      });
    },

    markWorkflowChanged: (workflowId?: string) => set(state => ({
      pendingChanges: {
        ...state.pendingChanges,
        [workflowId || state.activeId]: true
      }
    })),

    getPendingChanges: () => get().pendingChanges,

    updateNodeCode: (nodeId: string, code: string, id?: string) => set((state) => {
      const targetId = id || state.activeId;
      // Skip if targetId is 'default'
      if (targetId === 'default') return state;

      const newNodes = state.workflows[targetId].nodes.map(node => {
        if (node.id === nodeId && node.data.type === 'code') {
          return {
            ...node,
            data: {
              ...node.data,
              code
            }
          };
        }
        return node;
      });

      get().markWorkflowChanged(targetId);

      return {
        workflows: {
          ...state.workflows,
          [targetId]: {
            ...state.workflows[targetId],
            nodes: newNodes
          }
        },
        // Update top level nodes for ReactFlow
        nodes: targetId === state.activeId ? newNodes : state.nodes
      };
    }),

    reset: (reinitialize = false) => {
      const state = get();
      if (state.syncIntervalId) {
        clearInterval(state.syncIntervalId);
      }
      if (state.pullIntervalId) {
        clearInterval(state.pullIntervalId);
      }

      set({
        workflows: {},
        activeId: 'default',
        nodes: [],
        edges: [],
        state: {},
        runtimeState: {},
        nodeErrors: {},
        runId: null,
        runLastAliveTime: null,
        nodesToExecute: [],
        edgesToExecute: [],
        lastSyncTime: 0,
        lastPullTime: 0,
        pendingChanges: {},
        syncIntervalId: undefined,
        pullIntervalId: undefined,
        whichWorkflowIsRunning: null,
        nodesExecutedResults: [],
        nodeExecuting: null,
        previousErrors: {},
        ai_client: null,
        available_variables: [],
        changeLogs: {}
      });

      if (reinitialize) {
        setTimeout(() => {
          get().initializeWorkflows();
        }, 0);
      }
    },

    setPreviousErrors: (errors) => set((state) => ({
      previousErrors: {
        ...state.previousErrors,
        [errors.whichWorkflowIsRunning || 'default']: errors
      }
    })),

    deleteWorkflowLocally: (workflowId: string) => set(state => {
      // Don't allow deleting the default workflow
      if (workflowId === 'default') return state;

      // Create new workflows object without the deleted workflow
      const { [workflowId]: deletedWorkflow, ...remainingWorkflows } = state.workflows;
      const { [workflowId]: deletedChangeLog, ...remainingChangeLogs } = state.changeLogs;

      return {
        workflows: remainingWorkflows,
        changeLogs: remainingChangeLogs
      };
    }),


    addWorkflow: (workflowId: string, workflow: WorkflowDefinition) => set(state => ({
      workflows: {
        ...state.workflows,
        [workflowId]: workflow
      }
    })),

    removeEdgeMatchingSourceHandle: (sourceHandle: string) => set(state => {
      console.log("Removing edge with sourceHandle:", sourceHandle);
      const filteredEdges = state.edges.filter(edge => edge.sourceHandle != sourceHandle);
      console.log(filteredEdges);
      
      return {
        workflows: {
          ...state.workflows,
          [state.activeId]: {
            ...state.workflows[state.activeId],
            edges: filteredEdges
          }
        },
        edges: filteredEdges
      };
    }),

    canUndo: () => {
      const changeLog = get().getCurrentChangeLog();
      const result = changeLog?.canUndo() || false;
      console.log('Can undo:', result, 'for workflow:', get().activeId);
      return result;
    },

    canRedo: () => {
      const changeLog = get().getCurrentChangeLog();
      const result = changeLog?.canRedo() || false;
      console.log('Can redo:', result, 'for workflow:', get().activeId);
      return result;
    },
  };
});