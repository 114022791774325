import { Socket } from 'socket.io-client';
import { Node, Edge } from 'reactflow';
import { CustomNodeData } from '../components/WorkflowPanel/types/nodes';
import { SupabaseSocketWrapper } from '../stores/socketStore';

// This is for running arbitrary python code in Revit
export const runCodeInRevit = (socket: SupabaseSocketWrapper, code: string, readOnly: boolean): Promise<any> => {
    console.log("THIS IS AN OUTDATED FUNCTION AND NO LONGER IN USE")
    return Promise.resolve(null);
}

// This is a newer implementation for running python functions in Revit as part of a workflow
interface RevitFunctionResponse {
    projectId?: string;
    result: any;
    output: string | null;
    error: string | null;
    success: boolean;
    readOnly: boolean;
    timestamp: number;
}

export const runFunctionInRevit = (socket: SupabaseSocketWrapper, code: string, state: Record<string, any>, readOnly: boolean): Promise<RevitFunctionResponse> => {
    return new Promise((resolve) => {
        socket.emit('runFunctionFromFrontend', {
            functionCode: code,
            state: state,
            readOnly: readOnly,
        });
        socket.on('revitFunctionResponse', (data) => {
            resolve(data);
            socket.off('revitFunctionResponse', resolve);
        });
    });
}

export const startTransactionGroup = (socket: SupabaseSocketWrapper): Promise<any> => {
    return new Promise((resolve) => {
        socket.emit('startTransactionGroup', {
            startCommit: true,
        });
        resolve(null);
    });
}

export const endTransactionGroup = (socket: SupabaseSocketWrapper, commit: boolean): Promise<any> => {
    return new Promise((resolve) => {
        socket.emit('endTransactionGroup', {
            commit: commit,
        });
        resolve(null);
    });
}

export const abortTransactionGroup = (socket: SupabaseSocketWrapper) => {
    socket.emit('abortTransactionGroup', {
        abort: true,
    });
}

export const runWorkflowInRevit = async (socket: SupabaseSocketWrapper, nodes: Node<CustomNodeData>[], edges: Edge[], commit: boolean): Promise<any> => {
    console.log("THIS IS AN OUTDATED FUNCTION AND NO LONGER IN USE")
    return null;
}

export const captureActiveView = (socket: SupabaseSocketWrapper): Promise<any> => {
    return new Promise((resolve) => {
        socket.emit('viewCaptureRequest', {
            captureCurrentView: true,
        });
        socket.on('viewCaptureResponse', (data) => {
            if (data.imageData) {
                const dataUrl = `data:image/png;base64,${data.imageData}`;
                resolve(dataUrl);
            }
            resolve(null);
            // Remove the listener after receiving the response
            socket.off('viewCaptureResponse', resolve);
        });
    });
}
