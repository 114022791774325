import { createClient } from '@supabase/supabase-js';
import { SavedWorkflow } from '../types/workflow';
import { ChatMessage, ThinkingState } from '../types/message';
import { useEditorStore } from '../stores/editorStore';
import { useWorkflowStore } from '../stores/workflowStore';
import { useSocketStore } from '../stores/socketStore';
import { sanitizeWorkflowData } from './flowHelpers';


export interface User {
    id: string; // uuid from org_users table
    created_at: string;
    organization_id: string; // uuid from org_users table
    name?: string; // from auth.users
    email?: string; // from auth.users
}

export interface Invite {
    id: number; // bigint from invites table
    created_at: string;
    organization_id: string; // uuid reference to organizations
    email: string;
    claim_key_encoded: string;
    claimed: boolean;
    status?: 'pending' | 'claimed'; // Derived from claimed boolean
}

// Initialize Supabase client

console.log(process.env.REACT_APP_SUPABASE_URL);
console.log(process.env.REACT_APP_SUPABASE_ANON_KEY);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '';

export const supabase = createClient(supabaseUrl, supabaseKey);

// Auth helpers
export const signInWithEmail = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
    });
    
    if (!error) {
        // First reset all stores
        useSocketStore.getState().reset(false);
        useWorkflowStore.getState().reset(false);
        useEditorStore.getState().reset(false);

        await new Promise(resolve => setTimeout(resolve, 1000)); // Give workflow time to initialize
        
        // Then initialize them in the correct order
        useSocketStore.getState().reset(true);
        await new Promise(resolve => setTimeout(resolve, 300)); // Give socket time to initialize
        useWorkflowStore.getState().reset(true);
        await new Promise(resolve => setTimeout(resolve, 300)); // Give workflow time to initialize
        useEditorStore.getState().reset(true);
    }
    
    return { data, error };
};

interface SignUpParams {
  email: string;
  password: string;
  inviteCode?: string;
}

export const signUp = async (params: SignUpParams) => {
    const { email, password } = params;
    
    console.log("signup params", params, email, password);
    // First create the user account
    const { data, error } = await supabase.auth.signUp({
        email:  email,
        password: password,
    });
    
    return { data, error };
};


export const getIntercomSecret = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user');

  const { data: orgUser, error } = await supabase
    .from('org_users')
    .select('encrypted_intercom_secret')
    .eq('id', user.id)
    .single();

  // Convert user.id to encryption key using Web Crypto API
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(user.id),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );

  const key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: encoder.encode('salt'),
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt']
  );

  if (!orgUser?.encrypted_intercom_secret) {
    // Generate new intercom secret via edge function
    const { data: intercomData, error: intercomError } = await supabase.functions.invoke('intercom-key');
    console.log("intercomData", intercomData, intercomError);
    if (intercomError) throw new Error('Failed to generate Intercom secret');

    // Encrypt the intercom secret
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      encoder.encode(intercomData.hash)
    );

    // Convert to string for storage
    const encryptedString = JSON.stringify({
      iv: Array.from(iv),
      data: Array.from(new Uint8Array(encrypted))
    });

    // Store encrypted secret in database
    const { error: updateError } = await supabase
      .from('org_users')
      .update({ encrypted_intercom_secret: encryptedString })
      .eq('id', user.id);

    if (updateError) throw new Error('Failed to store Intercom secret');
    
    return intercomData.hash;
  }

  // Decrypt existing key
  try {
    const { iv, data } = JSON.parse(orgUser.encrypted_intercom_secret);
    const decrypted = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: new Uint8Array(iv) },
      key,
      new Uint8Array(data)
    );

    return new TextDecoder().decode(decrypted);
  } catch (e) {
    throw new Error('Failed to decrypt Intercom secret');
  }
};




export const getSecretKey = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user');

  const { data: orgUser, error } = await supabase
    .from('org_users')
    .select('encrypted_secret_key')
    .eq('id', user.id)
    .single();

  console.log("orgUser", orgUser, error);

  // Convert user.id to encryption key using Web Crypto API
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(user.id),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );

  const key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: encoder.encode('salt'),
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt']
  );

  // If no secret key exists, generate and store one
  if (!orgUser?.encrypted_secret_key) {
    // Generate random bytes
    const newSecretKey = Array.from(crypto.getRandomValues(new Uint8Array(32)))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
    
    // Encrypt the new secret key
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      encoder.encode(newSecretKey)
    );

    // Convert to string for storage
    const encryptedString = JSON.stringify({
      iv: Array.from(iv),
      data: Array.from(new Uint8Array(encrypted))
    });

    // Store in database
    const { error: updateError } = await supabase
      .from('org_users')
      .update({ encrypted_secret_key: encryptedString })
      .eq('id', user.id);

    if (updateError) throw new Error('Failed to store secret key');
    
    return newSecretKey;
  }

  // Decrypt existing key
  try {
    const { iv, data } = JSON.parse(orgUser.encrypted_secret_key);
    const decrypted = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: new Uint8Array(iv) },
      key,
      new Uint8Array(data)
    );

    return new TextDecoder().decode(decrypted);
  } catch (e) {
    throw new Error('Failed to decrypt secret key');
  }
};


export const claimInvite = async (inviteCode: string) => {

    const { data, error } = await supabase.functions.invoke('claim-invite', {
        body: { invite_code: inviteCode }
    });
    return { data, error };
};

export const signOut = async () => {
    // Reset stores without reinitialization before signing out
    useWorkflowStore.getState().reset(false);
    useEditorStore.getState().reset(false);
    useSocketStore.getState().reset(false);
    
    const { error } = await supabase.auth.signOut();
    return { error };
};

export const getUsers = async () => {
    const organizationId = await getOrganizationId();
    if (!organizationId) return { data: [], error: 'No organization id found' };
    
    const { data, error } = await supabase
        .from('org_users')
        .select('*')
        .eq('organization_id', organizationId);

    return { data: data as User[] || [], error };
};

// Storage and table write helpers
export const uploadWorkflow = async (
    workflowData: SavedWorkflow, 
    userId: string,
    file?: File
) => {
    // First upload file to storage if provided
    let fileUrl = '';
    if (file) {
        const { data: storageData, error: storageError } = await supabase.storage
            .from('workflows')
            .upload(`${userId}/${file.name}`, file);
            
        if (storageError) return { error: storageError };
        fileUrl = storageData.path;
    }

    // Then write workflow data to table
    const { data, error } = await supabase
        .from('workflows')
        .insert([{
            ...workflowData,
            user_id: userId,
            file_url: fileUrl
        }])
        .select()
        .single();

    return { data, error };
};

// Read helpers
export const getWorkflowsWithWorksheets = async (userId: string) => {
    const { data, error } = await supabase
        .from('workflows')
        .select(`
            *,
            worksheets (*)
        `)
        .eq('user_id', userId);

    return { data, error };
};

export const getSingleWorkflowWithWorksheets = async (workflowId: number) => {
    const { data, error } = await supabase
        .from('workflows')
        .select(`
            *,
            worksheets (*)
        `)
        .eq('id', workflowId)
        .single();

    return { data, error };
};


export const getOrganizationId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return null;


    console.log("user", user);
    
    const { data, error } = await supabase
        .from('org_users')
        .select('organization_id')
        .eq('id', user.id)
        .single();

    console.log("userdata", data);
        
    return data?.organization_id;
};

// Invite helpers
export const getInvites = async () => {
    const organizationId = await getOrganizationId();

    const { data, error } = await supabase
        .from('invites')
        .select('*')
        .eq('organization_id', organizationId)
        .eq('claimed', false);

    return { data: data as Invite[] || [], error };
};

export const createInvite = async (email: string) => {
    console.log("current user", supabase.auth.getUser());
    const { data, error } = await supabase.functions.invoke('send-invite', {
        body: { email }
    });
    console.log("createInvite", data, error);
    return { data, error };
};

export const acceptInvite = async (inviteId: number) => {
    const { data, error } = await supabase.functions.invoke('claim-invite', {
        body: { inviteId }
    });
    return { data, error };
};

export const deleteInvite = async (inviteId: number) => {
    const { data, error } = await supabase.from('invites').delete().eq('id', inviteId);
    return { data, error };
};

export const uploadCustomerDocument = async (file: File, customerId: string) => {
    // Upload file to storage bucket
    const fileExt = file.name.split('.').pop();
    const timestamp = Date.now();
    const randomId = crypto.randomUUID();
    const fileName = `${timestamp}-${randomId}.${fileExt}`;
    const filePath = `${customerId}/${fileName}`;

    const { data: uploadData, error: uploadError } = await supabase.storage
        .from('customer_documents')
        .upload(filePath, file);

    if (uploadError) {
        return { error: uploadError };
    }

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
        .from('customer_documents')
        .getPublicUrl(filePath);

    // Update documents table
    const { data: documentData, error: documentError } = await supabase
        .from('customer_documents')
        .insert([{
            customer_id: customerId,
            file_name: fileName,
            file_path: filePath,
            public_url: publicUrl,
            file_type: fileExt,
            file_size: file.size
        }])
        .select()
        .single();

    if (documentError) {
        return { error: documentError };
    }

    return { data: documentData };
};

export const getCustomerDocuments = async () => {

    const { data, error } = await supabase
        .from('customer_documents')
        .select('*')
        
    console.log("dat", data, error);
    return { data, error };
};

export interface WorkflowData {
  nodes: any[];
  edges: any[];
  state: Record<string, any>;
}



export const createWorkflowSupabase = async (sessionId: string, name: string) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { data: null, error: 'No authenticated user' };

  // Start a Supabase transaction
  const { data: workflow, error: workflowError } = await supabase
    .from('workflows')
    .insert([{
      user_id: user.id,
      session_id: sessionId,
      workflow_data: JSON.stringify({
        nodes: [],
        edges: [],
        state: {
          name: name
        }
      })
    }])
    .select()
    .single();

  if (workflowError) return { data: null, error: workflowError };

  // Create associated chat
  const { data: chat, error: chatError } = await supabase
    .from('chats')
    .insert([{
      user_id: user.id,
      workflow_id: workflow.id,
      session_id: sessionId,
      messages: JSON.stringify([]),
      is_default: true
    }])
    .select()
    .single();

  if (chatError) return { data: null, error: chatError };

  return { data: { workflow, chat }, error: null };
};

export const createChatSupabase = async (sessionId: string, workflowId: string, isDefault: boolean = false) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { data: null, error: 'No authenticated user' };

  const { data, error } = await supabase.from('chats').insert([{
    user_id: user.id,
    workflow_id: workflowId,
    session_id: sessionId,
    messages: JSON.stringify([]),
    is_default: isDefault
  }]).select().single();

  return data?.id;
}

// Add these new workflow-related functions
export const fetchUserWorkflows = async (sessionId: string, lastSyncTime: number, globalWorkflow: boolean = false) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { data: null, error: 'No authenticated user' };

  // Get organization ID
  const { data: orgUser } = await supabase
    .from('org_users')
    .select('organization_id')
    .eq('id', user.id)
    .single();

  if (!orgUser?.organization_id) return { data: null, error: 'No organization found' };

  // Get all user IDs in the same organization
  const { data: orgUsers } = await supabase
    .from('org_users')
    .select('id')
    .eq('organization_id', orgUser.organization_id);

  const orgUserIds = orgUsers?.map(u => u.id) || [];

  // Fetch workflows that are either:
  // 1. Owned by the current user OR
  // 2. From org members AND shared
  var data = null;
  var error = null;


  if (globalWorkflow) {
    ({ data, error } = await supabase
      .from('workflows')
      .select('*')
      .neq('session_id', sessionId)
      .gte('updated_at', new Date(lastSyncTime).toISOString()));
  } else {
    ({ data, error } = await supabase
      .from('workflows')
      .select('*')
      .or(`user_id.eq.${user.id},and(user_id.in.(${orgUserIds.join(',')}),is_shared.eq.true)`)
      .neq('session_id', sessionId)
      .gte('updated_at', new Date(lastSyncTime).toISOString()));
  }

  return { data, error };
};

export const syncWorkflow = async (
  workflowId: string, 
  workflowData: WorkflowData,
  sessionId: string
) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { error: 'No authenticated user' };

  const { data, error } = await supabase
    .from('workflows')
    .update({
      session_id: sessionId,
      workflow_data: JSON.stringify(workflowData),
      updated_at: new Date().toISOString()
    })
    .eq('id', workflowId);

  console.log("syncWorkflow", data, error);

  return { data, error };
};

interface ChatData {
  messages: ChatMessage[];
  inputValue: string;
  thinkingState: ThinkingState | null;
  workflow_id: string;
  is_default: boolean;
}

export const fetchUserChats = async (sessionId: string, lastSyncTime: number, globalChat: boolean = false) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { data: null, error: 'No authenticated user' };

  var data = null;
  var error = null;

  if (globalChat) {
    ({ data, error } = await supabase
      .from('chats')
      .select('*')
      .neq('session_id', sessionId)
      .gte('updated_at', new Date(lastSyncTime).toISOString()));
  } else {
    ({ data, error } = await supabase
      .from('chats')
      .select('*')
      .eq('user_id', user.id)
      .neq('session_id', sessionId)
      .gte('updated_at', new Date(lastSyncTime).toISOString()));
  }

  console.log("fetchUserChatsSupabase", data, error);

  return { data, error };
};

export const syncChat = async (
  chatId: string,
  chatData: ChatData,
  sessionId: string
) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { data: null, error: new Error('No authenticated user') };

  try {
    const { data, error } = await supabase
      .from('chats')
      .update({
        workflow_id: chatData.workflow_id,
        session_id: sessionId,
        messages: JSON.stringify(chatData.messages),
        updated_at: new Date().toISOString(),
        user_id: user.id
      })
      .eq('id', chatId)
      .select()
      .single();

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error syncing chat:', error);
    return { data: null, error };
  }
};

export const createSignedUrl = async (filePath: string, expiresIn: number = 3600) => {
  const { data, error } = await supabase.storage
    .from('chat_docs')
    .createSignedUrl(filePath, 3600); // expiresIn is in seconds, defaults to 1 hour

  if (error) {
    console.error('Error creating signed URL:', error);
    return { data: null, error };
  }

  return { 
    data: {
      signedUrl: data.signedUrl,
      expiresAt: Date.now() + (expiresIn * 1000) // Convert seconds to milliseconds
    }, 
    error: null 
  };
};



export const shareWorkflow = async (workflowId: string) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { error: 'No authenticated user' };

  // First, get the current workflow
  const { data: currentWorkflow, error: fetchError } = await supabase
    .from('workflows')
    .select('*')
    .eq('id', workflowId)
    .single();

  if (fetchError) return { error: fetchError };
  if (!currentWorkflow) return { error: 'Workflow not found' };


  var sanitizedWorkflowData = sanitizeWorkflowData(currentWorkflow.workflow_data);


  // If already shared, update the existing shared workflow
  if (currentWorkflow.shared_workflow_id) {
    const { data: updatedShared, error: updateError } = await supabase
      .from('workflows')
      .update({
        workflow_data: sanitizedWorkflowData,
        updated_at: new Date().toISOString()
      })
      .eq('id', currentWorkflow.shared_workflow_id)
      .select()
      .single();


    return { 
      id: currentWorkflow.shared_workflow_id, 
      workflow: {
        ...JSON.parse(sanitizedWorkflowData),
        is_shared: true, 
        updated_at: updatedShared.updated_at
      }, 
      error: updateError 
    };
  }

  // Create new shared workflow
  const { data: sharedWorkflow, error: createError } = await supabase
    .from('workflows')
    .insert([{
      user_id: currentWorkflow.user_id,
      session_id: crypto.randomUUID(),
      workflow_data: sanitizedWorkflowData,
      is_shared: true,
      updated_at: new Date().toISOString()
    }])
    .select()
    .single();

  if (createError) return { error: createError };

  // Update original workflow with shared_workflow_id
  const { error: updateError } = await supabase
    .from('workflows')
    .update({ shared_workflow_id: sharedWorkflow.id })
    .eq('id', workflowId);

  if (updateError) return { error: updateError };

  return { 
    id: sharedWorkflow.id, 
    workflow: {
      ...JSON.parse(sanitizedWorkflowData),
      is_shared: true, 
      updated_at: sharedWorkflow.updated_at
    }, 
    error: null 
  };
};

export const deleteWorkflow = async (workflowId: string) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { error: 'No authenticated user' };

  // Get the current workflow's shared workflow id
  const { data: currentWorkflow, error: fetchError } = await supabase
    .from('workflows')
    .select('shared_workflow_id')
    .eq('id', workflowId)
    .single();

  if (fetchError) return { error: fetchError };

  const workflowIds = currentWorkflow?.shared_workflow_id ? 
    [workflowId, String(currentWorkflow.shared_workflow_id)] : 
    [workflowId];

  // First delete associated chat records for the workflow and any shared workflow
  const { error: chatDeleteError } = await supabase
    .from('chats')
    .delete()
    .in('workflow_id', workflowIds);

  if (chatDeleteError) return { error: chatDeleteError };

  // Then delete the workflow and any shared workflow
  const { data, error: deleteError } = await supabase
    .from('workflows')
    .delete()
    .in('id', workflowIds)
    .eq('user_id', user.id)
    .select();

  return { data, error: deleteError };
};

export const duplicateWorkflow = async (workflowId: string) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return { error: 'No authenticated user' };

  // First, get the current workflow
  const { data: currentWorkflow, error: fetchError } = await supabase
    .from('workflows')
    .select('*')
    .eq('id', workflowId)
    .single();

  if (fetchError) return { error: fetchError };
  if (!currentWorkflow) return { error: 'Workflow not found' };

  // Create new workflow with copied data
  const { data: duplicatedWorkflow, error: createError } = await supabase
    .from('workflows')
    .insert([{
      user_id: user.id,
      session_id: crypto.randomUUID(),
      workflow_data: currentWorkflow.workflow_data,
      is_shared: false, // Always create as non-shared
      updated_at: new Date().toISOString()
    }])
    .select()
    .single();

  if (createError) return { error: createError };

  // Create a new chat for this workflow
  const chatId = await createChatSupabase(duplicatedWorkflow.session_id, duplicatedWorkflow.id, true);

  return { 
    id: duplicatedWorkflow.id,
    workflow: JSON.parse(currentWorkflow.workflow_data),
    chatId,
    error: null 
  };
};
