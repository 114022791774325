import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { signInWithEmail, signUp } from '../../utils/supabase';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
`;

const AuthBox = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin: 1rem 0;
  background: #3C94D6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: #2d7ab8;
  }
`;

const ToggleText = styled.p`
  text-align: center;
  cursor: pointer;
  color: #3C94D6;
`;

const ErrorMessage = styled.div`
  color: #ff0033;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const LegalText = styled.p`
  text-align: center;
  font-size: 0.8rem;
  color: #666;
  margin: 0.5rem 0;
`;

const LegalLink = styled.a`
  color: #3C94D6;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const Auth: React.FC = () => {
  const [isSignIn, setIsSignIn] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (isSignIn) {
      const { data, error } = await signInWithEmail(email, password);
      if (error) {
        console.error('Sign in error:', error);
        setError(error.message);
      }
    } else {
      const { data, error } = await signUp({
        email,
        password,
      });
      if (error) {
        console.error('Sign up error:', error);
        setError(error.message);
      } else {
        setIsSignIn(true);
      }
    }
  };

  return (
    <Container>
      <AuthBox>
        <Title>{isSignIn ? 'Sign In' : 'Sign Up'}</Title>
        <form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button type="submit">
            {isSignIn ? 'Sign In' : 'Sign Up'}
          </Button>

          <LegalText>
            By {isSignIn ? 'signing in' : 'signing up'}, you agree to our{' '}
            <LegalLink href="/tos.html" target="_blank">Terms of Service</LegalLink>{' '}
            and{' '}
            <LegalLink href="/privacy.html" target="_blank">Privacy Policy</LegalLink>
          </LegalText>
        </form>

        <ToggleText onClick={() => setIsSignIn(!isSignIn)}>
          {isSignIn ? 'Need an account? Sign up' : 'Already have an account? Sign in'}
        </ToggleText>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </AuthBox>
    </Container>
  );
};
