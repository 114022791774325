import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Editor, Monaco } from '@monaco-editor/react';
import styled, { keyframes, css } from 'styled-components';
import * as monaco from 'monaco-editor';
import { revitApiService } from '../../services/revitApiService';
import ReactDOM from 'react-dom/client';
import { submitCodeEdit, EditOperation } from './langgraphClient';

const EditorContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const EDIT_PLACEHOLDER = 'Editing instructions...';

const FloatingButtonContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  display: flex;
  gap: 0px;
  padding: 0px;
  background: var(--vscode-editor-background, #1e1e1e);
  border: 1px solid var(--vscode-input-border, #3c3c3c);
  border-radius: 6px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const ActionButton = styled.button`
  padding: 4px 12px;
  background: var(--vscode-button-background);
  color: var(--vscode-button-foreground, white);
  border: none;
  border-right: 1px solid var(--vscode-button-border, rgba(255, 255, 255, 0.1));
  border-radius: 0;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 80px;
  justify-content: center;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }

  &:hover {
    background: var(--vscode-button-hoverBackgroun);
  }

  span {
    opacity: 0.7;
    font-size: 11px;
    padding: 2px 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
`;

const borderAnimation = keyframes`
  0% {
    clip-path: polygon(0 0, 2px 0, 2px 2px, 0 2px);
  }
  25% {
    clip-path: polygon(0 0, 100% 0, 100% 2px, 0 2px);
  }
  50% {
    clip-path: polygon(calc(100% - 2px) 0, 100% 0, 100% 100%, calc(100% - 2px) 100%);
  }
  75% {
    clip-path: polygon(0 calc(100% - 2px), 100% calc(100% - 2px), 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 2px 0, 2px 100%, 0 100%);
  }
`;

const EditorInputWidget = styled.div<{ containerWidth: number; isLoading?: boolean }>`
  background: var(--vscode-editor-background);
  border: 1px solid var(--vscode-focusBorder, #007fd4);
  border-radius: 6px;
  font-family: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => Math.min(props.containerWidth - 60, 800)}px;
  z-index: 1;

  ${props => props.isLoading && css`
    &:after {
      content: '';
      position: absolute;
      inset: -2px;
      border-radius: 6px;
      z-index: -1;
      border: 2px solid var(--vscode-focusBorder, #007fd4);
      animation: ${borderAnimation} 1.5s linear infinite;
    }
  `}
`;

const InputLine = styled.div<{ isLoading?: boolean }>`
  padding: 0px;
  margin: 5px 10px;
  line-height: 20px;
  font-style: italic;
  opacity: ${props => props.isLoading ? 0.5 : 0.7};
  min-height: 24px;
  display: flex;
  align-items: flex-start;
  white-space: pre-wrap;
  word-break: break-word;
  pointer-events: ${props => props.isLoading ? 'none' : 'auto'};

  &:focus {
    outline: none;
  }
`;

const ButtonBar = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  font-size: 11px;
  opacity: ${props => props.isLoading ? 0.5 : 1};
  pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
`;

const BarButton = styled.button`
  background: none;
  border: none;
  color: var(--vscode-foreground);
  padding: 2px 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.active {
    color: var(--vscode-textLink-foreground);
  }
`;

interface FloatingButtonsProps {
  left: number;
  top: number;
  onChat: () => void;
  onEdit: () => void;
}

const FloatingButtons: React.FC<FloatingButtonsProps> = ({ left, top, onChat, onEdit }) => (
  <FloatingButtonContainer left={left} top={top}>
    {/* <ActionButton onClick={onChat}>
      Chat
      <span style={{ opacity: 0.7 }}>Ctrl+L</span>
    </ActionButton> */}
    <ActionButton onClick={onEdit}>
      Edit
      <span style={{ opacity: 0.7 }}>Ctrl+K</span>
    </ActionButton>
  </FloatingButtonContainer>
);

interface HoverProvider {
  pattern: RegExp | string;
  content?: string | monaco.IMarkdownString;
  getContentAsync?: (word: string, line_context: string) => Promise<string | monaco.IMarkdownString>;
}

interface FullCodeEditorProps {
  code: string;
  language: string;
  onChange?: (value: string) => void;
  hoverProviders?: HoverProvider[];
  uneditableLine?: {
    lineNumber: number;
    content: string;
  };
}

interface CompletionItem {
  label: string;
  documentation?: string;
  insertText?: string;
  kind?: number;
}

interface EditorWidgetRef {
  widget: HTMLElement;
  reactRoot: ReactDOM.Root;
  decorations: string[];
  viewZoneId: string;
  updateState: (isLoading: boolean) => void;
}

interface PreviewData {
  decorationIds: string[];
  zoneIds: string[];
  operations: EditOperation[];
}

const FullCodeEditor: React.FC<FullCodeEditorProps> = ({
  code,
  language,
  onChange,
  hoverProviders = [],
  uneditableLine,
}): JSX.Element => {
  const [editorValue, setEditorValue] = useState(code);
  const editorRef = useRef<any>(null);
  const modelRef = useRef<monaco.editor.ITextModel | null>(null);
  const currentLineRef = useRef<number>(uneditableLine?.lineNumber || 0);
  const decorationsRef = useRef<monaco.editor.IEditorDecorationsCollection | null>(null);
  const [floatingButtons, setFloatingButtons] = useState<{ left: number; top: number } | null>(null);
  const [showEditInput, setShowEditInput] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [editPosition, setEditPosition] = useState<number | null>(null);
  const widgetRef = useRef<EditorWidgetRef | null>(null);
  const zoneRef = useRef<{
    zone: monaco.editor.IViewZone;
    zoneId: string;
  } | null>(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const isEditLoadingRef = useRef(isEditLoading);
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);

  // Keep ref in sync with state
  useEffect(() => {
    isEditLoadingRef.current = isEditLoading;
  }, [isEditLoading]);

  const handleChange = (value: string | undefined) => {
    const newValue = value || '';
    setEditorValue(newValue);
    onChange?.(newValue);
  };

  const handleSelectionChange = useCallback(() => {
    if (!editorRef.current) return;

    const selection = editorRef.current.getSelection();
    if (!selection || selection.isEmpty()) {
      setFloatingButtons(null);
      return;
    }

    const model = editorRef.current.getModel();
    setSelectedText(model.getValueInRange(selection));

    // Get the coordinates of the selection start instead of end
    const startPosition = selection.getStartPosition();
    const coordinates = editorRef.current.getScrolledVisiblePosition(startPosition);
    
    if (coordinates) {
      setFloatingButtons({
        left: coordinates.left + 10,
        top: coordinates.top - 40
      });
    }
  }, []);

  const insertEditInput = useCallback(() => {
    if (!editorRef.current || !modelRef.current) return;

    const selection = editorRef.current.getSelection();
    if (!selection) return;

    // Get the editor's layout info to determine the actual visible width
    const layoutInfo = editorRef.current.getLayoutInfo();
    const contentWidth = layoutInfo.contentWidth;
    const verticalScrollbarWidth = layoutInfo.verticalScrollbarWidth;
    const containerWidth = contentWidth - verticalScrollbarWidth;

    // Use the start line number instead of end line number
    const lineNumber = selection.startLineNumber;
    setEditPosition(lineNumber);
    setShowEditInput(true);

    // Create the widget element
    const widget = document.createElement('div');
    widget.className = 'monaco-editor-line-widget';
    widget.style.width = '100%';
    widget.style.display = 'flex';
    widget.style.justifyContent = 'flex-start';
    
    // Add the styled component to the widget
    const root = document.createElement('div');
    widget.appendChild(root);
    
    // Create a React root and render our component
    const reactRoot = ReactDOM.createRoot(root);

    const updateWidgetState = (isLoading: boolean) => {
      reactRoot.render(
        <WidgetContent 
          containerWidth={containerWidth} 
          isLoading={isLoading} 
          onSubmit={handleSubmit}
          onClose={cleanupWidget}
        />
      );
    };
    
    // Initial render
    updateWidgetState(false);

    // Store the update function
    const widgetData = {
      widget,
      reactRoot,
      decorations: [],
      viewZoneId: '',
      updateState: updateWidgetState
    };
    widgetRef.current = widgetData;

    // Initial widget creation with fixed height
    editorRef.current.changeViewZones((changeAccessor: monaco.editor.IViewZoneChangeAccessor) => {
      const zone: monaco.editor.IViewZone = {
        afterLineNumber: lineNumber - 1,
        heightInPx: 60,
        domNode: widget,
        suppressMouseDown: false,
        showInHiddenAreas: false,
        marginDomNode: null
      };
      const zoneId = changeAccessor.addZone(zone);
      zoneRef.current = {
        zone,
        zoneId
      };
      widgetData.viewZoneId = zoneId;
    });
  }, []);

  interface WidgetContentProps {
    containerWidth: number;
    isLoading: boolean;
    onSubmit: () => Promise<void>;
    onClose: () => void;
  }

  const WidgetContent: React.FC<WidgetContentProps> = ({ 
    containerWidth, 
    isLoading,
    onSubmit,
    onClose
  }) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const lastHeightRef = useRef<number>(60);

    const handleNewline = useCallback((e?: React.KeyboardEvent) => {
      if (inputRef.current) {
        // if (e) e.preventDefault();
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        if (range) {
          const br = document.createElement('br');
          range.deleteContents();
          range.insertNode(br);
          range.setStartAfter(br);
          range.setEndAfter(br);
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      }
    }, []);

    useEffect(() => {
      if (inputRef.current) {
        // Set focus to the input
        inputRef.current.focus();

        // Create a new observer for height changes
        const resizeObserver = new ResizeObserver(() => {
          updateHeightIfNeeded();
        });
        resizeObserver.observe(inputRef.current);

        // Cleanup
        return () => {
          resizeObserver.disconnect();
        };
      }
    }, []); // Empty dependency array to run only once on mount

    // Force focus when the widget is created
    useEffect(() => {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
      return () => clearTimeout(timer);
    }, []); // Run once on mount with a slight delay

    const updateHeightIfNeeded = useCallback(() => {
      if (!widgetRef.current || !editorRef.current || !inputRef.current) return;
      
      const newHeight = inputRef.current.scrollHeight + 40; // Add space for button bar
      if (Math.abs(newHeight - lastHeightRef.current) > 5) { // Only update if height change is significant
        lastHeightRef.current = newHeight;

        if (!widgetRef.current) return;
        if (!zoneRef.current) return;

        const { zone, zoneId } = zoneRef.current;
        if (!zone || !zoneId) return;
        // 1) Update *both* the domNode's style AND the zone's heightInPx
        zone.domNode.style.height = `${newHeight}px`;
        zone.heightInPx = newHeight;

        // 2) Tell Monaco to re‐read zone.heightInPx and push lines accordingly
        editorRef.current.changeViewZones((changeAccessor: monaco.editor.IViewZoneChangeAccessor) => {
          changeAccessor.layoutZone(zoneId);
        });
      }
    }, []);

    return (
      <EditorInputWidget containerWidth={containerWidth} isLoading={isLoading}>
        <InputLine 
          ref={inputRef}
          contentEditable={!isLoading}
          suppressContentEditableWarning
          isLoading={isLoading}
          onKeyDown={(e) => {
            if (isLoading) {
              e.preventDefault();
              return;
            }
            if (e.currentTarget.textContent === EDIT_PLACEHOLDER) {
              e.currentTarget.textContent = '';
            }
            if (e.key === 'Enter') {
              if (e.shiftKey) {
                // do nothing
                console.log('shift+enter');
              } else {
                e.preventDefault();
                onSubmit();
              }
            }
          }}
          onInput={(e) => {
            if (isLoading) {
              e.preventDefault();
              return;
            }
            updateHeightIfNeeded();
          }}
          onPaste={(e) => {
            if (isLoading) {
              e.preventDefault();
              return;
            }
          }}
          onDrop={(e) => {
            if (isLoading) {
              e.preventDefault();
              return;
            }
          }}
          onCut={(e) => {
            if (isLoading) {
              e.preventDefault();
              return;
            }
          }}
          onBlur={(e) => {
            if (isLoading) return;
            if (!e.currentTarget.textContent?.trim()) {
              e.currentTarget.textContent = EDIT_PLACEHOLDER;
            }
          }}
        >
          {EDIT_PLACEHOLDER}
        </InputLine>
        <ButtonBar isLoading={isLoading}>
          <BarButton onClick={onClose}>
            <span>Esc to close</span>
          </BarButton>
          <BarButton onClick={() => handleNewline()}>
            <span>Shift+Enter for newline</span>
          </BarButton>
          <BarButton onClick={onSubmit}>
            <span>Enter to submit</span>
          </BarButton>
        </ButtonBar>
      </EditorInputWidget>
    );
  };

  const cleanupWidget = () => {
    // If still loading, do nothing
    if (isEditLoadingRef.current) {
      console.log('Widget is loading, preventing cleanup');
      return;
    }

    console.log('Cleaning up widget...');
    if (widgetRef.current) {
      widgetRef.current.reactRoot.unmount();
      if (zoneRef.current) {
        editorRef.current.changeViewZones((changeAccessor: monaco.editor.IViewZoneChangeAccessor) => {
          changeAccessor.removeZone(zoneRef.current!.zoneId);
        });
      }
      widgetRef.current = null;
      zoneRef.current = null;
      setShowEditInput(false);
      setEditPosition(null);
    }
  };

  const handleEditorDidMount = async (editor: any, monaco: Monaco) => {
    editorRef.current = editor;
    console.log('Editor mounted, attaching event handlers...');

    // Add selection change listener
    editor.onDidChangeCursorSelection(handleSelectionChange);
    
    // Add keyboard shortcut listeners
    console.log('Attaching keyboard commands...');
    
    // Add Ctrl+K command
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK, () => {
      console.log('Ctrl+K command triggered');
      const selection = editor.getSelection();
      if (selection && !selection.isEmpty()) {
        insertEditInput();
      }
    });

    // Escape command to close widget
    editor.addCommand(monaco.KeyCode.Escape, () => {
      console.log('Escape command triggered, loading state:', isEditLoadingRef.current);
      // ONLY close if NOT loading
      if (!isEditLoadingRef.current) {
        cleanupWidget();
      }
    });

    // Enter command to submit edit - only when edit widget is active
    editor.addCommand(monaco.KeyCode.Enter, () => {
      if (!showEditInput || !editPosition || !widgetRef.current) {
        return false; // Let the default Enter behavior happen
      }
      const instructions = editor.getModel().getLineContent(editPosition).trim();
      if (instructions) {
        console.log('Edit instructions:', instructions);
        console.log('Selected text:', selectedText);
      }
      widgetRef.current.reactRoot.unmount();
      editor.deltaDecorations(widgetRef.current.decorations, []);
      editor.changeViewZones((changeAccessor: monaco.editor.IViewZoneChangeAccessor) => {
        changeAccessor.removeZone(widgetRef.current!.viewZoneId);
      });
      widgetRef.current = null;
      setShowEditInput(false);
      setEditPosition(null);
      return true; // Prevent default Enter behavior
    }, 'showEditInput');  // Only active when showEditInput is true

    // Create model and URI
    const uri = monaco.Uri.parse(`file:///workspace/editor${Date.now()}.py`);
    const model = monaco.editor.createModel(code, 'python', uri);
    modelRef.current = model;
    editor.setModel(model);

    // Add uneditable line decoration if specified
    if (uneditableLine) {
      currentLineRef.current = uneditableLine.lineNumber;

      // Set the initial content of the uneditable line
      const lines = model.getLinesContent();
      const lineContent = lines[currentLineRef.current - 1];
      if (lineContent !== uneditableLine.content) {
        model.pushEditOperations(
          [],
          [{
            range: new monaco.Range(
              currentLineRef.current,
              1,
              currentLineRef.current,
              lineContent ? lineContent.length + 1 : 1
            ),
            text: uneditableLine.content
          }],
          () => null
        );
      }

      const updateDecoration = () => {
        decorationsRef.current?.set([{
          range: new monaco.Range(
            currentLineRef.current,
            1,
            currentLineRef.current,
            1
          ),
          options: {
            isWholeLine: true,
            className: 'uneditable-line',
            stickiness: monaco.editor.TrackedRangeStickiness.GrowsOnlyWhenTypingBefore,
          }
        }]);

        // Ensure the content of the uneditable line hasn't changed
        const currentContent = model.getLineContent(currentLineRef.current);
        if (currentContent !== uneditableLine.content) {
          model.pushEditOperations(
            [],
            [{
              range: new monaco.Range(
                currentLineRef.current,
                1,
                currentLineRef.current,
                currentContent.length + 1
              ),
              text: uneditableLine.content
            }],
            () => null
          );
        }
      };

      // Add decoration for visual feedback
      decorationsRef.current = editor.createDecorationsCollection([]);
      updateDecoration();

      // Track content changes to update uneditable line position
      model.onDidChangeContent((e) => {
        let needsUpdate = false;
        for (const change of e.changes) {
          const startLine = change.range.startLineNumber;
          const endLine = change.range.endLineNumber;
          const newLines = change.text.split('\n').length - 1;
          const removedLines = endLine - startLine;
          
          if (startLine < currentLineRef.current) {
            // If we're adding or removing lines before our line
            currentLineRef.current += newLines - removedLines;
            needsUpdate = true;
          } else if (startLine === currentLineRef.current) {
            // If someone tried to modify the uneditable line
            // Allow the change if it's a newline at the start of the line
            const isNewlineAtStart = change.text === '\n' && 
                                   change.range.startColumn === 1 && 
                                   change.range.endColumn === 1;
            if (!isNewlineAtStart) {
              needsUpdate = true;
            } else {
              currentLineRef.current++;
              needsUpdate = true;
            }
          }
        }
        if (needsUpdate) {
          updateDecoration();
        }
      });

      // Add content protection
      editor.onKeyDown((e: any) => {
        const position = editor.getPosition();
        if (position.lineNumber === currentLineRef.current) {
          // Allow Enter key (newline) when at the start of the line
          if (e.keyCode === monaco.KeyCode.Enter && position.column === 1) {
            return null; // Allow the enter key
          }
          e.preventDefault();
          return null;
        }
      });

      // Prevent paste operations on the uneditable line
      editor.onDidPaste((e: any) => {
        const position = editor.getPosition();
        if (position.lineNumber === currentLineRef.current) {
          editor.undo();
        }
      });

      // Add CSS for visual feedback
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        .uneditable-line {
          background: rgba(128, 128, 128, 0.1);
          cursor: not-allowed !important;
        }
      `;
      document.head.appendChild(styleElement);
    }

    // Register hover provider with async support
    const hoverDisposable = monaco.languages.registerHoverProvider(language, {
      provideHover: async (model, position) => {
        const word = model.getWordAtPosition(position);
        const line_context = model.getLineContent(position.lineNumber);

        if (!word) return null;
        
        const wordRange = new monaco.Range(
          position.lineNumber,
          word.startColumn,
          position.lineNumber,
          word.endColumn
        );

        // First check if it's a Revit API symbol
        if (language === 'python' && /^Revit|^Autodesk/.test(word.word)) {
          try {
            const content = await revitApiService.getHoverContent(word.word, line_context);
            return {
              range: wordRange,
              contents: [content]
            };
          } catch (error) {
            console.error('Error fetching Revit API documentation:', error);
          }
        }

        // Then check custom hover providers
        const matchingProvider = hoverProviders.find(provider => {
          if (provider.pattern instanceof RegExp) {
            return provider.pattern.test(word.word);
          }
          return provider.pattern === word.word;
        });

        if (matchingProvider) {
          // Handle async content retrieval
          if (matchingProvider.getContentAsync) {
            try {
              const content = await matchingProvider.getContentAsync(word.word, line_context);
              return {
                range: wordRange,
                contents: [
                  typeof content === 'string'
                    ? { value: content } as monaco.IMarkdownString
                    : content
                ]
              };
            } catch (error) {
              console.error('Error fetching hover content:', error);
              return null;
            }
          }

          // Handle static content
          if (matchingProvider.content) {
            return {
              range: wordRange,
              contents: [
                typeof matchingProvider.content === 'string'
                  ? { value: matchingProvider.content } as monaco.IMarkdownString
                  : matchingProvider.content
              ]
            };
          }
        }

        return null;
      },
    });

    // Cleanup on unmount
    return () => {
      hoverDisposable.dispose();
      modelRef.current?.dispose();
    };
  };

  // Add CSS for decorations if not already added
  useEffect(() => {
    if (!document.getElementById('monaco-preview-styles')) {
      const style = document.createElement('style');
      style.id = 'monaco-preview-styles';
      style.textContent = `
        .line-removed {
          text-decoration: line-through !important;
          background-color: rgba(255, 0, 0, 0.15) !important;
        }
        .line-inserted {
          background-color: rgba(0, 255, 0, 0.1) !important;
          white-space: pre !important;
          font-family: var(--vscode-editor-font-family) !important;
          padding: 2px 0 2px 20px !important;
          border-left: 2px solid #4CAF50 !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  const previewEdits = (operations: EditOperation[]) => {
    if (!editorRef.current || !modelRef.current) return null;

    const toDecorate: monaco.editor.IModelDeltaDecoration[] = [];
    const zonesToAdd: { afterLine: number; domNode: HTMLElement; height: number }[] = [];

    for (const op of operations) {
      if (op.operation_type === 'delete' || op.operation_type === 'replace') {
        // Highlight removed/replaced lines
        toDecorate.push({
          range: new monaco.Range(
            op.start_line,
            1,
            op.end_line || op.start_line,
            modelRef.current.getLineMaxColumn(op.end_line || op.start_line)
          ),
          options: {
            isWholeLine: true,
            className: 'line-removed'
          }
        });
      }

      if ((op.operation_type === 'insert' || op.operation_type === 'replace') && op.content) {
        // Create container for inserted lines
        const container = document.createElement('div');
        container.className = 'line-inserted';
        container.style.position = 'relative';
        container.style.zIndex = '9999';
        container.style.pointerEvents = 'auto';
        
        // Split content into lines and create elements
        const lines = op.content.split('\n');
        lines.forEach((line: string) => {
          const lineDiv = document.createElement('div');
          lineDiv.textContent = line;
          container.appendChild(lineDiv);
        });

        // Add to zones
        zonesToAdd.push({
          afterLine: op.operation_type === 'replace' 
            ? (op.end_line || op.start_line)
            : op.start_line,
          domNode: container,
          height: lines.length * 20 // Approximate line height
        });
      }
    }

    // Apply decorations
    const decorationIds = editorRef.current.deltaDecorations([], toDecorate);

    // Apply zones
    const zoneIds: string[] = [];
    editorRef.current.changeViewZones((accessor: monaco.editor.IViewZoneChangeAccessor) => {
      for (const zone of zonesToAdd) {
        const zoneId = accessor.addZone({
          afterLineNumber: zone.afterLine,
          heightInPx: zone.height,
          domNode: zone.domNode,
          suppressMouseDown: false  // Allow mouse events
        });
        zoneIds.push(zoneId);
      }
    });

    return { decorationIds, zoneIds, operations };
  };

  const cancelPreview = (previewToCancel: PreviewData) => {
    console.log('cancelPreview');
    if (!editorRef.current) return;

    // Remove decorations
    editorRef.current.deltaDecorations(previewToCancel.decorationIds, []);

    // Remove zones
    editorRef.current.changeViewZones((accessor: monaco.editor.IViewZoneChangeAccessor) => {
      for (const zoneId of previewToCancel.zoneIds) {
        accessor.removeZone(zoneId);
      }
    });

    setPreviewData(null);
    cleanupWidget();
  };

  const applyPreview = (previewToApply: PreviewData) => {
    console.log('applyPreview');
    if (!modelRef.current || !editorRef.current) {
      console.error('Model or editor not available');
      return;
    }

    // Remove preview visuals
    editorRef.current.deltaDecorations(previewToApply.decorationIds, []);
    editorRef.current.changeViewZones((accessor: monaco.editor.IViewZoneChangeAccessor) => {
      for (const zoneId of previewToApply.zoneIds) {
        accessor.removeZone(zoneId);
      }
    });

    // Apply the actual edits
    const editOperations = previewToApply.operations.map(op => {
      const range = new monaco.Range(
        op.start_line,
        1,
        op.end_line || op.start_line,
        modelRef.current!.getLineMaxColumn(op.end_line || op.start_line)
      );

      return {
        range,
        text: op.operation_type === 'delete' ? '' : (op.content || ''),
        forceMoveMarkers: true
      };
    });

    modelRef.current.pushEditOperations([], editOperations, () => null);
    cleanupWidget();
  };

  const handleSubmit = async () => {
    if (!editorRef.current || !modelRef.current || !widgetRef.current) return;
    
    const widget = widgetRef.current;
    const inputElement = widget.widget.querySelector('.monaco-editor-line-widget div[contenteditable]');
    if (!inputElement) return;
    
    const instructions = inputElement.textContent?.trim();
    if (!instructions || instructions === EDIT_PLACEHOLDER) return;

    // 1) Set loading = true so user can't close
    setIsEditLoading(true);
    widget.updateState(true);

    try {
      // Get the selected code and file context
      const selection = editorRef.current.getSelection();
      const selectedCode = modelRef.current.getValueInRange(selection);
      const fileContext = modelRef.current.getValue();

      // Get the surrounding context (20 lines before and after)
      const contextStartLine = Math.max(1, selection.startLineNumber - 20);
      const contextEndLine = Math.min(modelRef.current.getLineCount(), selection.endLineNumber + 20);
      const contextRange = new monaco.Range(contextStartLine, 1, contextEndLine, modelRef.current.getLineMaxColumn(contextEndLine));
      const codeContext = modelRef.current.getValueInRange(contextRange);

      // Submit to langgraph
      const response = await submitCodeEdit({
        selected_code: selectedCode,
        selected_code_context: codeContext,
        full_file_context: fileContext,
        edit_instructions: instructions,
        selection_range: {
          start_line: selection.startLineNumber,
          end_line: selection.endLineNumber
        },
        context_range: {
          start_line: contextStartLine,
          end_line: contextEndLine
        },
        total_lines: modelRef.current.getLineCount()
      });

      // Show preview instead of applying directly
      const preview = previewEdits(response.edit_result.operations);
      if (preview) {
        setPreviewData(preview);
        
        // Create a new root for the updated widget content
        const root = document.createElement('div');
        root.style.position = 'relative';
        root.style.zIndex = '9999';
        root.style.pointerEvents = 'auto';
        widget.widget.innerHTML = '';
        widget.widget.appendChild(root);
        
        // Render the buttons
        widget.reactRoot = ReactDOM.createRoot(root);
        widget.reactRoot.render(
          <ButtonBar>
            <BarButton onClick={() => cancelPreview(preview)}>
              <span>Cancel</span>
            </BarButton>
            <BarButton onClick={() => applyPreview(preview)}>
              <span>Apply Changes</span>
            </BarButton>
          </ButtonBar>
        );
      }

      // Remove the loading state
      widget.updateState(false);

    } catch (error) {
      console.error('Error processing code edit:', error);
    } finally {
      setIsEditLoading(false);
    }
  };

  return (
    <EditorContainer>
      <Editor
        height="100%"
        defaultLanguage={language}
        value={editorValue}
        onChange={handleChange}
        onMount={handleEditorDidMount}
        options={{
          minimap: { enabled: true },
          scrollBeyondLastLine: false,
          fontSize: 14,
          lineNumbers: 'on',
          quickSuggestions: {
            other: true,
            comments: true,
            strings: true
          },
          suggestOnTriggerCharacters: true,
          parameterHints: {
            enabled: true
          },
          glyphMargin: true
        }}
      />
      {floatingButtons && !showEditInput && (
        <FloatingButtons
          left={floatingButtons.left}
          top={floatingButtons.top}
          onChat={() => console.log('Chat clicked')}
          onEdit={insertEditInput}
        />
      )}
    </EditorContainer>
  );
};

export default FullCodeEditor; 