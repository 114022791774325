import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";

export const NodeWrapper = styled.div<NodeProps & { dragging: boolean }>`
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  min-width: 250px;
  min-height: ${props => props.type === 'branch' ? '80px' : 'auto'};
  border: 1px solid ${props => props.selected ? '#3C94D6' : '#e0e0e0'};
  transition: all 0.2s ease;

  ${props => props.selected && `
    box-shadow: 0 0 0 2px rgba(60, 148, 214, 0.3);
  `}

  &.error {
    border-color: #ef4444;
    background: #fef2f2;
  }
`;

export const NodeHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 16px 0 16px;

  &.error {
    background: #fee2e2;
    border-bottom-color: #fecaca;
  }
`;

export const NodeHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NodeHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NodeHeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  &:hover {
    scale: 1.1;
  }
  padding: 3px;
`;

export const NodeTitle = styled.div`
  font-weight: 500;
  cursor: text;
  padding: 2px 4px;
  border-radius: 4px;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &[contenteditable="true"] {
    outline: none;
    background: white;
    border: 1px solid #3C94D6;
    min-width: 50px;
  }
`;

export const NodeTitleInput = styled.input`
  font-weight: 500;
  background: white;
  border: none;
  outline: 1.5px solid #3C94D6;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: inherit;
  font-family: inherit;
  min-width: 50px;
`;

export const NodeBody = styled.div`
  padding: 0 16px 16px 16px;
`;

export const NodeFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #e0e0e0;
  padding: 4px 8px;
`;

export const NodeFooterTab = styled.div<{ active?: boolean }>`
  color: ${props => props.active ? '#000000' : '#7F7F7F'};
  background: ${props => props.active ? '#F5F5F5' : 'transparent'};
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 10px;
`;

export const StyledHandle = styled(Handle)`
  background: #D1D5DB;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;

  /* Default positioning for regular nodes */
  &.source {
    right: -6px;
    transform: translateX(0);
  }
  &.target {
    left: -6px;
    transform: translateX(0);
  }

  /* Special positioning for branch node handles */
  &[data-handleid] {
    position: absolute;
    right: -6px;
    transform: none;
  }

  /* Reset ReactFlow's default transform */
  &.source, &.target, &[data-handleid] {
    transform-origin: center;
  }
`;

export const NodeFieldLabel = styled.div`
  font-size: 12px;
  color: #7F7F7F;
  margin-bottom: 4px;
`;

export const NodeFieldDropdown = styled.select`
  width: 100%;
  padding: 6px 8px;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  color: #333;
  cursor: pointer;
  outline: none;

  &:hover {
    border-color: #3C94D6;
  }

  &:focus {
    border-color: #3C94D6;
    box-shadow: 0 0 0 1px rgba(60, 148, 214, 0.2);
  }
`;

export const NodeFields = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
