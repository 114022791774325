import React from 'react';
import styled from 'styled-components';
import { DynamicSvg } from '../../ui-components/DynamicSvg';

interface KPICardProps {
  value: number;
  unit: string;
  title: string;
}

const Card = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const KPICard: React.FC<KPICardProps> = ({ value, unit, title }) => {
  return (
    <Card>
      <Title>{title}</Title>
      <Value>
        {value}
        {unit && <span style={{ fontSize: '14px', color: '#666' }}>{unit}</span>}
      </Value>
    </Card>
  );
};