import React, { useState, useEffect } from "react";
import { _AIChatMessageBox, StepStatusTag, StepMetadata, StepHeader, StepContent, RunLogInChatContainer, RunLogInChatStepItem, StepTitle, StepIcon, RunLogInChatTitle, StepFinalState, ShowMoreButton, FinalStateContainer, FinalStateRow, FinalStateKey, FinalStateValue, FinalStateNestedContainer, ArrowIcon, FinalStateHeader, ButtonGroup, ChartButton} from "./ChatComponents.style";
import { ChatMessage } from "../../types/message";
import { DynamicSvg } from "../../ui-components/DynamicSvg";
import ChatEmbeddedWorkflowButton from './ChatEmbeddedWorkflowButton';
import { CodeBlock } from "./CodeBlock";
import { useWorkflowStore } from "../../stores/workflowStore";
import { useEditorStore } from "../../stores/editorStore";
import { ChartView } from "../ChartView/ChartView";

// Animation configuration
const ENABLE_ANIMATIONS = false;
const STEP_DELAY = 1000; // Delay between steps (ms)
const TIME_UPDATE_INTERVAL = 100; // Time counter update frequency (ms)

const ArchiLabsLink = ({ value }: { value: string }) => {
    const updateSignedUrl = useEditorStore(state => state.updateSignedUrl);

    const handleClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            // Parse the format ARCHILABS_STORED_FILE:filePath:fileType
            const [_, filePath, fileType] = value.split(':');
            const threadId = useEditorStore.getState().getChatThreadId();
            const expiration = 0
            const url = await updateSignedUrl(threadId, filePath, expiration, "");
            console.log('Generated URL:', url); // Debug log
            if (url) {
                const newWindow = window.open(url, '_blank');
                if (!newWindow) {
                    console.error('Popup was blocked');
                }
            } else {
                console.error('No URL generated');
            }
        } catch (error) {
            console.error('Error generating signed URL:', error);
        }
    };

    return (
        <a 
            href="#"
            onClick={handleClick}
            style={{
                color: '#3C94D6',
                textDecoration: 'none',
                cursor: 'pointer'
            }}
        >
            File Link
        </a>
    );
};

const formatValue = (value: any): React.ReactNode => {
    if (typeof value === 'number') {
        return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    }
    if (typeof value === 'string' && value.startsWith('ARCHILABS_STORED_FILE')) {
        return <ArchiLabsLink value={value} />;
    }
    return String(value);
};

const FinalStateRenderer = ({ data, isNested = false, lastItem = true }: { data: any, isNested?: boolean, lastItem?: boolean }) => {
    const [openNodes, setOpenNodes] = useState<{ [key: string]: boolean }>({});
    
    if (typeof data !== 'object' || data === null) {
        return <div>{formatValue(data)}</div>;
    }

    const entries = Object.entries(data);
    
    const toggleNode = (key: string) => {
        setOpenNodes(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };
    
    return (
        <FinalStateContainer style={isNested ? { margin: 0, border: 'none', padding: 0 } : {}}>
            {entries.map(([key, value], index) => {
                // Skip empty arrays
                if (Array.isArray(value) && value.length === 0) return null;
                
                const isLastEntry = index === entries.length - 1;
                const hasChildren = typeof value === 'object' && value !== null;
                const isOpen = openNodes[key] ?? false;
                
                // Format the key to be more readable
                const formattedKey = key
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                return (
                    <FinalStateRow key={key}>
                        <FinalStateKey 
                            isLast={isLastEntry}
                            hasChildren={hasChildren}
                            onClick={() => hasChildren && toggleNode(key)}
                        >
                            {hasChildren && <ArrowIcon isOpen={isOpen} />}
                            {formattedKey}
                        </FinalStateKey>
                        <FinalStateValue>
                            {typeof value === 'object' && value !== null ? (
                                isOpen && (
                                    <FinalStateNestedContainer>
                                        <FinalStateRenderer 
                                            data={value} 
                                            isNested={true}
                                            lastItem={isLastEntry}
                                        />
                                    </FinalStateNestedContainer>
                                )
                            ) : (
                                formatValue(value)
                            )}
                        </FinalStateValue>
                    </FinalStateRow>
                );
            })}
        </FinalStateContainer>
    );
};

export const AIChatMessageBox = ({ message, isNew }: { message: ChatMessage, isNew: boolean }) => {
    // Add URL parameter check
    const showChartsEnabled = new URLSearchParams(window.location.search).get('charts') === 'true';
    
    const [displayedContent, setDisplayedContent] = useState<(typeof message.content[0] & { 
        partialText?: string,
        stepProgress?: { [stepIndex: number]: { 
            currentTime: number, 
            showStatus: boolean,
            isVisible: boolean
        } }
    })[]>([]);
    const { nodes, setNodes } = useWorkflowStore();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFinalStateOpen, setIsFinalStateOpen] = useState(false);
    const [showCharts, setShowCharts] = useState(false);
    const [selectedFinalState, setSelectedFinalState] = useState<any>(null);

    useEffect(() => {
        // Clear any existing effects
        let timeouts: NodeJS.Timeout[] = [];
        let intervals: number[] = [];
        
        if (!isNew || !ENABLE_ANIMATIONS) {
            // For non-new messages, initialize with all steps visible and completed
            setDisplayedContent(message.content.map(content => {
                if (content.type === 'run-log-in-chat') {
                    return {
                        ...content,
                        stepProgress: content.steps.reduce((acc, step, idx) => ({
                            ...acc,
                            [idx]: { 
                                currentTime: step.time, 
                                showStatus: true,
                                isVisible: true
                            }
                        }), {})
                    };
                }
                return content;
            }));
            return;
        }

        // Reset displayed content
        setDisplayedContent([]);
        
        // Helper function to process a single content item and return a promise
        const processContent = async (content: typeof message.content[0], contentIndex: number) => {
            return new Promise<void>((resolve) => {
                if (content.type === 'run-log-in-chat') {
                    // Initialize content with all steps visible and completed
                    setDisplayedContent(prev => [...prev, {
                        ...content,
                        stepProgress: content.steps.reduce((acc, step, idx) => ({
                            ...acc,
                            [idx]: { 
                                currentTime: step.time,
                                showStatus: true,
                                isVisible: true
                            }
                        }), {})
                    }]);
                    resolve();
                    return;
                } else {
                    if (content.type !== 'text') {
                        setDisplayedContent(prev => [...prev, content]);
                        resolve();
                        return;
                    }

                    // Add the content initially with empty text
                    setDisplayedContent(prev => [...prev, { ...content, partialText: '' }]);
                    const text = content.text || '';  // Add fallback for undefined text
                    const words = text.split(/(\s+)/); // Split by whitespace but keep the whitespace

                    let wordIndex = 0;
                    const streamText = () => {
                        if (wordIndex < words.length) {
                            setDisplayedContent(prev => 
                                prev.map((item, idx) => 
                                    idx === contentIndex 
                                        ? { ...item, partialText: words.slice(0, wordIndex + 1).join('') }
                                        : item
                                )
                            );
                            wordIndex++;
                            const timeout = setTimeout(streamText, 50); // Adjust timing per word
                            timeouts.push(timeout);
                        } else {
                            resolve();
                        }
                    };
                    streamText();
                }
            });
        };

        // Process content items sequentially
        const processAllContent = async () => {
            for (let i = 0; i < message.content.length; i++) {
                await processContent(message.content[i], i);
            }
        };

        processAllContent();

        return () => {
            timeouts.forEach(clearTimeout);
            intervals.forEach(id => window.clearInterval(id));
        };
    }, [message, isNew]);

    const applyCode = (code: string) => {
        // setNodes([]);
        setNodes((prev) => {
            const output = [...prev.map((node) => {
                return { ...node, data: { ...node.data, code } };
                if (node.data.type == 'code') {
                return { ...node, data: { ...node.data, code } };
            }
            })]
            console.log(output);
            return output;
        });
    }

    return (
        <_AIChatMessageBox>
            <CodeBlock />
            {displayedContent.map((content, index) => (
                <React.Fragment key={index}>
                    {content.type === "text" && (
                        <p style={{ whiteSpace: 'pre-wrap' }}>
                            {(content.partialText ?? content.text)?.trim()}
                        </p>
                    )}
                    {content.type === "workflow-button" && <ChatEmbeddedWorkflowButton title={content.title} workflow={content.workflow} />}
                    {content.type === "code" && <CodeBlock code={content.code} language="python" onApply={applyCode}/>}
                    {content.type === "run-log-in-chat" && (
                        <>
                            <RunLogInChatTitle>{content.title}</RunLogInChatTitle>
                            <RunLogInChatContainer>
                                {content.steps.map((step, stepIndex) => {
                                    const progress = content.stepProgress?.[stepIndex];
                                    return progress?.isVisible ? (
                                        <RunLogInChatStepItem key={stepIndex}>
                                            <StepIcon>
                                                <DynamicSvg name={step.nodeType || "Code"} customIconColor="#3C94D6" width={16} height={16} />
                                            </StepIcon>
                                            <StepContent>
                                                <StepHeader>
                                                    <StepTitle>{step.title}</StepTitle>
                                                    <StepMetadata>
                                                        <StepStatusTag type="time">
                                                            {progress.currentTime.toFixed(1)}s
                                                        </StepStatusTag>
                                                        {progress.showStatus && (
                                                            <StepStatusTag type={step.status === "success" ? "success" : "error"}>
                                                                {step.status}
                                                            </StepStatusTag>
                                                        )}
                                                    </StepMetadata>
                                                </StepHeader>
                                            </StepContent>
                                        </RunLogInChatStepItem>
                                    ) : null;
                                })}
                            </RunLogInChatContainer>
                            {content.final_state && (
                                <ButtonGroup>
                                    <FinalStateHeader onClick={() => setIsFinalStateOpen(!isFinalStateOpen)}>
                                        <ArrowIcon isOpen={isFinalStateOpen} />
                                        Final State
                                    </FinalStateHeader>
                                    {showChartsEnabled && (
                                        <ChartButton onClick={() => {
                                            setSelectedFinalState(content.final_state);
                                            setShowCharts(true);
                                        }}>
                                            <DynamicSvg name="Chart" width={16} height={16} />
                                            View Charts
                                        </ChartButton>
                                    )}
                                </ButtonGroup>
                            )}
                            {content.final_state && isFinalStateOpen && (
                                <StepFinalState isExpanded={isFinalStateOpen}>
                                    <FinalStateRenderer data={content.final_state} />
                                </StepFinalState>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))} 
            <ChartView 
                visible={showCharts}
                onHide={() => setShowCharts(false)}
                finalState={selectedFinalState}
            />
        </_AIChatMessageBox>
    )
}