import { NodeTitle, StyledHandle } from "../Node.styles";
import { NodeHeader } from "../Node.styles";
import { NodeWrapper } from "../Node.styles";
import { CustomNodeData } from "../types/nodes";
import { useState, useRef } from 'react';
import { Position } from 'reactflow';
import { DynamicSvg } from "../../../ui-components/DynamicSvg";
import { NodeHeaderButton } from "../Node.styles";
import { NodeHeaderLeft } from "../Node.styles";
import { NodeHeaderRight } from "../Node.styles";
import { NodeFieldLabel } from "../Node.styles";
import { NodeFieldDropdown } from "../Node.styles";
import { NodeBody } from "../Node.styles";
import { NodeFields } from "../Node.styles";
import { NodeTitleInput } from "../Node.styles";
import { useWorkflowStore } from '../../../stores/workflowStore';
import InProgressEffect from '../../../ui-components/InProgressEffect';

export const SelectInRevitNode = ({ data, id, selected, xPos, yPos, zIndex }: { 
  data: CustomNodeData;
  id: string;
  selected?: boolean;
  xPos: number;
  yPos: number;
  zIndex?: number;
}) => {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState(data.label || 'Select in Revit');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const updateNodeData = useWorkflowStore(state => state.updateNodeData);
  const { activeId: activeWorkflow, whichWorkflowIsRunning, nodeExecuting } = useWorkflowStore();

  if (data.type !== 'select_in_revit') {
    return null;
  }

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsTitleEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputBlur = () => {
    setIsTitleEditing(false);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsTitleEditing(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value || 'Select in Revit');
  };

  const handleSelectionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateNodeData(id, 'selectionType', e.target.value);
  };

  const handleObjectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateNodeData(id, 'revitObjectType', e.target.value);
  };

  console.log("Data:", data)

  
  return (
    <InProgressEffect
      workflowId={whichWorkflowIsRunning}
      nodeId={id}
      activeWorkflow={activeWorkflow}
      executingNodeId={nodeExecuting}
    >
      <NodeWrapper
        id={id}
        type={data.type}
        data={data}
        selected={selected || false}
        isConnectable={true}
        xPos={xPos}
        yPos={yPos}
        zIndex={zIndex || 0}
        dragging={false}
      >
        <StyledHandle type="source" position={Position.Right} />
        <StyledHandle type="target" position={Position.Left} />
        
        <NodeHeader>
          <NodeHeaderLeft>
            <DynamicSvg name="Select" customIconColor="#3C94D6" width={20} height={20} />
            {isTitleEditing ? (
              <NodeTitleInput
                ref={inputRef}
                value={title}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyDown}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <NodeTitle onDoubleClick={handleDoubleClick}>{title}</NodeTitle>
            )}
          </NodeHeaderLeft>
          <NodeHeaderRight>
            <NodeHeaderButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <DynamicSvg name={isCollapsed ? "Plus" : "Minus"} customIconColor="#7F7F7F" width={16} height={16} />
            </NodeHeaderButton>
            <NodeHeaderButton>
              <DynamicSvg name="Ellipsis" customIconColor="#7F7F7F" width={16} height={16} />
            </NodeHeaderButton>
          </NodeHeaderRight>
        </NodeHeader>

        <NodeBody>
          {!isCollapsed && (
            <NodeFields>
              <div>
                <NodeFieldLabel>Select Type of Selection:</NodeFieldLabel>
                <NodeFieldDropdown 
                  value={data.selectionType || 'Objects'}
                  onChange={handleSelectionTypeChange}
                >
                  <option value="Any">Any</option>
                  <option value="Views">Views</option>
                  <option value="Points">Points</option>
                  <option value="Wall">Wall</option>
                  <option value="Door">Door</option>
                  <option value="Window">Window</option>
                  <option value="Floor">Floor</option>
                  <option value="Ceiling">Ceiling</option>
                  <option value="Sheet">Sheet</option>
                  <option value="Grid">Grid</option>
                  <option value="Level">Level</option>
                  <option value="Area">Area</option>
                </NodeFieldDropdown>
              </div>
            </NodeFields>
          )}
        </NodeBody>
      </NodeWrapper>
    </InProgressEffect>
  );
};