import { create } from 'zustand'
import { createClient } from '@supabase/supabase-js'
import { getSecretKey } from '../utils/supabase'

let supabase: ReturnType<typeof createClient>;

export class SupabaseSocketWrapper {
  private eventHandlers: { [key: string]: ((data: any) => void)[] } = {}
  private subscription: any
  private secretKey: string
  private hashedSecretKey: string | null = null
  private isConnecting: boolean

  constructor() {
    this.secretKey = ''
    this.isConnecting = false
    this.initSecretKey()
  }

  private async initSecretKey() {
    let success = false;
    while (!success) {
      try {
        this.secretKey = await getSecretKey();
        // Initialize Supabase client with secret key
        supabase = createClient(
          process.env.REACT_APP_SUPABASE_URL ?? '',
          process.env.REACT_APP_SUPABASE_ANON_KEY ?? '',
          {
            auth: {
              persistSession: false,
              autoRefreshToken: false
            },
            global: {
              headers: {
                'x-my-secret-key': this.secretKey
              }
            }
          }
        );
        success = true;
      } catch (error) {
        console.error('Error getting secret key:', error);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
      }
    }
    // If connect was called before secret key was ready, connect now
    if (this.isConnecting) {
      this.connectWithKey()
    }
  }

  connect() {
    if (!this.secretKey) {
      // Mark that we want to connect once secret key is available
      this.isConnecting = true
      return
    }
    this.connectWithKey()
  }

  private async hashKey(key: string) {
    if (this.hashedSecretKey && key === this.secretKey) {
      return this.hashedSecretKey;
    }

    const encoder = new TextEncoder();
    const data = encoder.encode(key);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedKey = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
    if (key === this.secretKey) {
      this.hashedSecretKey = hashedKey;
    }
    
    return hashedKey;
  }

  private async encryptData(data: any, eventName: string, fromFrontend: boolean) {
    // Create combined data object with version
    const combinedData = {
      version: "1.0",
      event_name: eventName,
      from_frontend: fromFrontend,
      info: data
    };
    
    // Convert to string
    const dataString = JSON.stringify(combinedData);
    
    // Generate random salt
    const salt = crypto.getRandomValues(new Uint8Array(16));
    const saltHex = Array.from(salt).map(b => b.toString(16).padStart(2, '0')).join('');
    
    // Derive encryption key from secret key using salt
    const keyMaterial = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(this.secretKey),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );
    
    const key = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: salt,
        iterations: 10000,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-CBC', length: 256 },
      false,
      ['encrypt']
    );
    
    // Generate IV (16 bytes for CBC)
    const iv = crypto.getRandomValues(new Uint8Array(16));
    
    // Encrypt
    const encrypted = await crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv: iv
      },
      key,
      new TextEncoder().encode(dataString)
    );
    
    // Convert to base64
    const encryptedArray = new Uint8Array(encrypted);
    const encryptedBase64 = btoa(Array.from(encryptedArray, byte => String.fromCharCode(byte)).join(''));
    const ivHex = Array.from(iv).map(b => b.toString(16).padStart(2, '0')).join('');
    
    return {
      encrypted_info: `${ivHex}:${encryptedBase64}`,
      salt: saltHex
    };
  }

  private async decryptData(encryptedInfo: string, salt: string) {
    const [ivHex, encryptedBase64] = encryptedInfo.split(':');
    
    // Convert hex strings back to Uint8Array
    const iv = new Uint8Array(ivHex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    const saltArray = new Uint8Array(salt.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    
    // Derive key
    const keyMaterial = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(this.secretKey),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );
    
    const key = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: saltArray,
        iterations: 10000,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-CBC', length: 256 },
      false,
      ['decrypt']
    );
    
    // Decrypt
    const encryptedData = new Uint8Array(atob(encryptedBase64).split('').map(c => c.charCodeAt(0)));
    const decrypted = await crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: iv
      },
      key,
      encryptedData
    );
    
    const decryptedString = new TextDecoder().decode(decrypted);
    const decryptedData = JSON.parse(decryptedString);
    
    // Validate version and structure
    if (!decryptedData.version || decryptedData.version !== "1.0") {
      throw new Error("Invalid or unsupported message version");
    }

    if (!decryptedData.event_name || !('info' in decryptedData)) {
      throw new Error("Malformed message structure");
    }

    return decryptedData;
  }

  private async connectWithKey() {
    this.isConnecting = false
    this.subscription = supabase
      .channel('frontend-revit-events')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'revit_events',
          filter: `hashed_secret_key=eq.${await this.hashKey(this.secretKey)}`
        },
        async (payload) => {
          const decryptedData = await this.decryptData(payload.new.encrypted_info, payload.new.salt);
          
          if (decryptedData.from_frontend) {
            return
          }
          if (payload.new.hashed_secret_key !== (await this.hashKey(this.secretKey))) {
            console.log("WHY AM I GETTING THIS EVENT", payload.new.hashed_secret_key, await this.hashKey(this.secretKey))
            return
          }
          
          const { event_name, info } = decryptedData;
          console.log("SOCKET RECEIVED EVENT", event_name, info)
          
          // Call all handlers for this event
          if (this.eventHandlers[event_name]) {
            this.eventHandlers[event_name].forEach(handler => handler(info))
          }
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          useSocketStore.setState({ isConnected: true });
        }
      });

      console.log(this.subscription)
  }

  

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      useSocketStore.setState({ isConnected: false });
    }
  }

  async emit(eventName: string, data: any) {
    while (!this.secretKey) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    const { encrypted_info, salt } = await this.encryptData(data, eventName, true);

    await supabase.from('revit_events').insert({
      hashed_secret_key: await this.hashKey(this.secretKey),
      encrypted_info,
      salt
    });
  }

  on(eventName: string, handler: (data: any) => void) {
    if (!this.eventHandlers[eventName]) {
      this.eventHandlers[eventName] = []
    }
    this.eventHandlers[eventName].push(handler)
  }

  off(eventName: string, handler: (data: any) => void) {
    this.eventHandlers[eventName] = this.eventHandlers[eventName]?.filter(h => h !== handler) ?? []
  }
}

interface SocketStore {
  socket: SupabaseSocketWrapper | null;
  isConnected: boolean;
  setSocket: (socket: SupabaseSocketWrapper | null) => void;
  reset: (reinitialize?: boolean) => void;
}

export const useSocketStore = create<SocketStore>((set, get) => ({
  socket: null,
  isConnected: false,
  setSocket: (socket) => set({ socket }),
  reset: (reinitialize = false) => {
    // Disconnect the existing socket if it exists
    const currentSocket = get().socket;
    if (currentSocket) {
      currentSocket.disconnect();
    }
    // Reset the store
    set({ socket: null, isConnected: false });

    // Optionally reinitialize with a new socket
    if (reinitialize) {
      const newSocket = new SupabaseSocketWrapper();
      set({ socket: newSocket, isConnected: false });
      newSocket.connect();
    }
  },
}));

// This matches your existing io initialization
export const io = () => {
  const socket = new SupabaseSocketWrapper()
  return socket
}