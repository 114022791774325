import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
  height: 40px;
`;

export const TabsList = styled.div`
  display: flex;
  overflow-x: auto;
  flex-grow: 1;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

export const TabButton = styled.button<{ active?: boolean }>`
  height: 40px;
  padding: 0 16px;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.active ? '#3C94D6' : '#333'};
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-right: 1px solid #e0e0e0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  ${props => props.active && `
    background-color: white;
    border-bottom: 2px solid #3C94D6;
    font-weight: 500;
  `}
`;

export const AddTabButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #666;
  border-left: 1px solid #e0e0e0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const CloseButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 1;
  border-radius: 50%;
  color: #666;
  margin-left: 4px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
  }
`; 