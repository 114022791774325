import styled from "styled-components";
import { Sidebar, SidebarContent, SidebarHeader, SidebarHeaderButton, SidebarHeaderButtonGroup, SidebarTitle, ScrollableContent, ToggleButton } from "../../ui-components/Sidebar.styles";
import React, { useEffect } from "react";
import { DynamicSvg } from "../../ui-components/DynamicSvg";
import { NODE_TYPES } from '../WorkflowPanel/constants/nodeTypes';
import { useEditorStore } from "../../stores/editorStore";
import { useWorkflowStore } from "../../stores/workflowStore";
import { ThinkingStep } from '../ChatPanel/ThinkingStep';
import { Invites } from '../Invites/Invites';
import { SecretKey } from '../SecretKey/SecretKey';
import { WorkflowDefinition } from '../../workflow/types';
import WorkflowNameModal from './WorkflowNameModal';
import IntercomComponent from '../ChatSupport/Intercom';
import { shareWorkflow, deleteWorkflow, duplicateWorkflow } from "../../utils/supabase";
import { PluginDownload } from '../PluginDownload/PluginDownload';


const LogoSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 4px;
    margin-left: auto;
`;

const SearchInput = styled.input`
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    background: rgba(246, 246, 246, 0.44);
    
    &::placeholder {
        color: #9e9e9e;
    }
    
    &:focus {
        border-color: #3C94D6;
        background-color: white;
    }
`;

const TabContainer = styled.div`
    display: flex;
    gap: 16px;
    margin: 16px 0;
`;

const Tab = styled.button<{ isActive?: boolean }>`
    background: none;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    color: ${props => props.isActive ? '#000000' : '#666666'};
    cursor: pointer;
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${props => props.isActive ? '#3C94D6' : 'transparent'};
    }
`;

const WorkflowItem = styled.div<{ isActive?: boolean; isRunning?: boolean }>`
    padding: 16px;
    background: ${props => {
        if (props.isRunning && props.isActive) return '#F0F7FF';  // Blue when running & active
        if (props.isRunning) return '#E8F5E9';  // Green when just running
        if (props.isActive) return '#F0F7FF';   // Blue when just active
        return 'white';
    }};
    border: 1px solid ${props => {
        if (props.isRunning && props.isActive) return '#3C94D6';  // Blue border when running & active
        if (props.isRunning) return '#4CAF50';  // Green border when just running
        if (props.isActive) return '#3C94D6';   // Blue border when just active
        return '#e0e0e0';
    }};
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    
    &:hover {
        background: ${props => {
            if (props.isRunning && props.isActive) return '#F0F7FF';  // Keep blue on hover when running & active
            if (props.isRunning) return '#E8F5E9';  // Keep green on hover when just running
            if (props.isActive) return '#F0F7FF';   // Keep blue on hover when just active
            return '#f8f8f8';
        }};
    }
`;

const WorkflowTitle = styled.div`
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const WorkflowMetadata = styled.div`
    display: flex;
    gap: 12px;
    font-size: 12px;
    color: #666666;
    align-items: center;
    min-height: 20px;
`;

const EfficacyText = styled.span<{ value: number }>`
    color: ${props => props.value >= 90 ? '#2E7D32' : '#1976D2'};
`;

interface WorkflowData {
    id: string;
    title: string;
    blocks: number;
    efficacy: number;
}

interface BlockItem {
    title: string;
    icon: string;
}

interface BlockData {
    title: string;
    description: string;
    icon: string;
    nodeType: string;
    items: BlockItem[];
}

const blocks: BlockData[] = [
    {
        title: 'Start',
        description: 'Every workflow must start with a Start node',
        icon: 'Play',
        nodeType: NODE_TYPES.start,
        items: [
        ]
    },
    { 
        title: 'AI Action', 
        description: 'Harness AI for Revit automation tasks',
        icon: 'AISparkles_colored',
        nodeType: NODE_TYPES.ai_prompt,
        items: [

        ]
    },
    {
        title: 'Code',
        description: 'Run python code in Revit',
        icon: 'Code',
        nodeType: NODE_TYPES.code,
        items: [

        ]
    },
    {
        title: 'Select in Revit',
        description: 'Select elements in Revit',
        icon: 'Select',
        nodeType: NODE_TYPES.select_in_revit,
        items: [
        ]
    },
    {
        title: 'User Input',
        description: 'Ask the user for input',
        icon: 'UserInput',
        nodeType: NODE_TYPES.user_input,
        items: [
        ]
    },
    {
        title: 'Branch',
        description: 'Split the workflow into multiple paths',
        icon: 'Branch',
        nodeType: NODE_TYPES.branch,
        items: [
        ]
    }
];

const BlockSection = styled.div`
    padding: 10px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    
    &:hover {
        background: #fafafa;
    }
`;

const BlockHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`;

const BlockTitleGroup = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const BlockTextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const BlockTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #000000;
`;

const BlockDescription = styled.div`
    font-size: 12px;
    color: #666666;
`;

const BlockItemList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
`;

const BlockItem = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666666;
    padding: 8px;
    background: #f5f5f5;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    width: 100%;
    user-select: none;

    &:hover {
        background: #eeeeee;
    }
`;

const BlockItemText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoreButton = styled(BlockItem)`
    background: none;
    &:hover {
        background: none;
    }
`;

const ChevronRight = styled.span`
    margin-left: auto;
    color: #666666;
`;

const WorkflowItemContainer = styled.div`
    margin-bottom: 8px;
`;

interface ThinkingState {
    stepTitle: string;
    // Add other thinking state properties if needed
}

interface ThinkingStates {
    [workflowId: string]: ThinkingState | undefined;
}

const EmailButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    
    &:hover {
        opacity: 0.8;
    }
`;

const SecretKeyButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    
    &:hover {
        opacity: 0.8;
    }
`;

const ChatWithSupportButton = styled.button`
    background: none;
    border: 1px solid #3C94D6;
    border-radius: 6px;
    padding: 8px 12px;
    color: #3C94D6;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    
    &:hover {
        background: #f0f7ff;
    }
`;

const CreateWorkflowButton = styled.button`
    background: #3C94D6;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    
    &:hover {
        background: #2d7ab8;
    }
`;

const EditButton = styled.button`
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    opacity: 0.6;
    
    &:hover {
        opacity: 1;
    }
`;

const MenuButton = styled.button`
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    opacity: 0.6;
    
    &:hover {
        opacity: 1;
    }
`;

const MenuPopover = styled.div`
    position: fixed;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

const MenuItem = styled.button`
    width: 100%;
    padding: 8px 16px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: ${props => props.color || '#000000'};

    &:hover {
        background: #f5f5f5;
    }
`;

const SuccessBanner = styled.div`
    background-color: rgba(198, 246, 213, 0.95);
    color: #2F855A;
    padding: 8px 16px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    max-width: 90%;
    font-size: 14px;
    border: 1px solid #C6F6D5;
    display: flex;
    align-items: center;
    gap: 12px;
`;

const PluginButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    
    &:hover {
        opacity: 0.8;
    }
`;

const LibraryPanel = () => {
    // State
    const [activeTab, setActiveTab] = React.useState('my-workflows');
    const [activeWorkflowId, setActiveWorkflowId] = React.useState('');
    const [thinkingStates, setThinkingStates] = React.useState<ThinkingStates>({});
    const [showInvites, setShowInvites] = React.useState(false);
    const [showSecretKey, setShowSecretKey] = React.useState(false);
    const [showNameModal, setShowNameModal] = React.useState(false);
    const [editingWorkflowId, setEditingWorkflowId] = React.useState<string | null>(null);
    const [showSupport, setShowSupport] = React.useState(false);
    const [menuWorkflowId, setMenuWorkflowId] = React.useState<string | null>(null);
    const [menuPosition, setMenuPosition] = React.useState({ top: 0, right: 0 });
    const [banner, setBanner] = React.useState<{ message: string; visible: boolean }>({ message: '', visible: false });
    const [showPluginDownload, setShowPluginDownload] = React.useState(false);

    // Store selectors
    const setChatWorkflowId = useEditorStore(state => state.setChatWorkflowId);
    const changeActiveWorkflow = useWorkflowStore(state => state.changeActiveId);
    const workflows = useWorkflowStore(state => state.workflows);
    const getThinkingStateGivenWorkflowId = useEditorStore(state => state.getThinkingStateGivenWorkflowId);
    const getThinkingState = useEditorStore(state => state.getThinkingState);
    const chatThreadId = useEditorStore(state => state.getChatThreadId());
    const addChat = useEditorStore(state => state.addChat);
    const createWorkflow = useWorkflowStore(state => state.createWorkflow);
    const updateWorkflowName = useWorkflowStore(state => state.updateWorkflowName);
    const addWorkflow = useWorkflowStore(state => state.addWorkflow);
    const { isLeftSidebarCollapsed, toggleLeftSidebar } = useEditorStore();
    const whichWorkflowIsRunning = useWorkflowStore(state => state.whichWorkflowIsRunning);
    const deleteWorkflowLocally = useWorkflowStore(state => state.deleteWorkflowLocally);
    const activeChatId = useEditorStore(state => state.activeId);

    // Handlers
    const handleWorkflowClick = React.useCallback(async (workflowId: string, chatId?: string) => {
        await changeActiveWorkflow(workflowId);
        await setChatWorkflowId(workflowId, chatId);
        setActiveWorkflowId(workflowId);
    }, [setChatWorkflowId, changeActiveWorkflow]);

    const handleCreateWorkflow = async (name: string) => {
        return await createWorkflow(name);
    };

    const handleEditWorkflow = (workflowId: string) => {
        setEditingWorkflowId(workflowId);
        setShowNameModal(true);
    };

    const handleNameSubmit = async (name: string) => {
        if (editingWorkflowId) {
            await updateWorkflowName(editingWorkflowId, name);
            setEditingWorkflowId(null);
            showBanner('Workflow renamed successfully');
        } else {
            const result = await handleCreateWorkflow(name);
            if (result) {
                await handleWorkflowClick(result.workflowId, result.chatId);
                showBanner('Workflow created successfully');
            }
        }
    };

    const handleDragStart = (event: React.DragEvent, blockType: string) => {
        event.dataTransfer.setData('application/reactflow', blockType);
        event.dataTransfer.effectAllowed = 'move';
    };

    // Effects
    useEffect(() => {
        // Filter workflows to only include non-shared ones
        const nonSharedWorkflowIds = Object.entries(workflows)
            .filter(([_, workflow]) => !workflow.is_shared)
            .map(([id]) => id);

        const allWorkflowIds = Object.keys(workflows);

        if (nonSharedWorkflowIds.length > 0 && !activeWorkflowId) {
            handleWorkflowClick(nonSharedWorkflowIds[0]);
        }
        else if (activeWorkflowId && !allWorkflowIds.includes(activeWorkflowId) && nonSharedWorkflowIds.length > 0) {
            handleWorkflowClick(nonSharedWorkflowIds[0]);
        }
        else if (activeWorkflowId && !allWorkflowIds.includes(activeWorkflowId) && nonSharedWorkflowIds.length === 0 && activeWorkflowId !== 'default') {
            handleWorkflowClick('default');
        }
    }, [workflows, activeWorkflowId, handleWorkflowClick]);

    // Force re-render when thinking state changes
    useEffect(() => {
        const interval = setInterval(() => {
            const newThinkingState = getThinkingState(activeChatId);
            setThinkingStates(prev => {
                if (JSON.stringify(prev[activeChatId]) !== JSON.stringify(newThinkingState)) {
                    return { 
                        ...prev, 
                        [activeChatId]: newThinkingState || undefined 
                    };
                }
                return prev;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [activeChatId, getThinkingState]);

    // Add this function to check for existing start node
    const hasStartNode = (workflowId: string) => {
        const workflow = workflows[workflowId];
        return workflow?.nodes?.some(node => node.type === NODE_TYPES.start);
    };

    const handleSupportClick = () => {
        setShowSupport(!showSupport);
    };


    const handleMenuClick = (e: React.MouseEvent, workflowId: string) => {
        e.stopPropagation();
        
        // Get the button position to position the menu
        const button = e.currentTarget as HTMLElement;
        const rect = button.getBoundingClientRect();
        
        // Position the menu to the right of the button and 10px higher
        setMenuPosition({
            top: rect.top - 60, // Subtract 10px from the top position
            right: window.innerWidth - rect.right + 28
        });
        
        setMenuWorkflowId(workflowId === menuWorkflowId ? null : workflowId);
    };

    const handleDeleteWorkflow = async (e: React.MouseEvent, workflowId: string) => {
        e.stopPropagation();
        const isConfirmed = window.confirm('Are you sure you want to delete this workflow?');
        if (isConfirmed) {
            const { data: deletedWorkflows } = await deleteWorkflow(workflowId);
            if (deletedWorkflows) {
                deletedWorkflows.forEach(workflow => deleteWorkflowLocally(String(workflow.id)));
            }
            setMenuWorkflowId(null);
            showBanner('Workflow deleted successfully');
        }
    };

    const handleShareWorkflow = async (e: React.MouseEvent, workflowId: string) => {
        e.stopPropagation();
        var sharedData = await shareWorkflow(workflowId);
        if (sharedData.id && sharedData.workflow){
            addWorkflow(sharedData.id, sharedData.workflow);
        }
        setMenuWorkflowId(null);
        showBanner('Workflow shared successfully');
    };

    const handleDuplicateWorkflow = async (e: React.MouseEvent, workflowId: string) => {
        e.stopPropagation();
        const result = await duplicateWorkflow(workflowId);
        if (result.id && result.workflow) {
            await addWorkflow(result.id, result.workflow);
            await addChat(result.chatId, result.id, true);
            showBanner('Workflow duplicated successfully');
        }
        handleWorkflowClick(String(result.id));
        setMenuWorkflowId(null);
    };

    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = () => setMenuWorkflowId(null);
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    // Add this function to show banner
    const showBanner = (message: string) => {
        setBanner({ message, visible: true });
        setTimeout(() => {
            setBanner(prev => ({ ...prev, visible: false }));
        }, 3000);
    };

    return (
        <Sidebar isCollapsed={isLeftSidebarCollapsed} sidebarPosition="left" width={350}>
            {banner.visible && (
                <SuccessBanner>
                    {banner.message}
                </SuccessBanner>
            )}
            <ToggleButton 
                onClick={() => toggleLeftSidebar(!isLeftSidebarCollapsed)}
                sidebarPosition="left"
            >
                <DynamicSvg 
                    name={isLeftSidebarCollapsed ? "ChevronRight" : "ChevronLeft"} 
                    width={12} 
                    height={12}
                    customIconColor="#3C94D6"
                />
            </ToggleButton>
            
            <SidebarContent isCollapsed={isLeftSidebarCollapsed}>
                <LogoSection>
                    <DynamicSvg name="ArchiLabsLogo" />
                    <ButtonGroup>
                        <PluginButton onClick={() => setShowPluginDownload(true)}>
                            <DynamicSvg name="Plugin" width={20} height={20} customIconColor="#3C94D6" />
                        </PluginButton>
                        <SecretKeyButton onClick={() => setShowSecretKey(true)}>
                            <DynamicSvg name="Key" width={20} height={20} customIconColor="#3C94D6" />
                        </SecretKeyButton>
                        <EmailButton onClick={() => setShowInvites(true)}>
                            <DynamicSvg name="Email" width={20} height={20} customIconColor="#3C94D6" />
                        </EmailButton>
                    </ButtonGroup>
                </LogoSection>
                
                <ChatWithSupportButton onClick={handleSupportClick}>
                    {showSupport ? 'Close Support Chat' : 'Chat With Support'}
                </ChatWithSupportButton>

                <SecretKey
                    isOpen={showSecretKey}
                    onClose={() => setShowSecretKey(false)}
                />
                <Invites 
                    isOpen={showInvites}
                    onClose={() => setShowInvites(false)}
                />
                <IntercomComponent 
                    isOpen={showSupport}
                    onClose={() => setShowSupport(false)}
                    showNewMessage={showSupport}
                    externalOpen={() => setShowSupport(true)}
                />
                {/** 
                <SidebarHeader buttonsNearTitle={true}>
                    <SidebarTitle style={{marginTop: '-2px'}}>Library</SidebarTitle>
                    <SidebarHeaderButtonGroup>
                        <SidebarHeaderButton>
                            <DynamicSvg name="Open" customIconColor="#3C94D6" width={20} height={20} />
                        </SidebarHeaderButton>
                    </SidebarHeaderButtonGroup>
                </SidebarHeader>
                
                <SearchInput 
                    placeholder="Search or ask anything..."
                    type="text"
                />
                **/}
                <TabContainer>
                    <Tab 
                        isActive={activeTab === 'my-workflows'}
                        onClick={() => setActiveTab('my-workflows')}
                    >
                        My Workflows
                    </Tab>
                    <Tab 
                        isActive={activeTab === 'shared-workflows'}
                        onClick={() => setActiveTab('shared-workflows')}
                    >
                        Shared Workflows
                    </Tab>
                    <Tab 
                        isActive={activeTab === 'blocks'}
                        onClick={() => setActiveTab('blocks')}
                    >
                        Blocks
                    </Tab>
                </TabContainer>
                {activeTab === 'my-workflows' && (
                    <CreateWorkflowButton onClick={() => setShowNameModal(true)}>
                        <DynamicSvg name="Plus" width={16} height={16} customIconColor="white" />
                        New Workflow
                    </CreateWorkflowButton>
                )}
                <ScrollableContent>
                    {(activeTab === 'my-workflows' || activeTab === 'shared-workflows') && Object.entries(workflows)
                            .filter(([_, workflow]) => activeTab === 'my-workflows' ? !workflow.is_shared : workflow.is_shared)
                            .map(([id, workflow]) => {
                        const thinkingState = getThinkingStateGivenWorkflowId(id);
                        return (
                            <WorkflowItem 
                                key={id}
                                isActive={id == activeWorkflowId}
                                isRunning={id == whichWorkflowIsRunning}
                                onClick={() => handleWorkflowClick(id)}
                            >
                                <WorkflowTitle>
                                    {workflow.state?.name || `Workflow ${id.slice(0, 8)}`}
                                    {!workflow.is_shared && (
                                        <>
                                            <MenuButton onClick={(e) => handleMenuClick(e, id)}>
                                                <DynamicSvg name="ThreeDots" width={16} height={16} />
                                            </MenuButton>
                                            {menuWorkflowId === id && (
                                                <MenuPopover 
                                                    onClick={e => e.stopPropagation()}
                                                    style={{
                                                        top: `${menuPosition.top}px`,
                                                        right: `${menuPosition.right}px`
                                                    }}
                                                >
                                                    <MenuItem onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditWorkflow(id);
                                                        setMenuWorkflowId(null);
                                                    }}>
                                                        Rename
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => handleShareWorkflow(e, id)}>
                                                        <DynamicSvg name="Share" width={16} height={16} />
                                                        Share
                                                    </MenuItem>
                                                    <MenuItem 
                                                        onClick={(e) => handleDeleteWorkflow(e, id)}
                                                        color="#DC3545"
                                                    >
                                                        <DynamicSvg name="Trash" width={16} height={16} customIconColor="#DC3545" />
                                                        Delete
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => handleDuplicateWorkflow(e, id)}>
                                                        <DynamicSvg name="Copy" width={16} height={16} />
                                                        Duplicate
                                                    </MenuItem>
                                                </MenuPopover>
                                            )}
                                        </>
                                    )}
                                    {workflow.is_shared && (
                                        <>
                                        <MenuButton onClick={(e) => handleMenuClick(e, id)}>
                                            <DynamicSvg name="ThreeDots" width={16} height={16} />
                                        </MenuButton>
                                        {menuWorkflowId === id && (
                                            <MenuPopover 
                                                onClick={e => e.stopPropagation()}
                                                style={{
                                                    top: `${menuPosition.top}px`,
                                                    right: `${menuPosition.right}px`
                                                }}
                                            >
                                                <MenuItem onClick={(e) => handleDuplicateWorkflow(e, id)}>
                                                    <DynamicSvg name="Copy" width={16} height={16} />
                                                    Duplicate
                                                </MenuItem>
                                            </MenuPopover>
                                        )}
                                        </>                                    )}
                                </WorkflowTitle>
                                <WorkflowMetadata>
                                    <span>{workflow.nodes?.length || 0} Blocks</span>
                                    {thinkingState && (
                                        <ThinkingStep 
                                            step={thinkingState.stepTitle} 
                                            fontSize="12px"
                                        />
                                    )}
                                </WorkflowMetadata>
                            </WorkflowItem>
                        );
                    })}

                    {activeTab === 'blocks' && blocks
                        .filter(block => {
                            // Only show start node block if there isn't one already
                            if (block.nodeType === NODE_TYPES.start) {
                                return !hasStartNode(activeWorkflowId);
                            }
                            return true;
                        })
                        .map((block, index) => (
                            <BlockSection
                                key={index}
                                draggable
                                onDragStart={(e) => handleDragStart(e, block.nodeType)}
                                onClick={() => {
                                    console.log('Block section clicked');
                                }}
                            >
                                <BlockHeader>
                                    <BlockTitleGroup>
                                        <DynamicSvg name={block.icon} width={30} height={30} />
                                        <BlockTextContent>
                                            <BlockTitle>{block.title}</BlockTitle>
                                            <BlockDescription>
                                                {block.description}
                                            </BlockDescription>
                                        </BlockTextContent>
                                    </BlockTitleGroup>

                                </BlockHeader>
                                <BlockItemList>
                                    {block.items.map((item, itemIndex) => {
                                        if (item.title === 'More') {
                                            return (
                                                <MoreButton
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, block.nodeType)}
                                                >
                                                    <DynamicSvg name={item.icon} width={16} height={16} />
                                                    <BlockItemText>{item.title}</BlockItemText>
                                                </MoreButton>
                                            )
                                        }
                                        return (
                                            <BlockItem>
                                                <DynamicSvg name={item.icon} width={16} height={16} />
                                                <BlockItemText>{item.title}</BlockItemText>
                                            </BlockItem>
                                        )
                                    })}
                                </BlockItemList>
                            </BlockSection>
                        ))}
                </ScrollableContent>
            </SidebarContent>
            <WorkflowNameModal
                isOpen={showNameModal}
                onClose={() => {
                    setShowNameModal(false);
                    setEditingWorkflowId(null);
                }}
                onSubmit={handleNameSubmit}
                initialName={editingWorkflowId ? workflows[editingWorkflowId]?.state?.name : ''}
            />
            <PluginDownload
                isOpen={showPluginDownload}
                onClose={() => setShowPluginDownload(false)}
            />
        </Sidebar>
    );
};

export default LibraryPanel;
