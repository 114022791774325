import styled from 'styled-components';

interface SidebarProps {
  isCollapsed: boolean;
  sidebarPosition: 'left' | 'right';
  width?: number;
}

interface ToggleButtonProps {
  sidebarPosition: 'left' | 'right';
}

interface SidebarContentProps {
  isCollapsed: boolean;
}

export const Sidebar = styled.div<SidebarProps>`
  width: ${props => props.isCollapsed ? '50px' : props.width ? `${props.width}px` : '250px'};
  background-color: #FFFFFF;
  ${props => props.sidebarPosition === 'left' ? 'border-right' : 'border-left'}: 1px solid #dee2e6;
  padding: ${props => props.isCollapsed ? '10px 5px' : '10px 20px'};
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100vh;
`;

export const ToggleButton = styled.button<ToggleButtonProps>`
  position: absolute;
  top: 10px;
  ${props => props.sidebarPosition === 'left' ? 'right: 0px' : 'left: 0px'};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &:hover {
    background: #e9ecef;
    border-radius: 4px;
  }
`;

export const SidebarContent = styled.div<SidebarContentProps>`
  opacity: ${props => props.isCollapsed ? 0 : 1};
  transition: opacity 0.2s ease;
  visibility: ${props => props.isCollapsed ? 'hidden' : 'visible'};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SidebarSection = styled.div`
  margin-bottom: 20px;
`;

export const SidebarTitle = styled.h2`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 0 10px 0 0;
`;

interface SidebarHeaderProps {
    buttonsNearTitle?: boolean;
}

export const SidebarHeader = styled.div<SidebarHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.buttonsNearTitle ?  'flex-start' : 'space-between'};
  padding-bottom: 15px;
  padding-top: 10px;
  flex: 0;
`;

export const SidebarHeaderButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SidebarHeaderButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

export const HistoryItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #eee;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  small {
    color: #666;
    font-size: 0.8em;
  }

  button {
    opacity: 0;
  }

  &:hover button {
    opacity: 1;
  }
`;

export const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-right: -10px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
`; 