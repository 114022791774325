import React, { useEffect, useState } from 'react';
import { supabase, getIntercomSecret } from '../../utils/supabase';

// Declare the Intercom types for TypeScript
declare global {
  interface Window {
    Intercom: any;
  }
}

interface IntercomComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  showNewMessage?: boolean;
  externalOpen?: () => void;   
}

export default function IntercomComponent({ isOpen, onClose, showNewMessage, externalOpen }: IntercomComponentProps) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Add Intercom script
    const script = document.createElement('script');
    script.innerHTML = `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pvwhgg07';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `;
    document.body.appendChild(script);

    const initIntercom = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const intercomSecret = await getIntercomSecret();

      window.Intercom('boot', {
        app_id: 'pvwhgg07',
        user_id: user.id,
        name: "ArchiLabs User",
        email: user.email,
        created_at: new Date(user.created_at).getTime(),
        user_hash: intercomSecret,
        hide_default_launcher: true,
      });
      setIsInitialized(true);
    };

    // Wait a short moment for the script to load before initializing
    setTimeout(initIntercom, 1000);

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
        setIsInitialized(false);
      }
      // Remove the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isInitialized && window.Intercom) {
      // Add event listeners for Intercom show/hide
      window.Intercom('onShow', () => {
        if (externalOpen) {
          externalOpen();
        }
        // Add your onShow logic here
      });

      window.Intercom('onHide', () => {
        if (onClose) {
          onClose();
        }
      });

      console.log("isOpen", isOpen);
      if (isOpen) {
        window.Intercom('show');
      } else {
        window.Intercom('hide');
      }
    }
  }, [isOpen, showNewMessage, isInitialized]);

  return null;
}