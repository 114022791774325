import * as monaco from 'monaco-editor';
import { getLangGraphSupabaseClient } from '../../utils/langgraphSupabase';
import { supabase } from '../../utils/supabase';
import { Client } from "@langchain/langgraph-sdk";

// Instead of direct initialization, create a lazy-loaded singleton
let serviceInstance: any = null;
const getClient = async () => {
  if (!serviceInstance) {
    serviceInstance = await getLangGraphSupabaseClient(supabase);
  }
  return serviceInstance.getClient();
};

interface RevitDocResult {
  id: string;
  name: string;
  namespace: string;
  description: string;
  syntax?: string;
  remarks?: string;
  parameters?: Array<{
    name: string;
    type: string;
    description: string;
  }>;
  returns?: {
    type: string;
    description: string;
  };
}

async function fetchRevitDocs(word: string, line_context: string): Promise<RevitDocResult[]> {
  try {
    console.log("Fetching Revit docs for", word, "in context", line_context);
    // Use the lazy-loaded client
    const client = await getClient();
    const thread = await client.threads.create();
    const stream = client.runs.stream(
      thread.thread_id,
      "code_hover",
      {
        input: {
          element_name: word,
          line_context: line_context
        },
        streamMode: ["values"],
        streamSubgraphs: true,
      }
    );

    let docs: RevitDocResult[] = [];
    for await (const chunk of stream) {
      if (chunk.event === "values" && chunk.data.code_hover_result) {
        docs = chunk.data.code_hover_result.map((doc: any) => doc.doc_json_representation);
      }
    }
    return docs;
  } catch (error) {
    console.error('Error fetching Revit documentation:', error);
    return [];
  }
}

function formatDocumentation(doc: RevitDocResult): monaco.IMarkdownString {
  let markdown = [
    `### ${doc.name}`,
    `**Namespace:** ${doc.namespace}`,
    '',
    doc.description,
    ''
  ];

  if (doc.syntax) {
    markdown.push('**Syntax:**', '```python', doc.syntax, '```', '');
  }

  if (doc.parameters && doc.parameters.length > 0) {
    markdown.push('**Parameters:**');
    doc.parameters.forEach(param => {
      markdown.push(`- \`${param.name}\` *(${param.type})*: ${param.description}`);
    });
    markdown.push('');
  }

  if (doc.returns) {
    markdown.push(
      '**Returns:**',
      `*(${doc.returns.type})* ${doc.returns.description}`,
      ''
    );
  }

  if (doc.remarks) {
    markdown.push('**Remarks:**', doc.remarks);
  }

  return {
    value: markdown.join('\n'),
    isTrusted: true,
    supportHtml: true
  };
}

export const revitHoverProvider = {
  // Match common Revit API patterns:
  // - Capitalized words (classes/types)
  // - Words ending in common Revit suffixes
  pattern: /^(([A-Z][a-zA-Z0-9]*)|(.*(Element|Type|Instance|Document|Category|Parameter|Family|Symbol|View|Material|Reference|Filter|Collector)))$/,
  
  getContentAsync: async (word: string, line_context: string) => {
    const docs = await fetchRevitDocs(word, line_context);
    
    if (docs.length === 0) {
      return `No documentation found for \`${word}\``;
    }

    // If multiple results, combine them
    if (docs.length > 1) {
      return {
        value: docs.map(doc => formatDocumentation(doc).value).join('\n\n---\n\n'),
        isTrusted: true,
        supportHtml: true
      };
    }

    return formatDocumentation(docs[0]);
  }
}; 