import { Node, Edge } from 'reactflow';
import { WorkflowData } from '../utils/supabase';

/**
 * Creates a mapping of old node IDs to new node IDs
 */
export const createIdMapping = (nodes: Node[]): { [key: string]: string } => {
  return nodes.reduce((mapping, node) => {
    const newId = `${node.type}_${crypto.randomUUID().replace(/-/g, '')}`;
    mapping[node.id] = newId;
    return mapping;
  }, {} as { [key: string]: string });
};

/**
 * Creates copies of nodes with new IDs and optional position offset
 */
export const copyNodes = (
  nodes: Node[],
  idMapping: { [key: string]: string },
  offset?: { x: number; y: number }
): Node[] => {
  return nodes.map(node => ({
    ...node,
    id: idMapping[node.id],
    position: offset ? {
      x: node.position.x + offset.x,
      y: node.position.y + offset.y
    } : { ...node.position },
    selected: false,
  }));
};

/**
 * Creates copies of edges with new IDs and updated source/target references
 */
export const copyEdges = (
  edges: Edge[],
  idMapping: { [key: string]: string }
): Edge[] => {
  return edges.map(edge => ({
    ...edge,
    id: `edge-${crypto.randomUUID()}`,
    source: idMapping[edge.source],
    target: idMapping[edge.target],
    selected: false,
  }));
};

/**
 * Main function to duplicate a selection of nodes and edges
 */
export const duplicateSelection = (
  selectedNodes: Node[],
  relatedEdges: Edge[],
  offset: { x: number; y: number } = { x: 50, y: 50 }
): { nodes: Node[]; edges: Edge[] } => {
  const idMapping = createIdMapping(selectedNodes);
  const newNodes = copyNodes(selectedNodes, idMapping, offset);
  const newEdges = copyEdges(relatedEdges, idMapping);

  return {
    nodes: newNodes,
    edges: newEdges,
  };
};


export const sanitizeWorkflowData = (workflowData: WorkflowData) => {
    // Deep clone the workflow data to avoid modifying the original
    const sanitizedData = JSON.parse(JSON.stringify(workflowData));
  
    // Traverse through nodes to find and sanitize user_input nodes
    if (sanitizedData.nodes) {
      sanitizedData.nodes = sanitizedData.nodes.map((node: any) => {
        if (node.data?.type === 'user_input' && node.data.inputs) {
          // Remove file data from inputs
          node.data.inputs = node.data.inputs.map((input: any) => ({
            ...input,
            runtime_file: undefined,
            default_file: undefined,
            runtime_value: undefined
          }));
        }
        return node;
      });
    }
  
    return sanitizedData;
  }