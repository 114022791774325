import React from 'react';
import styled from 'styled-components';
import { DynamicSvg } from '../../ui-components/DynamicSvg';
import { useWorkflowStore } from '../../stores/workflowStore';
// import mockWorkflow from "../../mock-data/room-area-workflow.js";
import mockWorkflow from "../../mock-data/mod-room-windows-workflow-1.js";
import mockRoomAreaWorkflow from "../../mock-data/room-area-workflow.js";
import mockRoomAreaWorkflowFunction from "../../mock-data/room-area-workflow-function.js";
import mockModWindows1Workflow from "../../mock-data/mod-room-windows-workflow-1.js";
import mockModWindows1WorkflowFunction from "../../mock-data/mod-room-windows-workflow-1-function.js";
import mockModWindows2Workflow from "../../mock-data/mod-room-windows-workflow-2.js";
import mockModWindows2WorkflowFunction from "../../mock-data/mod-room-windows-workflow-2-function.js";
import mockAddTrayCeilingWorkflow from "../../mock-data/add-tray-ceiling-workflow.js";
import { CustomNodeData } from '../WorkflowPanel/types/nodes';
import { Edge, Node } from 'reactflow';
import { Workflow } from '../../types/message';

const _ChatEmbeddedWorkflowButton = styled.div`
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    background: #FBFBFB;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: row;
`;

const _ChatEmbeddedWorkflowButtonIconContainer = styled.div`
    width: 48px;
    align-self: stretch;
    background: #E3E3E3;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const _ChatEmbeddedWorkflowButtonTextContainer = styled.div`
    flex: 1;
    padding: 10px;
`;

const _ChatEmbeddedWorkflowButtonTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin: 0;
`;
const _ChatEmbeddedWorkflowButtonSubtitle = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: #7F7F7F;
    margin: 0;
`;

const ChatEmbeddedWorkflowButton = ({title, workflow}: {title: string, workflow: Workflow | undefined}) => {
    const {nodes, edges, setNodes, setEdges, setGlobalWorkflowState} = useWorkflowStore();


    const buildWorkflow = () => {
        console.log('Building workflow');
        setNodes([]);
        setEdges([]);
        setGlobalWorkflowState({});
        // HACK: delay for 0.1 second to allow for setNodes, setEdges, and setState to take effect
        setTimeout(() => {
            if (title.toLowerCase().includes("area")) {
                setNodes(mockRoomAreaWorkflowFunction.nodes as Node<CustomNodeData>[]);
                setEdges(mockRoomAreaWorkflowFunction.edges as Edge[]);
                setGlobalWorkflowState(mockRoomAreaWorkflowFunction.state as Record<string, any>);
            } else if (title.toLowerCase().includes("windows") && !title.toLowerCase().includes("2")) {
                setNodes(mockModWindows1WorkflowFunction.nodes as Node<CustomNodeData>[]);
                setEdges(mockModWindows1WorkflowFunction.edges as Edge[]);
                setGlobalWorkflowState(mockModWindows1WorkflowFunction.state as Record<string, any>);
            } else if (title.toLowerCase().includes("windows") && title.toLowerCase().includes("2")) {
                setNodes(mockModWindows2WorkflowFunction.nodes as Node<CustomNodeData>[]);
                setEdges(mockModWindows2WorkflowFunction.edges as Edge[]);
                setGlobalWorkflowState(mockModWindows2WorkflowFunction.state as Record<string, any>);
            } else if (title.toLowerCase().includes("tray ceiling")) {
                setNodes(mockAddTrayCeilingWorkflow.nodes as Node<CustomNodeData>[]);
                setEdges(mockAddTrayCeilingWorkflow.edges as Edge[]);
            }
            else{
                setNodes(workflow?.nodes as Node<CustomNodeData>[]);
                setEdges(workflow?.edges as Edge[]);
            }
        }, 100);
    }

    return (
        <_ChatEmbeddedWorkflowButton onClick={buildWorkflow}>
            <_ChatEmbeddedWorkflowButtonIconContainer>
                <DynamicSvg name="Workflow" customIconColor="#7F7F7F" />
            </_ChatEmbeddedWorkflowButtonIconContainer>
            <_ChatEmbeddedWorkflowButtonTextContainer>
                <_ChatEmbeddedWorkflowButtonTitle>
                    {title}
                </_ChatEmbeddedWorkflowButtonTitle>
                <_ChatEmbeddedWorkflowButtonSubtitle>
                    Click to add workflow to worksheet
                </_ChatEmbeddedWorkflowButtonSubtitle>
            </_ChatEmbeddedWorkflowButtonTextContainer>
        </_ChatEmbeddedWorkflowButton>
    );
};

export default ChatEmbeddedWorkflowButton;