import { getLangGraphSupabaseClient } from '../../utils/langgraphSupabase';
import { supabase } from '../../utils/supabase';
import { Client } from "@langchain/langgraph-sdk";


// Instead of direct initialization, create a lazy-loaded singleton
let serviceInstance: any = null;
const getClient = async () => {
  if (!serviceInstance) {
    serviceInstance = await getLangGraphSupabaseClient(supabase);
  }
  return serviceInstance.getClient();
};

export type EditOperationType = "replace" | "insert" | "delete";

export interface EditOperation {
  operation_type: EditOperationType;
  start_line: number;
  end_line?: number;
  content?: string;
}

export interface CodeEditResult {
  operations: EditOperation[];
  explanation: string;
}

export interface LineRange {
  start_line: number;
  end_line: number;
}

export interface CodeEditRequest {
  selected_code: string;
  selected_code_context: string;
  full_file_context: string;
  edit_instructions: string;
  selection_range: LineRange;
  context_range: LineRange;
  total_lines: number;
}

export interface CodeEditResponse {
  edit_result: CodeEditResult;
}

export async function submitCodeEdit(request: CodeEditRequest): Promise<CodeEditResponse> {
  try {
    // Use the lazy-loaded client
    const client = await getClient();
    const stream = client.runs.stream(
      null, // Threadless run
      "ad_hoc_code_edit", // Graph ID
      {
        input: {
          selected_code: request.selected_code,
          selected_code_context: request.selected_code_context,
          full_file_context: request.full_file_context,
          edit_instructions: request.edit_instructions,
          selection_range: request.selection_range,
          context_range: request.context_range,
          total_lines: request.total_lines,
          messages: [] // Required by MessagesState base class
        },
        streamMode: ["values"],
      }
    );

    for await (const chunk of stream) {
      if (chunk.event === "values") {
        if (chunk.data.edit_result) {
          return { edit_result: chunk.data.edit_result };
        }
      }
    }

    throw new Error("No edit result received");
  } catch (error) {
    console.error('Error submitting code edit:', error);
    throw error;
  }
} 