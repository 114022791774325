import React from 'react';
import styled, { keyframes } from 'styled-components';

const dotsAnimation = keyframes`
  0%, 20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60%, 100% {
    content: "...";
  }
`;

const ThinkingContainer = styled.div<{ fontSize?: string }>`
  padding: 0;
  color: #666;
  font-size: ${props => props.fontSize || '14px'};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  min-height: 0;
`;

const AnimatedDots = styled.span`
  &::after {
    content: "";
    animation: ${dotsAnimation} 1.5s infinite;
  }
`;

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 1;
    padding: 0;
`;

interface ThinkingStepProps {
  step?: string;
  fontSize?: string;
}

export const ThinkingStep: React.FC<ThinkingStepProps> = ({ 
  step = "Planning the next step",
  fontSize
}) => {
  return (
    <ThinkingContainer fontSize={fontSize}>
      {step}<AnimatedDots/>
    </ThinkingContainer>
  );
};