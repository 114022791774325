import styled from 'styled-components';

export const FlowContainer = styled.div`
  width: 100vw;
  height: 100%;
  background: #f8f9fa;
`;

export const NodeContainer = styled.div`
  background: white;
  border-radius: 8px;
  min-width: 300px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  border: 1px solid #e0e0e0;
`;

export const NodeHeader = styled.div`
  padding: 8px 12px;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  font-weight: bold;
`;

export const NodeContent = styled.div`
  padding: 16px;
  
  .tagged-input {
    width: 100%;
    min-height: 80px;
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
    font-size: 13px;
    line-height: 1.4;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #f8f9fa;
    margin: 8px 0;
    
    &:focus {
      outline: none;
      border-color: #4d90fe;
      box-shadow: 0 0 0 2px rgba(77,144,254,0.2);
    }
  }
`;

export const InputField = styled.div`
  margin-bottom: 16px;
  
  label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #555;
  }
  
  input, select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 13px;
    
    &:focus {
      outline: none;
      border-color: #4d90fe;
      box-shadow: 0 0 0 2px rgba(77,144,254,0.2);
    }
  }
`;

export const NodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddNodeBelow = styled.div`
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const PlusButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4CAF50;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  
  &:hover {
    background: #45a049;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const NodeTypeSelect = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: #f8f9fa;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  
  &:focus {
    outline: none;
  }
`;

export const StartNodeTitle = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 8px;
  color: #4CAF50;
`;

export const FlowWrapper = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;

export const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
`;

export const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

export const ActionButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  &:hover {
    background-color: #1976d2;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }

  &:active {
    transform: translateY(1px);
  }
`;