import { useState, useRef, useLayoutEffect } from 'react';

interface DynamicSvgProps {
    className?: string;
    name: string;
    customIconColor?: string;
    width?: number;
    height?: number;
}

export function DynamicSvg(props: DynamicSvgProps) {
    const {name} = props
    const ImportedIconRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);
  
    useLayoutEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            try {
                ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../icons/${name}.svg`)).default;
            } catch (error) {
                // Silently handle the error
                ImportedIconRef.current = null;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [name]);

    const style: any = {}

    if (props.width) {
        style['width'] = props.width
    }
    if (props.height) {
        style['height'] = props.height
    }
    if (props.customIconColor) {
        style['color'] = props.customIconColor
    }

  
    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        return (
            <ImportedIcon
                className={props.className}
                style={
                    style
                }
            />
        )
    }
    
    return null;
}