import React, { useState, useEffect } from 'react';
import * as S from './Invites.styles';
import { createInvite, getInvites, getUsers, Invite, User, deleteInvite } from '../../utils/supabase';

interface InvitesProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Invites: React.FC<InvitesProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [invites, setInvites] = useState<Invite[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    // Fetch existing invites and users
    fetchInvites();
    fetchUsers();
  }, []);

  const fetchInvites = async () => {
    const { data, error } = await getInvites();
    if (error) {
      console.error('Error fetching invites:', error);
      return;
    }
    setInvites(data || []);
  };

  const fetchUsers = async () => {
    const { data, error } = await getUsers();
    if (error) {
      console.error('Error fetching users:', error);
      return;
    }
    setUsers(data || []);
  };

  const handleSendInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    await createInvite(email);
    setEmail('');
    fetchInvites();
  };

  const handleDeleteInvite = async (inviteId: number) => {
    console.log("deleting invite", inviteId);
    const { data, error } = await deleteInvite(inviteId);
    fetchInvites();
  };

  if (!isOpen) return null;

  return (
    <S.Overlay onClick={onClose}>
      <S.Modal onClick={(e) => e.stopPropagation()}>
        <S.Header>
          <h2>Invite people</h2>
          <S.CloseButton onClick={onClose}>×</S.CloseButton>
        </S.Header>

        <S.InviteForm onSubmit={handleSendInvite}>
          <S.EmailInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address..."
          />
          <S.SendButton type="submit">
            Send invite
          </S.SendButton>
        </S.InviteForm>

        <S.Section>
          <h3>Pending Invites</h3>
          {invites.map((invite) => (
            <S.ListItem key={invite.id}>
              <S.ItemInfo>
                <span>{invite.email}</span>
                <S.Status>{invite.claimed ? 'claimed' : 'pending'}</S.Status>
              </S.ItemInfo>
              <S.DeleteButton onClick={() => handleDeleteInvite(invite.id)}>
                Delete
              </S.DeleteButton>
            </S.ListItem>
          ))}
        </S.Section>

        <S.Section>
          <h3>Members</h3>
          {users.map((user) => (
            <S.ListItem key={user.id}>
              <S.ItemInfo>
                <span>{user.email || 'No email'}</span>
              </S.ItemInfo>
            </S.ListItem>
          ))}
        </S.Section>
      </S.Modal>
    </S.Overlay>
  );
};
