import React, { useEffect, useState } from 'react';
import { useWorkflowStore } from '../../stores/workflowStore';
import FullCodeEditor from './FullCodeEditor';
import { revitHoverProvider } from './revitHoverProvider';

interface WorkflowCodeEditorProps {
  nodeId: string;
  language: string;
}

const WorkflowCodeEditor: React.FC<WorkflowCodeEditorProps> = ({
  nodeId,
  language,
}) => {
  const { nodes, updateNodeCode } = useWorkflowStore();
  
  // Find the node and get its code
  const node = nodes.find(n => n.id === nodeId);
  const code = node?.data.type === 'code' ? node.data.code || '' : '';

  // Find the function header line
  const findFunctionHeaderLine = (code: string): number => {
    const lines = code.split('\n');
    const headerPattern = /^def\s+execute\s*\(\s*state\s*:\s*dict\s*\)\s*->\s*dict\s*:/;
    const headerLineIndex = lines.findIndex(line => headerPattern.test(line.trim()));
    return headerLineIndex !== -1 ? headerLineIndex + 1 : -1; // Convert to 1-indexed
  };

  // Set up uneditable line based on found header
  const headerLineNumber = findFunctionHeaderLine(code);
  const uneditableLine = headerLineNumber !== -1 ? {
    lineNumber: headerLineNumber,
    content: 'def execute(state: dict) -> dict:'
  } : undefined;

  const handleCodeChange = (newCode: string) => {
    updateNodeCode(nodeId, newCode);
  };

  return (
    <FullCodeEditor
      code={code}
      language={language}
      onChange={handleCodeChange}
      hoverProviders={[revitHoverProvider]}
      uneditableLine={uneditableLine}
    />
  );
};

export default WorkflowCodeEditor; 