export default {
    "nodes": [
        {
            "id": "1",
            "type": "start",
            "position": { "x": 100, "y": 100 },
            "data": {
                "type": "start",
                "label": "Room Area Workflow",
                "iconName": "Entry",
                "iconColor": "#000000",
                "tooltip": "Starts the workflow"
            }
        },
        {
            "id": "2",
            "type": "code",
            "position": { "x": 450, "y": -8 },
            "data": {
                "type": "code",
                "label": "Calculate Bedroom Areas",
                "iconName": "Code",
                "iconColor": "#000000",
                "tooltip": "Calculates areas of all bedrooms",
                "code": `
from Autodesk.Revit.UI import TaskDialog
from Autodesk.Revit.DB import FilteredElementCollector, BuiltInCategory

def execute(state):
    # Get all rooms in the model
    collector = FilteredElementCollector(doc).OfCategory(BuiltInCategory.OST_Rooms)

    # List to store bedroom information
    bedrooms = []
    total_area = 0.0

    # Get filters from state
    filters = state.get('filters', {})
    level_filter = filters.get('level')
    min_area = filters.get('minArea', 0)
    max_area = filters.get('maxArea', float('inf'))
    units = state.get('units', 'imperial')

    # Iterate through rooms
    for room in collector:
        # Get room properties
        room_name = room.get_Parameter(BuiltInParameter.ROOM_NAME).AsString()
        room_number = room.get_Parameter(BuiltInParameter.ROOM_NUMBER).AsString()
        room_level = room.Level.Name if room.Level else 'Unknown'
        
        # Skip if doesn't match level filter
        if level_filter and room_level != level_filter:
            continue
        
        # Check if room is a bedroom (case-insensitive)
        if 'bedroom' in room_name.lower() or 'bed' in room_name.lower():
            # Get room area in square feet
            area = room.get_Parameter(BuiltInParameter.ROOM_AREA).AsDouble()
            
            # Skip if outside area filter range
            if area < min_area or area > max_area:
                continue
                
            # Convert from square feet to square meters
            area_sqm = area * 0.092903
            
            # Store bedroom information
            bedroom_data = {
                'name': room_name,
                'number': room_number,
                'area_sqft': round(area, 2),
                'area_sqm': round(area_sqm, 2),
                'level': room_level
            }
            
            bedrooms.append(bedroom_data)
            total_area += area

    # Convert total area to square meters
    total_area_sqm = total_area * 0.092903

    # Create results to update state
    results = {
        'total_area_sqft': round(total_area, 2),
        'total_area_sqm': round(total_area_sqm, 2),
        'bedroom_count': len(bedrooms),
        'bedrooms': bedrooms,
        'filters_applied': {
            'level': level_filter,
            'min_area': min_area,
            'max_area': max_area,
            'units': units
        }
    }

    # Display results in TaskDialog
    dialog = TaskDialog("Bedroom Analysis Results")
    dialog.MainInstruction = "Bedroom Analysis Results"

    report = "BEDROOM ANALYSIS REPORT\\n"
    report += "=" * 50 + "\\n\\n"
    report += f"Total Bedrooms Found: {results['bedroom_count']}\\n"
    report += f"Total Area: {results['total_area_sqft']} sq ft ({results['total_area_sqm']} sq m)\\n\\n"

    if results['filters_applied']['level']:
        report += f"Filtered by Level: {results['filters_applied']['level']}\\n"
    report += f"Area Range Filter: {results['filters_applied']['min_area']} - {results['filters_applied']['max_area']} sq ft\\n\\n"

    report += "Detailed Bedroom Information:\\n"
    report += "-" * 50 + "\\n\\n"

    for bedroom in results['bedrooms']:
        report += f"Room: {bedroom['name']} (#{bedroom['number']})\\n"
        report += f"Level: {bedroom['level']}\\n"
        report += f"Area: {bedroom['area_sqft']} sq ft ({bedroom['area_sqm']} sq m)\\n\\n"

    dialog.MainContent = report
    dialog.Show()

    # Return updated state
    return {
        'results': results
    }`
            }
        }
    ],
    "edges": [
        {
            "id": "e1-2",
            "source": "1",
            "target": "2"
        }
    ],
    "state": {
        "filters": {
            "minArea": 0,      // Minimum area filter in sq ft
            "maxArea": 1000,   // Maximum area filter in sq ft
            "level": null      // Optional level filter
        },
        "units": "imperial",   // or "metric"
        "results": null        // Will be updated by function execution
    }
}