import React, { useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tab, TabType, WorkflowTabData } from '../../types/tabs';
import { WorkflowPanel } from '../WorkflowPanel/WorkflowPanel';
import { WorkflowContainer } from '../../App.styles';
import WorkflowCodeEditor from '../CodeEditor/WorkflowCodeEditor';
import { MainContainerWrapper } from './MainContainer.styles';
import TabsBar from '../TabsBar/TabsBar';
import { useWorkflowStore } from '../../stores/workflowStore';

const MainContainer: React.FC = () => {
  const activeId = useWorkflowStore(state => state.activeId);
  const isSharedWorkflow = useWorkflowStore(state => state.workflows[state.activeId]?.is_shared ?? false);
  
  // Memoize the initial tabs state
  const [tabs, setTabs] = useState<Tab[]>(() => ([
    { id: 'workflow', type: 'workflow', title: 'Workflow', data: {} as WorkflowTabData }
  ]));
  const [activeTab, setActiveTab] = useState<string>('workflow');
  const [showBanner, setShowBanner] = useState(true);

  // Memoize handlers
  const handleAddCodeTab = useCallback((code: string, language: string, nodeId: string) => {
    const { nodes } = useWorkflowStore.getState();
    const node = nodes.find(n => n.id === nodeId);
    const newTab: Tab = {
      id: uuidv4(),
      type: 'code',
      title: node?.data.label || `Code ${tabs.length}`,
      data: {
        code,
        language,
        nodeId
      }
    };
    setTabs(prevTabs => [...prevTabs, newTab]);
    setActiveTab(newTab.id);
  }, [tabs.length]);

  const handleTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
  }, []);

  const handleCloseTab = useCallback((tabId: string) => {
    if (tabId === 'workflow') return;
    
    setTabs(prevTabs => prevTabs.filter(tab => tab.id !== tabId));
    setActiveTab(prevTab => prevTab === tabId ? 'workflow' : prevTab);
  }, []);

  // Memoize the warning banner component
  const warningBanner = useMemo(() => {
    if (!isSharedWorkflow || !showBanner) return null;
    return (
      <div style={{
        backgroundColor: 'rgba(255, 243, 205, 0.95)',
        color: '#856404',
        padding: '8px 16px',
        textAlign: 'center',
        borderRadius: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '90%',
        fontSize: '14px',
        border: '1px solid #ffeeba',
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
      }}>
        <span>
          Shared workflow changes and chat logs are temporary and will not save on page reload
        </span>
        <button
          onClick={() => setShowBanner(false)}
          style={{
            background: 'none',
            border: 'none',
            color: '#856404',
            cursor: 'pointer',
            padding: '4px',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            opacity: 0.7,
            transition: 'opacity 0.2s'
          }}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '0.7'}
        >
          ✕
        </button>
      </div>
    );
  }, [isSharedWorkflow, showBanner]);

  return (
    <MainContainerWrapper>
      <TabsBar
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onCloseTab={handleCloseTab}
      />
      {tabs.map(tab => (
        <div 
          key={tab.id} 
          style={{ 
            display: activeTab === tab.id ? 'flex' : 'none',
            flex: 1,
            height: '100%'
          }}
        >
          {tab.type === 'workflow' ? (
            <WorkflowContainer>
              <WorkflowPanel onExpandCode={handleAddCodeTab} />
            </WorkflowContainer>
          ) : tab.type === 'code' ? (
            <WorkflowCodeEditor
              nodeId={tab.data.nodeId}
              language={tab.data.language}
            />
          ) : null}
        </div>
      ))}
      {warningBanner}
    </MainContainerWrapper>
  );
};

export default MainContainer; 