import { IMarkdownString } from 'monaco-editor';

interface RevitTypeInfo {
  documentation: string;
  typeStub: string;
}

interface RevitApiCache {
  [key: string]: RevitTypeInfo;
}

class RevitApiService {
  private cache: RevitApiCache = {};
  private baseUrl: string;

  constructor(baseUrl: string = process.env.REACT_APP_BACKEND_URL || '') {
    this.baseUrl = baseUrl;
  }

  async getTypeInfo(symbol: string): Promise<RevitTypeInfo | null> {
    // Check cache first
    if (this.cache[symbol]) {
      return this.cache[symbol];
    }

    try {
      const response = await fetch(`${this.baseUrl}/api/revit/type-info/${encodeURIComponent(symbol)}`);
      if (!response.ok) {
        return null;
      }

      const typeInfo = await response.json();
      this.cache[symbol] = typeInfo;
      return typeInfo;
    } catch (error) {
      console.error('Error fetching Revit API type info:', error);
      return null;
    }
  }

  async getHoverContent(symbol: string, lineContext: string): Promise<IMarkdownString> {
    const typeInfo = await this.getTypeInfo(symbol);
    if (!typeInfo) {
      return { value: 'No documentation available' };
    }

    return {
      value: [
        '```python',
        typeInfo.typeStub,
        '```',
        '---',
        typeInfo.documentation
      ].join('\n'),
      supportHtml: true,
      isTrusted: true
    };
  }

  async getTypeStubs(): Promise<string> {
    try {
      const response = await fetch(`${this.baseUrl}/api/revit/type-stubs`);
      if (!response.ok) {
        throw new Error('Failed to fetch type stubs');
      }
      return await response.text();
    } catch (error) {
      console.error('Error fetching Revit API type stubs:', error);
      return '';
    }
  }
}

export const revitApiService = new RevitApiService(); 