import { create } from 'zustand';
import { ChatMessage, ThinkingState } from '../types/message';
import { fetchUserChats, syncChat, createChatSupabase } from '../utils/supabase';
import { supabase } from '../utils/supabase';
import { AttachmentMessageContent } from '../types/message';
import { useWorkflowStore } from './workflowStore';

interface ChatData {
  messages: ChatMessage[];
  inputValue: string;
  thinkingState: ThinkingState | null;
  workflow_id: string;
  is_default: boolean;
}

interface EditorState {
  isLeftSidebarCollapsed: boolean;
  isRightSidebarCollapsed: boolean;
  toggleLeftSidebar: (isCollapsed: boolean) => void;
  toggleRightSidebar: (isCollapsed: boolean) => void;

  chats: Record<string, ChatData>;
  activeId: string;
  chatMessages: ChatMessage[]; // Keep for backward compatibility
  inputBoxValue: string; // Keep for backward compatibility
  
  addChatMessage: (message: ChatMessage, id?: string) => void;
  updateLastChatMessage: (message: ChatMessage, id?: string) => void;
  setInputBoxValue: (value: string, id?: string) => void;
  setChatWorkflowId: (workflowId: string, chatId?: string) => void;
  getChatThreadId: () => string;
  setThinkingState: (state: ThinkingState | null, id?: string) => void;
  getThinkingState: (id?: string) => ThinkingState | null;
  getThinkingStateGivenWorkflowId: (workflowId?: string) => ThinkingState | null;

  addChat: (chatId: string, workflowId: string, isDefault: boolean) => void;

  lastSyncTime: number;
  lastPullTime: number;
  pendingChanges: Record<string, boolean>;

  initializeChats: () => Promise<void>;
  fetchChats: () => Promise<void>;
  syncChats: () => Promise<void>;
  markChatChanged: (workflowId: string) => void;
  reset: (reinitialize?: boolean) => void;
  updateSignedUrl: (threadId: string, filePath: string, expiration: number, signedUrl: string) => Promise<string>;

  syncIntervalId?: NodeJS.Timeout;
  pullIntervalId?: NodeJS.Timeout;
}

const SYNC_INTERVAL = 3000; // 3 seconds - how often to push changes to DB
const PULL_INTERVAL = 15000; // 5 seconds - how often to pull changes from DB
const SESSION_ID = crypto.randomUUID();

export const useEditorStore = create<EditorState>((set, get) => {
  // Call initializeChats immediately after store creation
  setTimeout(() => {
    get().initializeChats();
  }, 0);

  return {
    isLeftSidebarCollapsed: false,
    isRightSidebarCollapsed: false,
    toggleLeftSidebar: (isCollapsed: boolean) => set({ isLeftSidebarCollapsed: isCollapsed }),
    toggleRightSidebar: (isCollapsed: boolean) => set({ isRightSidebarCollapsed: isCollapsed }),

    chats: { },
    activeId: 'default',
    chatMessages: [], // Keep for backward compatibility
    inputBoxValue: '', // Keep for backward compatibility

    getChatThreadId: () => get().activeId,


    updateSignedUrl: async (threadId: string, filePath: string, expiration: number, signedUrl: string) => {
      // Return early if URL hasn't expired yet
      const BUFFER_TIME = 1000 * 60 * 10; // 10 MINUTES
      if (expiration - BUFFER_TIME > Date.now()) {
        console.log('URL has not expired yet', expiration - BUFFER_TIME, Date.now());
        return signedUrl;
      }
      console.log('URL has expired');

      // Get new signed URL
      const { data, error } = await supabase.storage.from('chat_docs').createSignedUrl(filePath, 3600); // 1 hour expiration
      
      if (error || !data?.signedUrl) {
        console.error('Error updating signed URL:', error);
        return signedUrl; // Return old URL if there's an error
      }

      // Update the URL in messages
      set((state) => {
        const updatedMessages = state.chats[threadId].messages.map(message => {
          const updatedContent = message.content.map(content => {
            if ('filePath' in content && (content as AttachmentMessageContent).filePath === filePath) {
              return { ...content, url: data.signedUrl, expiresAt: Date.now() + 3600 * 1000 };
            }
            return content;
          });
          return { ...message, content: updatedContent };
        });

        return {
          chats: {
            ...state.chats,
            [threadId]: {
              ...state.chats[threadId],
              messages: updatedMessages
            }
          }
        };
      });

      get().markChatChanged(threadId);
      return data.signedUrl;
    },

    addChatMessage: (message: ChatMessage, id?: string) => set((state) => {
      console.log("ADD CHAT MESSAGE", message, id);
      const targetId = id || state.activeId || 'default';
      const newMessages = [...state.chats[targetId]?.messages || [], message];
      
      // Mark chat as changed when messages are added
      get().markChatChanged(targetId);

      // Reset lastSyncTime if this is a new workflow ID
      const shouldResetSync = !state.chats[targetId]?.messages.length;

      return {
        chats: {
          ...state.chats,
          [targetId]: {
            ...state.chats[targetId],
            messages: newMessages
          }
        },
        chatMessages: targetId === state.activeId ? newMessages : state.chatMessages,
        ...(shouldResetSync ? { lastSyncTime: 0 } : {})
      };
    }),

    updateLastChatMessage: (message: ChatMessage, id?: string) => set((state) => {
      const targetId = id || state.activeId;
      const currentMessages = state.chats[targetId].messages;
      const newMessages = currentMessages.length > 0 
        ? [...currentMessages.slice(0, -1), message]
        : [message];

      // Mark chat as changed when messages are updated
      get().markChatChanged(targetId);

      return {
        chats: {
          ...state.chats,
          [targetId]: {
            ...state.chats[targetId],
            messages: newMessages
          }
        },
        chatMessages: targetId === state.activeId ? newMessages : state.chatMessages
      };
    }),

    setInputBoxValue: (value: string, id?: string) => set((state) => {
      const targetId = id || state.activeId;
      return {
        chats: {
          ...state.chats,
          [targetId]: {
            ...state.chats[targetId],
            inputValue: value
          }
        },
        inputBoxValue: targetId === state.activeId ? value : state.inputBoxValue
      };
    }),

    createChat: async (workflowId: string) => {
      var newChatId = await createChatSupabase(SESSION_ID, workflowId);
      if (!newChatId) return;
      set(state => ({
        chats: {
          ...state.chats,
          [newChatId]: {
            messages: [],
            inputValue: '',
            thinkingState: null,
            workflow_id: workflowId,
            is_default: false
          }
        }
      }));
      return newChatId;
    },

    listChats: (workflowId: string) => {
      return Object.entries(get().chats).filter(([_, chat]) => chat.workflow_id == workflowId);
    },

    setChatWorkflowId: (workflowId: string, inputChatId?: string) => {
      // Determine chatId based on priority order
      const chatId = inputChatId || 
        // Find default chat for this workflow
        Object.entries(get().chats).find(([_, chat]) => 
          chat.workflow_id == workflowId && chat.is_default
        )?.[0] ||
        // Find any chat with same workflow
        Object.entries(get().chats).find(([_, chat]) => 
          chat.workflow_id == workflowId
        )?.[0] ||
        // Fallback to workflowId
        workflowId;

      set({ activeId: chatId });
      
      // Only create a blank chat if inputChatId is provided and the chat doesn't exist
      if (inputChatId && !get().chats[chatId]) {

        set(state => ({
          chats: {
            ...state.chats,
            [chatId]: { 
              messages: [], 
              inputValue: '',
              thinkingState: null,
              workflow_id: workflowId,
              is_default: false
            }
          }
        }));
      }
      const chat = get().chats[chatId];
      set({ 
        chatMessages: chat?.messages || [],
        inputBoxValue: chat?.inputValue || ''
      });
    },

    setThinkingState: (state: ThinkingState | null, id?: string) => set((currentState) => {
      const targetId = id || currentState.activeId;
      return {
        chats: {
          ...currentState.chats,
          [targetId]: {
            ...currentState.chats[targetId],
            thinkingState: state
          }
        }
      };
    }),

    getThinkingState: (id?: string) => {
      const state = get();
      const targetId = id || state.activeId;
      return state.chats[targetId]?.thinkingState || null;
    },

    addChat: (chatId: string, workflowId: string, isDefault: boolean) => {
      set(state => ({
        chats: {
          ...state.chats,
          [chatId]: {
            ...state.chats[chatId],
            is_default: isDefault,
            workflow_id: workflowId,
            messages: [],
            inputValue: '',
            thinkingState: null
          }
        }
      }));
    },

    getThinkingStateGivenWorkflowId: (workflowId?: string) => {
      const state = get();
      if (!workflowId) {
        const targetId = state.activeId;
        return state.chats[targetId]?.thinkingState || null;
      }
      
      // Find any chat with matching workflow ID that has a thinking state
      const matchingChat = Object.values(state.chats).find(
        chat => chat.workflow_id == workflowId && chat.thinkingState != null
      );
      return matchingChat?.thinkingState || null;
    },

    lastSyncTime: 0,
    lastPullTime: 0,
    pendingChanges: {},

    initializeChats: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const urlParams = new URLSearchParams(window.location.search);
      const chatGlobal = urlParams.get('globalView') === 'true';

      // Initial load - force immediate fetch regardless of last sync time
      const { data: chats, error } = await fetchUserChats(SESSION_ID, 0, chatGlobal)

      if (chats && !error) {
        set(state => {
          const updatedChats = { ...state.chats };
          chats.forEach(chat => {
            var newMessageData = JSON.parse(chat.messages);
            //implementation of old chat format
            if ("messages" in newMessageData){
              newMessageData = newMessageData.messages;
            }
            updatedChats[chat.id] = {
              messages: newMessageData,
              inputValue: '',
              thinkingState: null,
              is_default: chat.is_default,
              workflow_id: chat.workflow_id
            };
          });

          const activeChat = updatedChats[state.activeId];
          return { 
            chats: updatedChats,
            chatMessages: activeChat?.messages || [],
            inputBoxValue: activeChat?.inputValue || '',
            lastPullTime: Date.now(),
            lastSyncTime: Date.now()
          };
        });
      }

      // Set up separate intervals for fetch and sync
      const pullIntervalId = setInterval(() => {
        get().fetchChats();
      }, PULL_INTERVAL);
      
      const syncIntervalId = setInterval(() => {
        get().syncChats();
      }, SYNC_INTERVAL);
      
      set({ 
        syncIntervalId: syncIntervalId,
        pullIntervalId: pullIntervalId
      });
    },

    fetchChats: async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const chatGlobal = urlParams.get('globalView') === 'true';

      const { data: chats, error } = await fetchUserChats(SESSION_ID, get().lastPullTime, chatGlobal);
      if (error || !chats) return;

      console.log("chats", chats);
      set(state => {
        const updatedChats = { ...state.chats };
        console.log("cht", chats)
        chats.forEach(chat => {
          const existingChat = updatedChats[chat.id];
          var newData = JSON.parse(chat.messages);
          //implementation of old chat format
          if ("messages" in newData){
            newData = newData.messages;
          }
          console.log("NEW DATA", newData);
          if (!existingChat || new Date(chat.updated_at) > new Date(state.lastPullTime)) {
            updatedChats[chat.id] = {
              ...existingChat,
              messages: newData,
              workflow_id: chat.workflow_id,
              is_default: chat.is_default
            };
          }
        });


        // Update chatMessages if the active chat was modified
        const activeChat = updatedChats[state.activeId];
        return { 
          chats: updatedChats,
          chatMessages: activeChat?.messages || state.chatMessages,
          lastPullTime: Date.now()
        };
      });
    },

    syncChats: async () => {
      const state = get();
      const now = Date.now();
      
      if (now - state.lastSyncTime < SYNC_INTERVAL) {
        return;
      }

      // Safety check: Get all pending chats
      const pendingChats = Object.entries(state.pendingChanges)
        .filter(([_, isPending]) => isPending)
        .map(([chatId]) => state.chats[chatId]);

      // Count how many pending chats are empty
      const emptyChatCount = pendingChats.filter(chat => 
        !chat || (
          (!chat.messages || chat.messages.length === 0)
          &&
          (!chat.inputValue || chat.inputValue == '')
          &&
          (chat.thinkingState == null)
        )
      ).length;

      const { workflows } = useWorkflowStore.getState();

      const updates = Object.entries(state.pendingChanges)
        .map(async ([chatId, isPending]) => {
          const workflowId = state.chats[chatId].workflow_id;
          if (!isPending || workflows[workflowId]?.is_shared) {
            return;
          }
          const chat = state.chats[chatId];
          if (!chat || (emptyChatCount >= 2 && 
            (!chat.messages || chat.messages.length == 0)
            &&
            (!chat.inputValue || chat.inputValue == '')
            &&
            (chat.thinkingState == null)
          )) {
              return;
          }
          const chatData = {
            messages: chat.messages,
            inputValue: chat.inputValue,
            thinkingState: chat.thinkingState,
            workflow_id: chat.workflow_id,
            is_default: chat.is_default
          };
          return syncChat(chatId, chatData, SESSION_ID);
        })
        .filter(Boolean);

      await Promise.all(updates);

      set({
        lastSyncTime: now,
        pendingChanges: {}
      });
    },

    markChatChanged: (chatId: string) => set(state => ({
      pendingChanges: {
        ...state.pendingChanges,
        [chatId]: true
      }
    })),

    reset: (reinitialize = false) => {
      const state = get();
      if (state.syncIntervalId) {
        clearInterval(state.syncIntervalId);
      }
      if (state.pullIntervalId) {
        clearInterval(state.pullIntervalId);
      }

      set({
        isLeftSidebarCollapsed: false,
        isRightSidebarCollapsed: false,
        chats: {},
        activeId: 'default',
        chatMessages: [],
        inputBoxValue: '',
        lastSyncTime: 0,
        lastPullTime: 0,
        pendingChanges: {},
        syncIntervalId: undefined,
        pullIntervalId: undefined
      }); 

      if (reinitialize) {
        setTimeout(() => {
          get().initializeChats();
        }, 0);
      }
    },
  };
});