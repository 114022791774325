export const NODE_TYPES = {
  start: 'start',
  code: 'code',
  ai_prompt: 'ai_prompt',
  select_in_revit: 'select_in_revit',
  user_input: 'user_input',
  branch: 'branch',
} as const;

export const NODE_COLORS = {
  [NODE_TYPES.start]: '#ff9966',
  [NODE_TYPES.code]: '#66ff99',
  [NODE_TYPES.ai_prompt]: '#6699ff',
  [NODE_TYPES.select_in_revit]: '#ff6666',
  [NODE_TYPES.user_input]: '#6666ff',
  [NODE_TYPES.branch]: '#9966ff',
} as const; 